
import api, { APIClient } from '../../api';
import { Assistant } from '../assistantsTypes';
import axios from 'axios';

export class AssistantService extends APIClient {
    baseUrl = process.env.REACT_APP_AIX_LLM_API;
    constructor(props: any) {
        super(props);
    }

    public async getAssistants()  {
        try {
            var url = `${this.baseUrl}/api/assistants/`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('getAssistants');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.get(
                url,
                {
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    'Access-Control-Allow-Origin': '*'
                }
            });

            console.log(response);
            console.log('paas getAssistantss');
            // const response = await api.get();
            console.log(response.data); 
            return response.data.data;
        } catch (e) {
            console.log(e)
            return [];
        }
    }

    public async createAssistant(assistant: Assistant) {
        try {
            var url = `${this.baseUrl}/api/assistants/`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('getAssistants');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.post(
                url, assistant,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            );
            console.log(response);
            console.log('paas getAssistantss');
            // const response = await api.get();
            console.log(response.data);
            console.log(response.data);
            return response.data;
        } catch (e) {
            console.log(e)
            return {};
        }

        //try {
        //    console.log(`${this.endpoint}/api/assistants`);
        //    const response = await api.post(`${this.endpoint}/api/assistants?refresh=${Math.random()}`, assistant);
        //    console.log(response.data);
        //    return response.data;
        //} catch (e) {
        //    return {};
        //}
    }

    public async getAssistant(assistantId: string) {
        try {
            var url = `${this.baseUrl}/api/assistants/${assistantId}` 
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('getAssistants');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            );
            console.log(response);
            console.log('paas getAssistantss');
            // const response = await api.get();
            console.log(response.data);
            console.log(response.data.data);
            return response.data;
        } catch (e) {
            console.log(e)
            return {};
        }

        //try {
        //    console.log(`${this.endpoint}/api/assistants/${assistantId}`);
        //    const response = await api.get(`${this.endpoint}/api/assistants/${assistantId}?refresh=${Math.random()}`);
        //    console.log(response.data);
        //    return response.data;
        //} catch (e) {
        //    return {};
        //}
    }

    public async GetOpenAIModels(): Promise<{ value: string, label: string }[]> {
        try {
            var url = `${this.baseUrl}/api/GetOpenAIModels`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('GetOpenAIModels');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            );

            console.log(response);
            console.log('paas GetOpenAIModels');
            // const response = await api.get();
            console.log(response.data);
            console.log(response.data);
            return response.data.map(c => { return { value: c.id, label: c.id }; });
        } catch (e) {
            console.log(e)
            return [];
        }  
    }


    public async updateAssistant(assistantId: string, assistant: Assistant) {
        try {
            var url = `${this.baseUrl}/api/assistants/${assistantId}`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    //     PathRoute: window.location.pathname,
                    'Access-Control-Allow-Origin': '*'
                }
            });
            console.log('getAssistants');
            //var url = `${this.endpoint}/api/assistants?refresh=${Math.random()}`;
            console.log(url);
            var response = await apiAIX.post(
                url, assistant,
                {
                    headers: {
                        Authorization: `Bearer ${LiveCloud_AuthMS}`,
                        AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                        Platform: `1`,
                        //     PathRoute: window.location.pathname,
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            );
            console.log(response);
            console.log('paas getAssistantss');
            // const response = await api.get();
            console.log(response.data);
            console.log(response.data.data);
            return response.data;
        } catch (e) {
            console.error(e)
            console.log(e)
            return {};
        } 
    }

    public async deleteAssistant(assistantId: string) {
        console.log(`${this.endpoint}/api/assistants/${assistantId}`);
        const response = await api.delete(`${this.endpoint}/api/assistants/${assistantId}?refresh=${Math.random()}`);
        console.log(response.data);
        return response.data;
    }
}

import React, { useState } from 'react';
import {MostTargetChart, ResumeEvent, devicesEvent } from '../Models';
import './index.css'
/*import { FinOpsChartService } from '../../../../services/finOps/finOpsChartService';*/
import ReactECharts from 'echarts-for-react';


interface Props {
    mostTargetData: MostTargetChart
    resumeEventReport: ResumeEvent,
    graphSelected: string,
}
interface tooltipModel {
    device: string;
    classification:string;
    count: number
}
const MostTargetGraph: React.FC<Props> = (props) => {

    const [pivotTableData, setPivotTableData] = useState<any[]>([]);
    const SumOfEvents = (events: devicesEvent[]) : tooltipModel[] => {
        let somatorio  = {};

        events.forEach(obj => {
            if (obj.device && obj.classification) {
                let chave = obj.device + '_' + obj.classification;
    
                if (somatorio[chave]) {
                    somatorio[chave].count += obj.count;
                } else {
                    somatorio[chave] = {
                        device: obj.device,
                        classification: obj.classification,
                        count: obj.count
                    };
                }
            }
        });
    
        // Transformar o objeto somatório em um array de objetos
        let resultado: tooltipModel[] = Object.values(somatorio);
    
        return resultado.sort((a: tooltipModel, b: tooltipModel) => b.count - a.count);
    }
    const formatTooltip = (params, legend) => {
        const mainLegendHTML = createMainLegend(params);
        const deviceLegendHTML = createDeviceLegend(params, SumOfEvents(legend));

        return `
        <div class="tooltipChart" style="border-color: ${params[0].color}">
            ${mainLegendHTML}
            ${deviceLegendHTML}
        </div>
    `;
    }


    const createMainLegend = (params) => `
    <div class="tooltipMainLegend">
        <div class="colorIndicator" style="background-color: ${params[0].color}"></div>
        <p class="legendText">${params[0].name}</p>
        <b class="legendValue">${params[0].value}</b>
    </div>
`;


    const createDeviceLegend = (params, legend) => {
        const devices = legend.filter(dev => dev.classification == params[0].name);
        const devicesHTML = devices.map(dev => `
        <p class="tooltipDevice">${dev.device}: <b>${dev.count}</b></p>
    `).join(' ');

        return `<div class="tooltipDeviceLegend scrollable-v">${devicesHTML}</div>`;
    }

    const getBarChartCustomTooltip = (data, legend?: any) => {
        const screenWidth = window.innerWidth;
        let fontSize = screenWidth <= 1400 ? 12 : 14;

        // estilo de fonte dos rotulos x e y
        const axisStyle = {
            axisLabel: {
                fontSize: fontSize + 'px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        };

        return (
            {
                tooltip: {
                    trigger: 'axis',
                    position: function (point, params, dom, rect, size) {
                        // Desloque um pouco à direita e abaixo do ponto do cursor
                        return [point[0] - 50, point[1] - 60];
                    },
                    confine: true,
                    hideDelay: 2000,
                    shadowColor: 'none',
                    shadowBlur: 'none',
                    backgroundColor: 'transparent',
                    padding: 0,
                    borderWidth: 0,
                    formatter: function (params) {
                        return formatTooltip(params, legend);
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: { 
                    top: '10%',
                    bottom: '7%',
                    left: '0%',
                    width: '100%',
                    containLabel: true
                },
                xAxis: {
                    ...axisStyle,
                    type: 'category',
                    data: data.labels,
                    show: true,                    
                },
                yAxis: {
                    ...axisStyle,
                    type: 'value'
                },
                series: [
                    {
                        data: data.data,
                        itemStyle: {
                            color: (params) => data.colors[params.dataIndex],
                            borderRadius: [4, 4, 0, 0],
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        type: 'bar',
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(58, 79, 135, 0.4)',
                            }
                        }
                    }
                ]
            }
        )
    };
    

    // const webixData = mostTargetedChart.data.map((value, index) => ({
    //     id: index,
    //     label: mostTargetedChart.labels[index],
    //     value,
    //     color: mostTargetedChart.colors[index]
    // }));


    // const testWebixData = [
    //     { id: "1", label: "Test Label 1", value: 10, color: "#ff0000" },
    //     { id: "2", label: "Test Label 2", value: 20, color: "#00ff00" }
    // ];

    //setDataPivot(webixData);

    return (
        <>
            {props.mostTargetData ? (
                <>
                    <ReactECharts style={{ minHeight: '100%', minWidth: '100%' }}

                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                        option={getBarChartCustomTooltip(props.mostTargetData, props.graphSelected === 'user' ? props.resumeEventReport.devices : props.resumeEventReport.mostTargetsCollectores)}
                    />

                   
                    {/* <PivotTableWebix
                        key={'key'}
                        data={testWebixData}
                        fields={['label', 'value', 'color']}
                        structure={{
                            rows: ['label'],
                            //values: [{ name: "count", operation: ["sum"] }],
                        }}
                    /> */}

                    


                </>
            ) : (
                <small style={{ display: 'flex', justifyContent: 'center' }}>
                    <i style={{ marginRight: '3px', marginTop: '2px' }} className="lci lci-exclamation text-warning" />
                    Não há informações no período
                </small>
            )}
        </>
    );
}

export default MostTargetGraph;
export class edr {
    solution() {
        return 'edr'
    }

    site() {
        return 'https://www.processor.com.br/livecloud-detection-and-response'
    }

    logotipo() {
        return require('./assets/logotipos/logo_edr.svg').default
    }
    titulo() {
        return <>Proteja os ativos digitais</>
    }

    chamada() {
        return <>Uma <b>solução de segurança abrangente</b>, projetada especificamente para proteger servidores e estações de trabalho, seja on-premises ou em nuvem pública.</>
    }

    image() {
        return require('./assets/imgs/imagem_edr.png').default
    }

    detalhes() {
        return <>
            <h2>Pronto para levar a segurança da organização para o próximo nível?</h2>
            <p>Com nossa tecnologia de ponta, oferecemos <b>proteção automatizada para endpoints em tempo real</b>, fazendo uso de análise comportamental. Isso significa que podemos detectar ameaças novas e já existentes, e responder a incidentes com base em Machine Learning e Inteligência Artificial.</p>
            <h3>Por que escolher o LiveCloud Detection & Response?</h3>
            <div className="pq-gtm">
                <div className="tela">
                    <img src={require('./assets/telas/tela_edr.png').default} />
                </div>
                <ul>
                    <li><b>Proteção avançada:</b> Utilizamos Machine Learning e Inteligência Artificial para oferecer proteção avançada contra ameaças.</li>
                    <li><b>Gestão contínua:</b> Maior disponibilidade e prevenção contínuas, para que as equipes possam se concentrar no que realmente importa.</li>
                    <li><b>Visibilidade completa:</b> Oferecemos visibilidade completa do endpoint, incluindo softwares instalados e processos.</li>
                    <li><b>Relatórios periódicos:</b> Fornecemos até dois relatórios do ambiente por mês, sob demanda.</li>
                    <li><b>Virtual patching:</b> Protegemos os dados da organização em todas as fases do ataque, desde a descoberta até a remediação e roll back.</li>
                </ul>
            </div>
            <div className="box">LiveCloud Detection & Response é parte do LiveCloud, um <b>conjunto de serviços e soluções de tecnologia gerenciados e operados por nossa equipe de especialistas</b>. Com o LiveCloud, você pode acelerar seu processo de transformação digital com confiança.</div>
            <h3>Pronto para dar o próximo passo em segurança?</h3>
            <p>Não espere até que seja tarde demais. Proteja os ativos digitais agora com LiveCloud Detection & Response. Entre em contato conosco hoje mesmo para saber mais sobre como podemos ajudar a proteger a organização.</p>            
        </>
    }
}
export default edr;
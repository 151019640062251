import { GridCellValue } from "@material-ui/data-grid";
import api, { APIClient } from "./api";
import { palette } from '@material-ui/system';
import { Component } from "react";
import React, { useState } from "react";

function DateFormater(date: any) {
    let options: any = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    return date && new Intl.DateTimeFormat('pt-BR', options).format(new Date(date)) as string;
}             

function getDateString(date: any, endDate: boolean) {
    if(!endDate)
    {
        return date.getFullYear() + "-" + (date.getMonth()+1) + "-1";
    } else {
        return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
    }
}     

export class AuditLogService extends APIClient {
    state = {
        data: {
            filterGridDefault: { startDate: getDateString(new Date(), false), endDate: getDateString(new Date(), true)},
        }
    };

    public emptyRecord: any =
        {
            id: null,
            userId: null,
            clientGroupId: 0,
            route: null,
            detail: null,
            httpMethod: null,
            autorizationRoles: null,
            accessDate: "0001-01-01T00:00:00",
            clientGroupName: null,
            userEmail: null
        };

    constructor(props: any) {
        super(props);
    }

    title = 'Audit Log';

    getFilter() {
        return this.state.data.filterGridDefault;
    }

    public async templateEdit(disabled: boolean) {
        let item = {
            title: 'id',
            Fields: [

                {
                    edit: true,
                    row: 1,
                    col: 2,
                    colspan: 2,
                    rowspan: 2,
                    name: 'subject',
                    type: 'detailtext',
                    title: 'Assunto',
                    required: {
                        value: true,
                        required: 'Assunto é obrigatório'
                    },
                    disabled: true
                },
                {
                    edit: true,
                    row: 1,
                    col: 2,
                    colspan: 2,
                    rowspan: 2,
                    name: 'details',
                    type: 'detailtextArea',
                    title: 'Detalhes',
                    required: {
                        value: true,
                        required: 'Detalhes é obrigatório'
                    },
                    disabled: true
                }, {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'solicitationDate',
                    type: 'detailtext',
                    title: 'Data Solicitação',
                    required: {
                        value: true,
                        required: 'Data Solicitação é obrigatório'
                    },
                    disabled: true,
                    formatData: function (data: any) {
                        return DateFormater(data);
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameBusinessArea',
                    type: 'detailtext',
                    title: 'Área de negócio',
                    required: {
                        value: true,
                        required: 'Área de negócio é obrigatório'
                    },
                    disabled: true, formatData: function (data: any) {
                        return data;
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameCustomer',
                    type: 'detailtext',
                    title: 'Cliente',
                    required: {
                        value: true,
                        required: 'Assunto é obrigatório'
                    },
                    disabled: true,
                    formatData: function (data: any) {
                        return data;
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameServiceGroup',
                    type: 'detailtext',
                    title: 'Grupo de Serviço',
                    required: {
                        value: true,
                        required: 'Grupo de Serviço é obrigatório'
                    },
                    disabled: true, formatData: function (data: any) {
                        return data;
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameService',
                    type: 'detailtext',
                    title: 'Serviço',
                    required: {
                        value: true,
                        required: 'Serviço é obrigatório'
                    },
                    disabled: true,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0 && val.length < 30) {
                            return true;
                        }
                        return false;
                    }, formatData: function (data: any) {
                        return data;
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameProcess',
                    type: 'detailtext',
                    title: 'Processo',
                    required: {
                        value: true,
                        required: 'Processo é obrigatório'
                    },
                    disabled: true,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0 && val.length < 30) {
                            return true;
                        }
                        return false;
                    }, formatData: function (data: any) {
                        return data;
                    }
                },
            ]
        };
        return { ...item };
    }

    async initialize() {

    }

    setFilter(filterParm: any) {
        const stateData = { ...this.state };
        stateData.data.filterGridDefault = filterParm;        
        this.setState({ stateData });
    }

    RetrieveList(_filterGridDefault: any, nextToken: string) {

        let filterData = _filterGridDefault;

        if (_filterGridDefault == null || _filterGridDefault == undefined || _filterGridDefault == "" || (_filterGridDefault.startDate == "" && _filterGridDefault.endDate == "" ) ) {
            filterData = this.state.data.filterGridDefault;
        }

        var filter = `?startDate=${filterData.startDate}T00:01:00-0300&endDate=${filterData.endDate}T23:59:00-0300&limit=${filterData.size}`;
        filter += nextToken ? `&nextToken=${nextToken}` : "";
        filter += filterData.clientId ? `&clientId=${filterData.clientId}` : "";
        filter += filterData.term ? `&UserEmail=${filterData.term}` : "";
        const url = `${this.endpoint}/api/AuditLog/RetrieveList${filter}&${Math.random()}`;
        var response = api({
            method: 'get',
            url,
        });

        return response;
    }

    RetrieveListCount(_filterGridDefault: any) {
        let filterData = _filterGridDefault;
        if (_filterGridDefault == null || _filterGridDefault == undefined || _filterGridDefault == "" || (_filterGridDefault.startDate == "" && _filterGridDefault.endDate == "" ) ) {
            filterData = this.state.data.filterGridDefault;
        }
        var filter = `?startDate=${filterData.startDate}T00:01:00&endDate=${filterData.endDate}T23:59:00`;
        filter += filterData.clientId ? `&clientId=${filterData.clientId}` : "";
        filter += filterData.term ? `&UserEmail=${filterData.term}` : "";
        const url = `${this.endpoint}/api/AuditLog/RetrieveListCount${filter}&${Math.random()}`;
        var response = api({
            method: 'get',
            url,
        });

        return response;
    }
}

import React from 'react';
import { useEffect } from 'react';

import './SideModal.css';
import LcIconLink from '../../Generic/LcIconLink';
import { PiXLight } from 'react-icons/pi';

interface Props {
    visible: boolean
    header: string
    onClose?: Function
    width?: string,
    fullWidth?: boolean,
    backgroundColor?: 'white' | 'blue';
}

const SideModal: React.FC<Props> = (props) => {

    useEffect(() => {
        if (props.visible) {
            let firstInput = document.querySelector('.side-modal.visible .body input') as HTMLInputElement;
            firstInput && firstInput.focus();
        }
    }, [props.visible])

    const getWidth = () => {
        return props.fullWidth ? `100%` : (props.width || '460px');
    }

    return (
        <div className={`side-modal${props.visible ? ' visible' : ''} ${props.backgroundColor || 'white'} `} style={{ width: props.visible ? getWidth() : '' }}>
            <div className="header">
                <span>{props.header}</span>
                {
                    props.onClose &&
                    <LcIconLink icon={<PiXLight />} onClick={() => props.onClose && props.onClose(false)} tooltip='Fechar' tooltipPosition='right' />
                    
                }
            </div>
            <div className="body scrollable-v" style={{ width: getWidth() }}>
                {props.children}
            </div>
        </div>
    );
}

export default SideModal;
import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/Layout/Layout';

//Redux
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { PrefState } from '../../store/reducers/prefReducer';
import { MenuState } from '../../store/reducers/menuReducer';
import { FilterState, setFilterActive } from '../../store/reducers/filterReducer';


import SideModal from '../../components/Layout/SideModal/SideModal';

import ManageOfficeStatusService from '../../services/manageOfficeStatus/manageOfficeStatus'

import LcIconLink from '../../components/Generic/LcIconLink';
import LcLoading from '../../components/Generic/LcLoading';
import { Typography } from '@material-ui/core';
import { TechEAEASService } from '../../services/EASubscriptionService/techEAEASService';

//Img Fluxo
import ImgFluxoEAEAS from '../../assets/images/Fluxos/eaeas.png';
import ImgFluxoCSP from '../../assets/images/Fluxos/csp.png';
import history from '../../history';
import { SaasService } from '../../services/saas/saasService';
import LcInfiniteTable from '../../components/Data/LcInfiniteTable';
import { PPInput, PPModal } from 'processor-plataform-ui'
import LcTooltip from '../../components/Generic/LcTooltip';
import LCDashboard, { Card } from '../../components/Data/Dashboard/LCDashboard';
import PPDropDown from '../../components/PPDropDown';
import FilterPanel from '../../components/Data/FilterPanel';
import { FilterField } from '../../components/Data/FilterPanel/FilterField';
import { HealthCheckTable } from '../../components/Layout/Cards/HealthCheckTable';

export const statusServices = [
        {
            statusNuvem: 'Investigating',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'ServiceDegradation',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'ServiceInterruption',
            statusLC: 'danger',
        },

        {
            statusNuvem: 'RestoringService',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'ExtendedRecovery',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'InvestigationSuspended',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'ServicesRestored',
            statusLC: 'success',
        },

        {
            statusNuvem: 'FalsePositive',
            statusLC: 'success',
        },

        {
            statusNuvem: 'PostIncidentReportPublished',
            statusLC: 'success',
        },

        {
            statusNuvem: 'ServiceOperational',
            statusLC: 'success',
        },

    ]

const ManageOfficeStatus: React.FC = (props) => {

    let isDev = process.env.NODE_ENV == 'development';

    const service = new ManageOfficeStatusService(props)

    const [assuntoFilter, setAssuntoFilter] = useState<string>('');

    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const menu = useSelector<RootState, MenuState>(state => state.menu);

    const [selectedStatus, setSelectedStatus] = useState<StatusOption | null>(null);


    // Objeto para rastrear os statusLC já adicionados
    const statusAdded = {};

    interface StatusOption {
        value: string;
        label: string;
    }

    const statusOptions: StatusOption[] = statusServices.reduce((acc: StatusOption[], s) => {
        if (!statusAdded[s.statusLC]) {
            acc.push({ value: s.statusLC, label: s.statusLC });
            statusAdded[s.statusLC] = true;
        }
        return acc;
    }, []);


    const dispatch = useDispatch();

    const [records, setRecords] = useState<any[]>([]);
    const [filteredRecords, setFilteredRecords] = useState<any[]>([]);

    const [filterPage, setFilterPage] = useState<{
        size: number,
        term: string,
        clientGroupId: string,
        isLinked: boolean,
        column: string,
        direction: string
    }>({
        size: 15, // ou o tamanho inicial que você desejar
        term: "",
        clientGroupId: "",
        isLinked: true,
        column: "workloadDisplayName",
        direction: "asc" // ou "desc", dependendo de como você quer ordenar
    });
    const [messagesSelected, setMessagesSelected] = useState<any[]>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [sideModal, setSideModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [fullDataRowCount, setFullDataRowCount] = useState<number>(0);
    const [visibleItemCount, setVisibleItemCount] = useState(0);


    useEffect(() => {
        async function executeUseEffect() {
            if (user.ClientGroupSelected) {
                setIsLoading(true);
                setFilteredRecords([]);
                service.GetServices().then(r => {
                    let data = r.data;
                    let mappedData = data.map((e) => {
                        const date = new Date(e.statusTime);
                        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;

                        return {
                            id: e.id,
                            workloadDisplayName: e.workloadDisplayName,
                            workload: e.workload,
                            credentialAlias: e.credentialAlias,
                            statusTime: formattedDate,
                            status: statusServices.find(x => x.statusNuvem === e.status)?.statusLC || 'info'
                        };
                    });

                    // Ordena inicialmente de acordo com filterPage
                    let sortedArray = sortRecords(mappedData, filterPage.column, filterPage.direction);
                    console.log(filterPage, sortedArray)


                    setRecords(sortedArray);
                    setFullDataRowCount(sortedArray.length);
                    setFilteredRecords(sortedArray.slice(0, filterPage.size));
                    setVisibleItemCount(filterPage.size);
                }).catch(error => {
                    console.warn(error);
                }).then(() => {
                    setIsLoading(false);
                });
            }
        }
        executeUseEffect();
    }, [user.ClientGroupSelected, user.refreshFlag]);



    //useEffect(() => {
    //    const filtered = records.filter(record =>
    //        record.workloadDisplayName.toLowerCase().includes(assuntoFilter.toLowerCase())
    //    );

    //    setFilteredRecords(filtered);
    //    setFullDataRowCount(filtered.length); // Atualiza a contagem total de registros com base nos registros filtrados
    //}, [records]);

    const [currentSort, setCurrentSort] = useState({ column: 'workloadDisplayName', direction: 'asc' });


    const showMessages = (item: any) => {
        setSelectedRow(item);
        setIsLoadingModal(true);
        setSideModal(!sideModal);

        service.GetAllMessagesByWorkLoad(item.workloadDisplayName)
            .then(r => {
                setMessagesSelected(r.data);
                setIsLoadingModal(false);
            });
    }

    const columnsInf = [
        { field: 'workloadDisplayName', headerName: 'Serviço', width: '60%' },
        {
            field: 'status', headerName: 'Status do serviço', width: '15%', overflow: 'visible',
            renderCell: (row: any) => {
                switch (row.status) {
                    case "success":
                        return (
                            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingRight: '70px' }}>
                                <LcTooltip content="No ar" position="center" trigger="hover">
                                    <i className='lci lci-dot-check'
                                        style={
                                            { fontSize: '24px', paddingLeft: '50px', color: "#008000" }
                                        }
                                    />
                                </LcTooltip>
                            </span>
                        )
                    case "warning":
                        return (
                            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingRight: '70px' }}>
                                <LcTooltip content="Aviso" position="center" trigger="hover">
                                    <i className='lci lci-dot-i text-warning'
                                        style={
                                            { fontSize: '24px', paddingLeft: '50px' }
                                        }
                                    />
                                </LcTooltip>
                            </span >
                        )
                    case "danger":
                        return (
                            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingRight: '70px' }}>
                                <LcTooltip content="Fora do ar" position="center" trigger="hover">
                                    <i className='lci lci-dot-exclamation text-danger'
                                        style={
                                            { fontSize: '24px', paddingLeft: '50px' }
                                        }
                                    />
                                </LcTooltip >
                            </span>
                        )
                    default:
                        return <i className='lci lci-dot-i text-info' />
                }
            }
        },
        {
            field: 'status', headerName: 'Mensagens', width: '20%', overflow: 'visible',
            renderCell: (row: any) => {
                if (row.status == 'danger' || row.status == 'warning') {
                    return (
                        <>
                            <span style={{ width: '100%', display: 'flex', alignItems: "center", flexDirection: 'row' }}>
                                <LcIconLink icon="lci lci-information-circle" size='small' tooltip="Ver detalhes" onClick={() => showMessages(row)} />
                                <span className='link' onClick={() => showMessages(row)}>Aviso</span>
                            </span>
                        </>
                    )
                }
            }
        }
    ]

    const onCloseSideModal = () => {
        setSideModal(false)
        setMessagesSelected([])
    }


    useEffect(() => {
        if (filterPage.size == 0) return;
       
        var filtered = records.filter((p: any) =>
            filterPage.term === '' ||
            p.workloadDisplayName.toLowerCase().includes(filterPage.term.toLowerCase()) ||
            p.credentialAlias.toLowerCase().includes(filterPage.term.toLowerCase()) ||
            p.statusTime.toLowerCase().includes(filterPage.term.toLowerCase())
        ).sort((a: any, b: any) => {
            if (filterPage.direction === 'asc') return (a[filterPage.column] > b[filterPage.column]) ? 1 : -1;
            return (a[filterPage.column] > b[filterPage.column]) ? -1 : 1;
        });

        const filteredSize = filtered.length;
        setFilteredRecords(filtered.slice(0, filterPage.size));
        setVisibleItemCount(Math.min(filterPage.size, filteredSize));
    }, [filterPage, records]);


    //#region Verifica se há licença EA/EAS
    let instance = new TechEAEASService(props);
    const [showFluxoEAEAS, setShowFluxoEAEAS] = useState(false);

    useEffect(() => {
        (!isDev && menu && menu.selectedItem && menu.selectedItem.id == 'cedcb443-a23e-4545-904b-5d1061e718aa') &&
            instance.GetSaasLicenseByCountryTotalView()
                .then(response => {
                    setShowFluxoEAEAS(response.data.length == 0)
                })
                .catch(error => {
                    setShowFluxoEAEAS(true);
                });
    }, [menu.selectedItem])
    //#endregion

    //#region Verifica se há GTM CSP
    const saasService = new SaasService(props);
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);

    useEffect(() => {
        if (!isDev && menu && menu.selectedItem && menu.selectedItem.id == '67aa56e4-ad63-44b0-aa27-d2d2a8665a63') {
            saasService.GetbyClientGroupIdAndTileId('13')
                .then(response => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [menu.selectedItem])
    //#endregion

    const sortRecords = (recordsToSort, column, direction) => {
        return recordsToSort.sort((a, b) => {
            // Ordenação para a coluna 'status'
            if (column === 'status') {
                const order = { 'danger': 1, 'warning': 2, 'success': 3, 'info': 4 };
                const statusOrderA = order[a.status] || 5;
                const statusOrderB = order[b.status] || 5;
                return direction === 'asc' ? statusOrderA - statusOrderB : statusOrderB - statusOrderA;
            }
            // Adicione aqui a lógica de ordenação para outras colunas, como 'mensagem'

            // Ordenação genérica para outras colunas
            if (direction === 'asc') {
                return a[column].localeCompare(b[column]);
            } else {
                return b[column].localeCompare(a[column]);
            }
        });
    };



    const onSortChange = (sortData) => {
        const { sort, size } = sortData;
        //console.log("Antes da Atualização - currentSort: ", currentSort);
        setCurrentSort({ column: sort.column, direction: sort.direction === 'asc' ? 'desc': 'asc'  });
        //console.log("Depois da Atualização - currentSort: ", { column: sort.column, direction: sort.direction });

        const sortedRecords = sortRecords([...records], sort.column, sort.direction  === 'asc' ? 'desc': 'asc'  );
        setRecords(sortedRecords);
        setFilteredRecords(sortedRecords.slice(0, size));
        setVisibleItemCount(size);
    };

    const loadMore = () => {
        const currentSize = filteredRecords.length;
        const additionalSize = 3;
        //console.log("Ao Carregar Mais - currentSort: ", currentSort);
        if(filteredRecords.length >= filterPage.size) {
            let newRecords = [...records].slice(0, currentSize + additionalSize);
            newRecords = sortRecords(newRecords, currentSort.column, currentSort.direction);
            setFilteredRecords(newRecords);
            setFullDataRowCount(records.length)
        } else{
            setVisibleItemCount(filteredRecords.length);
            setFullDataRowCount(filteredRecords.length)
                
        }
    };



    const status = (row: any) => {
        return row.status == 'success' ? '#008000' : row.status;
    }

    const formatDate = (stringDate) => {
        let date = new Date(stringDate)
        return `Atualizado em ${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
    }

    const formatHour = (stringDate) => {
        let date = new Date(stringDate)
        let GMT = date.toString().substring(25, 33).replace('0', '').replace('00', '')
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} (${GMT})`
    }

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const card:Card[] = [
        {
            id: 1,
            hideHeader: true,
            type: 'Custom',
            centeredContent: false,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <>
                        {
                            (showFluxoEAEAS || showFluxoCSP) ?
                            <div className="lc-segment">
                                <div className="body">
                                    Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                                    fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                                    <img src={showFluxoEAEAS ? ImgFluxoEAEAS : ImgFluxoCSP} width="100%" alt="Procedimento" />
                                </div>
                            </div>
                            :
                            // <LcInfiniteTable
                            //     rows={filteredRecords}
                            //     columns={columnsInf}
                            //     //loadMore={() => undefined}
                            //     loadMore={loadMore}
                            //     //filter={filterSystem}
                            //     size={fullDataRowCount}
                            //     onSortChange={onSortChange}
                            //     hidePagination
                            //     fontSize='medium'
                            //     status={status}
                            //     density={pref.data?.density || "medium"}
                            // />

                            <HealthCheckTable />
                        }
                    </>
                )
            }

        }
    ]

    const handleFilter = () => {
        let newFilteredRecords = records;

        // Filtrar por nome do serviço
        if (assuntoFilter.trim() !== '') {
            newFilteredRecords = newFilteredRecords.filter(record =>
                record.workloadDisplayName.toLowerCase().includes(assuntoFilter.toLowerCase())
            );
        }

        // Filtrar por status selecionado
        if (selectedStatus) {
            newFilteredRecords = newFilteredRecords.filter(record =>
                record.status === selectedStatus.value
            );
        }



        setFilteredRecords(newFilteredRecords);
        setFullDataRowCount(newFilteredRecords.length);

        // Ativar ou desativar o filtro com base se há registros após a filtragem
        dispatch(setFilterActive(newFilteredRecords.length > 0));
    };


    const handleClear = () => {
        setAssuntoFilter('');
        setSelectedStatus(null); // ou setSelectedStatus([]) se for um array
        setFilteredRecords(records);
        setFullDataRowCount(records.length);
        dispatch(setFilterActive(false));
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        handleFilter();
    };
    const translationsMissing = {
        workloadDisplayName: {
            label: "Serviço",
            type: "text"
        },
        status: {
            label: "Status do serviço",
            type: "multiSelect",
            values: {
                "warning": 'Aviso',
                "danger": 'Perigo',
                "success": 'No ar',
                "info": 'Informação',
            }
        },
    };
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
            <FilterPanel
                filter={Object.keys(translationsMissing).map(key => ({
                    label: translationsMissing[key].label,
                    name: key,
                    type: translationsMissing[key].type as FilterField['type'],
                    values: translationsMissing[key].values,
                }))}
                data={records}
                onFilteredData={(filteredData) =>{setFullDataRowCount(filteredData.length); setFilteredRecords(filteredData as any[])}}
                translations={translationsMissing}
                />
            </div>
        )
    }

    return (
        <Layout
            //cardType
            pageTitle="Disponibilidade dos serviços"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }

            gridFilter={{
                toggleVisibility: () => {
                    setShowFilterPanel(!showFilterPanel)
                },
                size: fullDataRowCount
            }}
        >

            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: showFilterPanel,
                    content: () => filterSystem()
                }}
            />

            <PPModal title={`Mensagens: ${selectedRow.workloadDisplayName}`} visible={sideModal} onClose={onCloseSideModal}>
                <LcLoading loading={isLoadingModal} label="Carregando mensagens...">
                    {
                        (messagesSelected == null || messagesSelected.length == 0) &&
                        <div>
                            <h4>
                                Não há dados disponíveis
                            </h4>
                        </div>
                    }
                    {
                        (!(messagesSelected == null || messagesSelected.length == 0)) && messagesSelected.map((item: any) => {
                            return (
                                <div key={item.id}>
                                    <div style={{ marginBottom: '1.5rem' }}>
                                        {`${formatDate(item.lastModifiedDateTime)} ${formatHour(item.lastModifiedDateTime)}`}
                                    </div>
                                    <Typography style={{ whiteSpace: "pre-line" }}>
                                        {`${item.impactDescription}`}
                                    </Typography>
                                    <hr></hr>
                                </div>
                            )
                        })
                    }
                </LcLoading>
            </PPModal>

        </Layout>
    )
}
export default ManageOfficeStatus;
import React, { useState, useEffect } from 'react';
import './index.css';

interface Props {
    id?: string,
    icon?: string | React.ReactNode;
    iconCharacter?: string,
    color?: string,
    onClick?: Function,
    disabled?: boolean,
    tooltip?: string,
    tooltipPosition?: 'left' | 'right' | 'inline-left' | 'inline-right',
    tooltipDelay?: boolean,
    badge?: {
        number: number,
        status: string
    },
    size?: 'small' | 'medium' | 'big',
    ref?: any,
    border?: string,
    clickble?: boolean,
    backgroundClickble?: string,
    notALink?: boolean,
    className?: string,
    degrade?: boolean,
    pulse?: boolean,
}

const LcIconLink: React.FC<Props> = (props) => {
    const disabled = props.disabled || false;
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [showPulse, setShowPulse] = useState(!!props.pulse);

    const uniqueId = props.id;

    useEffect(() => {
        if (props.pulse && uniqueId) {
            const clickCount = localStorage.getItem(`clickCount-${uniqueId}`);
            if (clickCount && parseInt(clickCount, 10) >= 5) {
                setShowPulse(false);
            }
        }
    }, [uniqueId, props.pulse]);

    const handleClick = () => {
        if (!disabled && props.onClick) {
            props.onClick();
        }

        if (props.pulse && uniqueId) {
            let clickCount = localStorage.getItem(`clickCount-${uniqueId}`);
            let newClickCount = clickCount ? parseInt(clickCount, 10) + 1 : 1;

            localStorage.setItem(`clickCount-${uniqueId}`, newClickCount.toString());

            if (newClickCount >= 5) {
                setShowPulse(false);
            }
        }
    };

    const handleMouseOver = () => {
        setTooltipVisible(true);
    };

    const handleMouseOut = () => {
        setTooltipVisible(false);
    };

    const iconLinkClass = `lc-iconlink${disabled ? ' disabled' : ''} ${props.size || ''} ${props.className || ''} ${props.notALink ? 'notALink' : ''} ${showPulse ? 'pulse' : ''}`;
    return (
        <div
            id={uniqueId}
            className={iconLinkClass}
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={{ border: props.border, color: (props.clickble && props.color) ? '#fff' : props.color, backgroundColor: props.clickble ? props.backgroundClickble ? props.backgroundClickble : '#E2E6F3' : '' }}
        >
            <div className="content">
                {
                    typeof props.icon === 'string'
                        ? <i className={`${props.icon} ${props.degrade ? 'degrade' : ''}`} style={{ color: props.color }}>{props.iconCharacter}</i>
                        : props.icon
                }
                {
                    props.badge && props.badge.number > 0 &&
                    <div className={`badge bg-${props.badge.status}`}>
                        <span>{props.badge.number > 99 ? '+99' : props.badge.number}</span>
                    </div>
                }
            </div>
            {
                (props.tooltip && tooltipVisible) &&
                <div className={`tooltip ${props.tooltipPosition || 'inline-left'} ${props.tooltipDelay ? ' delay' : ''}`}>
                    <div className={`body ${props.tooltipPosition || 'inline-left'}`}>
                        <span className={`arrow ${props.tooltipPosition || 'inline-left'}`} />
                        <div className={`content ${props.tooltipPosition || 'inline-left'}`}>
                            {props.tooltip}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default LcIconLink;

import api, { APIClient } from "../api";
import { SharePointSiteUsageDetail, SharePointSiteUsageStorage } from "./SharePointSiteUsageStorage";
export interface Office365ActivationReport {
    reportRefreshDate: string; // Dates in CSV are usually in string format
    userPrincipalName: string;
    displayName: string;
    productType: string;
    lastActivatedDate?: string; // Optional date
    windows: number;
    mac: number;
    windows10Mobile: number;
    ios: number;
    android: number;
    activatedOnSharedComputer: boolean;
}
export interface Office365EmailActivityUserDetailReport {
    reportRefreshDate: string; // ISO date string
    userPrincipalName: string;
    displayName: string;
    isDeleted: boolean;
    deletedDate?: string; // ISO date string or undefined
    lastActivityDate: string; // ISO date string
    sendCount: number;
    receiveCount: number;
    readCount: number;
    meetingCreatedCount: number;
    meetingInteractedCount: number;
    assignedProducts: string;
    reportPeriod: number;
}
export interface OneDriveUsageReport {
    reportRefreshDate: string; // ISO string representation of Date
    siteId: string;
    siteURL: string;
    ownerDisplayName: string;
    isDeleted: boolean;
    lastActivityDate: string | null; // ISO string representation of Date, null if not present
    fileCount: number;
    activeFileCount: number;
    storageUsed: number;
    storageAllocated: number;
    ownerPrincipalName: string;
    reportPeriod: number;
}
export interface Office365EmailActivityUserDetail {
    reportRefreshDate?: Date | null;
    userPrincipalName: string;
    displayName: string;
    isDeleted: boolean;
    deletedDate?: Date | null;
    lastActivityDate?: Date | null;
    sendCount: number;
    receiveCount: number;
    readCount: number;
    assignedProducts: string;
    reportPeriod: number;
}

export interface UserLicenseReport {
    reportRefreshDate: Date;
    userPrincipalName: string;
    displayName: string;
    isDeleted: boolean;
    deletedDate?: Date;
    hasExchangeLicense: boolean;
    hasOneDriveLicense: boolean;
    hasSharePointLicense: boolean;
    hasSkypeForBusinessLicense: boolean;
    hasYammerLicense: boolean;
    hasTeamsLicense: boolean;
    exchangeLastActivityDate?: Date;
    oneDriveLastActivityDate?: Date;
    sharePointLastActivityDate?: Date;
    skypeForBusinessLastActivityDate?: Date;
    yammerLastActivityDate?: Date;
    teamsLastActivityDate?: Date;
    exchangeLicenseAssignDate?: Date;
    oneDriveLicenseAssignDate?: Date;
    sharePointLicenseAssignDate?: Date;
    skypeForBusinessLicenseAssignDate?: Date;
    yammerLicenseAssignDate?: Date;
    teamsLicenseAssignDate?: Date;
    assignedProducts: string;
}
export interface EmailUsageUserReport {
    reportRefreshDate: Date;
    userPrincipalName: string;
    displayName: string;
    isDeleted: boolean;
    deletedDate?: Date;
    createdDate: Date;
    lastActivityDate: Date;
    itemCount: number;
    storageUsed: number;
    issueWarningQuota: number;
    prohibitSendQuota: number;
    prohibitSendReceiveQuota: number;
    deletedItemCount: number;
    deletedItemSize: number;
    deletedItemQuota: number;
    hasArchive: boolean;
    reportPeriod: number;
}
export class SaasService extends APIClient {

    constructor(props: any) {
        super(props);
        this.endpoint = this.endpoint;
    }

    async GetCards(month: string, year: string) {

        const url = this.endpoint + `/api/Saas/GetProductSlaTotalSolicitation?month=${month}&year=${year}`;
        return api({
            method: 'get',
            url,

        });
    }
    async GetUsersChart(days: number) {
        const url = this.endpoint + `/api/Saas/GetOffice365ActiveUserCountsClientGroupDays?days=${days}`;
        return (await api({
            method: 'get',
            url,

        })).data;
    }
    async GetOffice365ActiveUserDetail(days: number): Promise<UserLicenseReport[]> {
        try {
            const url = this.endpoint + `/api/Saas/GetOffice365ActiveUserDetail?days=${days}`;
            return (await api({
                method: 'get',
                url,

            })).data;
        } catch (e) {
            console.error(e);
            return [] as UserLicenseReport[];
        }

    }

    async RetrieveUserLicenses() {
        try {
            const url = this.endpoint + `/api/Saas/RetrieveUserLicenses`;
            return api({
                method: 'get',
                url,

            });
        } catch (e) {
            console.error(e);
            return { data: [] };
        }
    }

    async GetActivationChart() {
        try {
            const url = this.endpoint + `/api/Saas/GetOffice365ActivationCounts`;
            return api({
                method: 'get',
                url,

            });
        } catch (e) {
            console.error(e);
            return { data: [] };
        }
    }

    async GetOffice365ActivationReport(): Promise<Office365ActivationReport[]> {
        try {
            const url = this.endpoint + `/api/Saas/GetOffice365ActivationDetails`;
            return (await api({
                method: 'get',
                url
            })).data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async GetEmailActivityChart(days: number) {
        try {
            const url = this.endpoint + `/api/Saas/GetGraphOffice365EmailActivityCount?days=${days}`;
            return api({
                method: 'get',
                url,

            });
        } catch (e) {
            console.error(e);
            return { data: [] };
        }
    }
    async GetGraphOffice365EmailActivityDetail(days: number): Promise<Office365EmailActivityUserDetailReport[]> {
        try {
            const url = this.endpoint + `/api/Saas/GetGraphOffice365EmailActivityDetail?days=${days}`;
            return (await api({
                method: 'get',
                url,

            })).data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }
    async GetOneDriveUsageAccountDetail(days: number): Promise<OneDriveUsageReport[]> {
        try {
            const url = this.endpoint + `/api/Saas/GetOneDriveUsageAccountDetail?days=${days}`;
            return (await api({
                method: 'get',
                url,

            })).data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async GetEmailUsageChart(days: number) {
        try {
            const url = this.endpoint + `/api/Saas/GetMailBoxUsageMailBoxCounts?days=${days}`;
            return api({
                method: 'get',
                url,

            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async GetMailboxUsageDetail(days: number): Promise<EmailUsageUserReport[]> {
        try {
            const url = this.endpoint + `/api/Saas/GetMailboxUsageDetail?days=${days}`;
            return (await api({
                method: 'get',
                url,

            })).data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async GetOneDriveUsageChart(days: number) {
        try {
            const url = this.endpoint + `/api/Saas/GetGraphOffice365OneDriveUsageAccountCounts?days=${days}`;
            return api({
                method: 'get',
                url,

            });
        } catch (e) {
            console.error(e);
            return { data: [] };
        }
    }
    async GetOffice365EmailActivityUserDetailAsync(days: number): Promise<Office365EmailActivityUserDetail[]> {
        try {


            const url = this.endpoint + `/api/Saas/GetOffice365EmailActivityUserDetail?days=${days}`;
            return (await api({
                method: 'get',
                url,

            })).data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }
    async GetOffice365EmailActivityUserDetail(days: number) {
        try {


            const url = this.endpoint + `/api/Saas/GetOffice365EmailActivityUserDetail?days=${days}`;
            return api({
                method: 'get',
                url,

            });
        } catch (e) {
            console.error(e);
            return { data: [] };
        }
    }

    async GetMailBoxUsageMailBoxUsage(days: number) {
        try {


            const url = this.endpoint + `/api/Saas/GetMailBoxUsageMailBoxUsage?days=${days}`;
            return api({
                method: 'get',
                url,

            });
        } catch (e) {
            console.error(e);
            return { data: [] };
        }
    }

    async GetSharePointSiteUsageDetail(days: number): Promise<SharePointSiteUsageDetail[]> {

        try {
            const url = this.endpoint + `/api/Saas/GetSharePointSiteUsageStorage?days=${days}`;
            return (await api({
                method: 'get',
                url,
            })).data;
        } catch (e) {
            console.error(e);
        }
        return [] as unknown as SharePointSiteUsageDetail[];
    }

    async GetOneDriveUsageAccountReport(days: number) {
        try {


            const url = this.endpoint + `/api/Saas/GetOneDriveUsageAccountReport?days=${days}`;
            return (await api({
                method: 'get',
                url,

            })).data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async GetUserCount() {
        const url = this.endpoint + `/api/Saas/GetGraphOffice365ActivationsUserCounts`;
        return api({
            method: 'get',
            url,

        });
    }

    async GetAWSDashboardEmbedUrl(dashid: string) {
        const url = this.endpoint + `/api/Saas/GetAWSDashboardEmbedUrl?dashid=${dashid}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        });
    }

    async GetbyClientGroupIdAndTileId(tileId: string) {
        const url = this.endpoint + `/api/Saas/GetbyClientGroupIdAndTileId?tileId=${tileId}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        });
    }



}
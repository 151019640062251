import React, { ReactElement } from "react";
import LcNoData from "../../../Generic/LcNoData";

import LcTooltip from '../../../Generic/LcTooltip';

import "./index.css";

type Position = "left" | "center" | "right";

interface Props {
    title?: string,
    icon?: any,
    value?: string | number | ReactElement,
    smallValue?: string | number,
    color?: string,
    unity?: string,
    description?: string,
    tooltip?: string,
    info?: React.ReactNode,
    tooltipInfo?: string,
    tooltipInfoPosition?: Position
    formatValue?: boolean,
    maximumFractionDigits?: number,
    minimumFractionDigits?: number,
    onClick?: Function,
    imageIcon?: JSX.Element,
    withoutValue?: boolean,
    selected?: boolean,
    backgroundColor?: string,
    hearderStorege?: boolean,
    noDataLabel?: string,
    isHome?: boolean,
    cssClass?: string,
    fontSizeValue?:string,
    fontSizeIcon?:string
    smalLcNoData?: boolean
    colorValue?: string
    balonSelected?: boolean
}

const IconValue: React.FC<Props> = (props) => {
    const maximumFractionDigits = props.maximumFractionDigits == null ? 2 : props.maximumFractionDigits;
    const minimumFractionDigits = props.minimumFractionDigits == null ? 0 : props.minimumFractionDigits;
    const getValue = () => {
        let original = props.value ? props.value.toString() : "";
        if (!original) return "0";


        if (original == 'undefined') return (
            <>
                <LcNoData size={props.smalLcNoData ? 'xsmall':"small"} />
            </>
        );

        return props.formatValue ? parseFloat(original).toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits }) : original;
    };

    function shouldDisplayContent() {
        return (props.value != 'undefined' && props.value != undefined);
    }
    const isElement = (element) => {
        return React.isValidElement(element);
    }
    return (
        <>
            {shouldDisplayContent() ? (
            <div style={{height: '100%',position: 'relative'}}>
                <div className={`${props.isHome ? 'iconvaluecontent grey' : 'iconvaluecontent'} ${props.cssClass ? props.cssClass : ''}`} onClick={() => props.onClick && props.onClick()} style={{
                    minHeight: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: props.color,
                    cursor: props.onClick ? 'pointer' : '',
                }}>
                    {props.icon &&
                        typeof props.icon === 'string' ? <div className="icone" style={{display:'flex'}}><i className={`icon ${props.icon}`} style={{ fontSize: props.fontSizeIcon ? props.fontSizeIcon :'4vh' }}></i></div> : (
                            <div className="icone" style={{ fontSize: props.fontSizeIcon ? props.fontSizeIcon : '' , display:'flex'}}>{props.icon}</div>
                    )
                    }

                    {props.withoutValue && props.icon &&
                        <div style={{ justifyContent: 'center', width: '100%', height: '100%', fontSize:  props.hearderStorege ? '4vh' : '4vh' }}>
                            {typeof props.icon === 'string' ? <div className="icone" style={{display:'flex'}}><i className={`icon ${props.icon}`}  ></i></div> : (
                                <div className="icone" style={{display:'flex'}}>{props.icon}</div> // This could be a React element or null
                            )}
                        </div>
                    }

                    {props.imageIcon &&
                        (
                            <div style={{ justifyContent: 'center' }}>
                                {props.imageIcon}
                            </div>
                        )
                    }

                    {!props.withoutValue &&
                        <div className="truncate-container">
                            <div className="data right truncate-text" style={{ minWidth: '100%', textAlignLast: 'right', fontSize: props.fontSizeValue ? props.fontSizeValue : props.hearderStorege ? '3vh' : '', }}>
                                {isElement(props.value) ? props.value :
                                    <div style={{ fontSize: props.isHome ? '1.3vw' : '1.8vw', alignItems: 'center', color: props.colorValue ? props.colorValue : '' }}>{getValue()}{props.unity}</div>
                                }
                            </div>
                            {
                                props.description &&
                                <div className={(props.isHome ? "description truncate-text home" : "truncate-text description")}>
                                    {props.description}
                                </div>
                            }

                        </div>
                    }
                </div>
                {
                    props.balonSelected &&
                    <div className={props.cssClass === 'withHeaderButton' ? 'balonCard withHeaderButton' : "balonCard"} />
                }
            </div>
            
            ) : (
                <LcNoData size={props.smalLcNoData ? 'xsmall': "small"} label={props.noDataLabel} />
            )}
        </>
    );
}

export default IconValue;

import React, { useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query'; 
import { Button, CircularProgress, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form'; 
import { SaaSSubscriptionCostCenterParameters, TechEAEASService } from '../../../../services/EASubscriptionService/techEAEASService';

const EAParametersForm: React.FC = () => {
    const queryClient = useQueryClient();
    const { control, handleSubmit, reset } = useForm<SaaSSubscriptionCostCenterParameters>();
    const techEAEASService = new TechEAEASService({});

    const { data, error, isLoading } = useQuery('saasSubscriptionParameter', () => techEAEASService.fetchSaaSSubscriptionParameters(), {
        onSuccess: (data) => reset(data),
    });

    const mutation = useMutation((params: SaaSSubscriptionCostCenterParameters) => techEAEASService.saveSaaSSubscriptionParameters(params), {
        onSuccess: () => {
            queryClient.invalidateQueries('saasSubscriptionParameter');
            alert('Salvo com sucesso');
        },
    });

    const onSubmit = (data: SaaSSubscriptionCostCenterParameters) => {
        mutation.mutate(data);
    };

    if (isLoading) return <CircularProgress />;
    if (error) return <div>Erro ao carregar dados</div>;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>          
            <Controller
                name="customField"
                control={control}
                render={(field) => <TextField {...field} label="Campo Personalizado" fullWidth />}
            />
            <Controller
                name="alternateCountryField"
                control={control}
                render={(field) => <TextField {...field} label="Campo Alternativo de Pa�s" fullWidth />}
            />
            <Controller
                name="alternateCountryFieldRegex"
                control={control}
                render={(field) => <TextField {...field} label="Regex do Campo Alternativo de Pa�s" fullWidth />}
            />
            <Controller
                name="alternateRegionField"
                control={control}
                render={(field) => <TextField {...field} label="Campo Alternativo de Regi�o" fullWidth />}
            />
            <Controller
                name="alternateRegionFieldRegex"
                control={control}
                render={(field) => <TextField {...field} label="Regex do Campo Alternativo de Regi�o" fullWidth />}
            />
            <Button type="submit">Salvar</Button>
        </form>
    );
};

export default EAParametersForm;

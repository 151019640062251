import api, { APIClient } from "../api";


export interface SKUs {
    skuId: string,
    description: string,
    isChecked: string,
    isLicensed: boolean
}

export interface LicsModel {
    userId: string,
    nomeUsuario: string,
    licencas: [
        {
            skuId: string,
            description: string,
            isChecked: string,
            isLicensed: true
        }
    ],
    currentLocation: string
}

export interface License {
    id: string,
    clientGroupId: string,
    country: string,
    license: string,
    costCenter: string,
    totalHired: number,
    totalUsed: number,
    totalCC: number,
    groupName: string,
    skuId: string,
    eaSubscriptions: string,
    skuPartNumber: string,
    updatedAt: Date,
    unitPrice: number
}

export interface User {
    id: string,
    clientGroupId: string,
    userGraphId: string,
    userName: string,
    costCenter: string,
    country: string,
    license: string,
    updatedAt: Date
}

//export interface SaaSSubscriptionCostCenterParameters {
//    clientGroupId: number;
//    vendorCredentialId: number;
//    cloudOsProviderId: number;
//    allowsImportLicenses: number;
//    allowsImportCustomField: number;
//    customField: string;
//}
export interface SaaSSubscriptionCostCenterParameters {
    clientGroupId: number;
    vendorCredentialId: number;
    cloudOsProviderId: number;
    allowsImportLicenses: number;
    allowsImportCustomField: number;
    customField: string;
    alternateCountryField: string;
    alternateCountryFieldRegex: string;
    alternateRegionField: string;
    alternateRegionFieldRegex: string;
}
export class TechEAEASService extends APIClient {

    public controllerpath: string = "/api/SaaSSubscription";
    constructor(props: any) {
        super(props);
    }

    async UpdateQL(ql: any) {

        const url = `${this.endpoint}/api/VinSubscriptionToEA/UpdateQL`;
        const data = (ql)
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json',
            }
        })
    } 

    async fetchSaaSSubscriptionParameters(): Promise<SaaSSubscriptionCostCenterParameters> {
        const url = this.endpoint + `${this.controllerpath}/QuerySaaSSubscriptionCostCenterParameters`;
        const { data } = await api.get(url);
        return data;
    };

    async saveSaaSSubscriptionParameters(params: SaaSSubscriptionCostCenterParameters): Promise<void> {
        const url = this.endpoint + `${this.controllerpath}/SaveSaaSSubscriptionCostCenterParameters`;
        await api.post(url, params);
    };

    async UpdateOrcado(orcado: any) {

        const url = `${this.endpoint}/api/VinSubscriptionToEA/UpdateOrcado`;
        const data = (orcado)
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json',
            }
        })
    }

    async GetSaasLicenseByCountryTotalViewByUsers(costCenter: any, country: any, sku: any) {

        const url = this.endpoint + `${this.controllerpath}/GetSaasLicenseByCountryTotalViewByUsers/${costCenter}/${country}/${sku}?refresh=${Math.random()}`;

        return api({
            method: 'get',
            url
        });
    }

    async GetSaasLicenseByCountryTotalView() {
        const url = this.endpoint + `${this.controllerpath}/GetSaasLicenseByCountryTotalView?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        });
    }

    async GetUsersSaasLicenseByCountryCostCenterByClientGroupId() {
        const url = this.endpoint + `${this.controllerpath}/GetUsersSaasLicenseByCountryCostCenterByClientGroupId?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        });
    }
    async QueryRecordQL() {
        const url = this.endpoint + `${this.controllerpath}/QueryRecordQL?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        });
    }
    async QueryRecordOrcado() {
        const url = this.endpoint + `${this.controllerpath}/QueryRecordOrcado?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        });
    }
    async QueryRecordAdicionais() {
        const url = this.endpoint + `${this.controllerpath}/QueryRecordAdicionais?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url
        });
    }

    async GetSaasLicenseByCountryTotalViewbyUser() {
        const url = this.endpoint + `${this.controllerpath}/GetSaasLicenseByCountryTotalViewbyUser?refresh=${Math.random()}`;

        return api({
            method: 'get',
            url
        });
    }
    async QuerySaaSSubscriptionCostCenterParameters() {
        const url = this.endpoint + `${this.controllerpath}/QuerySaaSSubscriptionCostCenterParameters?refresh=${Math.random()}`;

        return (await api({
            method: 'get',
            url
        })).data;
    }

    async GetSubcribeSKUClientGroup(userId: string) {
        const url = this.endpoint + `${this.controllerpath}/GetSubcribeSKUClientGroup/${userId}?refresh=${Math.random()}`;

        return api({
            method: 'get',
            url
        });
    }

    async PostSKUsByUser(licsModel: LicsModel) {
        const url = this.endpoint + `${this.controllerpath}/GetSubcribeSKUClientGroup`;

        return api({
            method: 'post',
            url,
            data: licsModel
        });
    }

    async SaveSaaSSubscriptionCostCenterParameters(dataSaaSSubscriptionCostCenterParameters: SaaSSubscriptionCostCenterParameters) {
        const url = this.endpoint + `${this.controllerpath}/SaveSaaSSubscriptionCostCenterParameters`;

        return api({
            method: 'post',
            url,
            data: dataSaaSSubscriptionCostCenterParameters
        });
    }

    async UploadPositionsCSV(csv: string) {
        const url = this.endpoint + `${this.controllerpath}/UpdatePositionsData`;

        var bodyFormData = new FormData();
        bodyFormData.append('csvString', csv);

        return api({
            method: 'post',
            url,
            data: bodyFormData
        })
    }

    async GetPositionsCSV() {
        const url = this.endpoint + `${this.controllerpath}/GetPositionsData`;

        return api({
            method: 'get',
            url
        });
    }
}
import { useEffect, useState } from 'react';
import './index.css';
import { PPTooltip } from 'processor-plataform-ui';

interface InputFileProps {
    title: string,
    className?: string,
    width?: string,
    onChange: Function,
    multiple?: boolean,
    maxSize?: number,
    disabled?: boolean,
    acceptedTypes?: string[]
}
interface File {
    name: string,
    size: number,
    type: string,
    body: string
}

const PPInputFile: React.FC<InputFileProps> = (props) => {

    const [id] = useState(Date.now.toString());

    const [files, setFiles] = useState<File[]>([])
    const [dragging, setDragging] = useState(false);
    const [msg, setMsg] = useState('');

    const handleClickAddFile = () => {
        let el = document.getElementById(id);
        el?.click();
    }


    useEffect(() => {
        props.onChange(files);
    }, [files])

    const handleChangeFile = (event: any) => {

        setMsg("")

        event.target.files.length > 0 &&
            Array.from(event.target.files).forEach((file: any) => {
                handleFile(file);
            });
    }

    const handleDropFile = (event: any) => {
        event.preventDefault();
        setDragging(false);
        setMsg("")

        event.dataTransfer.files.length > 0 &&
            Array.from(event.dataTransfer.files).forEach((file: any) => {
                handleFile(file);
            });
    }

    const handleFile = (file: any) => {
        const { name, size, type } = file;

        if (props.maxSize && size > props.maxSize) {
            setMsg("O arquivo ultrapassa o limite definido")
        } else if (props.acceptedTypes && !props.acceptedTypes.includes(type)) {
            setMsg("Formato ou extensão de arquivo inválido")
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                const body = reader.result!.toString();
                setFiles([...files, { name, size, type, body }])
            }
            reader.readAsDataURL(file);
        }

    }


    const fileTypes = ["image/png", "image/bmp", "image/jpeg", "image/gif"]


    return <div
        className={`pp-inputfile ${props.className || ''}`}
        style={{ width: props.width || '100%' }} >

        {
            props.title &&
            <div className="title">{props.title}</div>

        }

        <div className={`body${dragging ? ' dragging' : ''}`}

            onDrop={handleDropFile}
            onDragOver={event => {
                setDragging(true);
                event.preventDefault();
            }}
            onDragLeave={event => {
                setDragging(false);
                event.preventDefault();
            }}
        >

            <input
                id={id}
                type="file"
                multiple={props.multiple}
                onChange={handleChangeFile}
            />

            {
                files.length > 0 &&
                <div className="files">
                    {
                        files.map((file, i) => {
                            return <div key={i} className='file'>

                                <PPTooltip trigger='hover' content={
                                    <div className='column'>
                                        <span><b>Nome:</b> {file.name}</span>
                                        <span><b>Tipo:</b> {file.type}</span>
                                        <span><b>Tamanho:</b>  {(file.size / 1024).toFixed(2)}Kb</span>
                                    </div>
                                }>
                                    {
                                        fileTypes.includes(file.type) ?
                                            <img src={file.body} />
                                            :
                                            <i className='ppi ppi-document ppi-7x' />
                                    }
                                </PPTooltip>

                                <div className="actions">
                                    <i className='ppi ppi-trash link'
                                        onClick={() => {
                                            setFiles(files.filter(f => f.body != file.body))
                                        }}
                                    />
                                </div>
                            </div>
                        })
                    }
                </div>
            }



            <button
                className='pp-button mt-5'
                type='button'
                onClick={() => handleClickAddFile()}
                disabled={props.disabled || (!props.multiple && files.length > 0)}
            >Selecionar arquivo</button>
            <div className="msg mt-4 text-warning">{msg}</div>
        </div>

    </div>;
}

export default PPInputFile;
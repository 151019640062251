import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { IconButton, Typography, Box } from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { Assistant } from '../../../services/AIx/assistantsTypes';
import { AssistantService } from '../../../services/AIx/assistants/assistantsService';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import AssistantEditDialog from '../AssistantEditDialog'; // Novo componente importado
import './index.css';

interface TableColumn {
    field: string;
    headerName?: string;
    width?: string | number;
    align?: string;
    hide?: boolean;
    truncate?: boolean;
    expandable?: boolean;
    renderCell?: (row: any, density?: string) => JSX.Element | JSX.Element[] | string | undefined | any;
    showOnHover?: boolean;
    fontSize?: string;
    overflow?: string;
}

const AssistantsList: React.FC = (props) => {
    const [selectedAssistant, setSelectedAssistant] = useState<Assistant | null>(null);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showThreadDialog, setShowThreadDialog] = useState(false);
    const queryClient = useQueryClient();

    const { data, error, isLoading } = useQuery<Assistant[]>('assistants', async () => {
        return await (new AssistantService(props)).getAssistants();
    });

    const handleShowEditForm = (assistant: Assistant) => {
        setSelectedAssistant(assistant);
        setShowEditForm(true);
    };

    const handleAddAssistant = () => {
        setSelectedAssistant({ id: '', name: '', instructions: '', model: 'gpt-4o' } as unknown as Assistant); // Create an empty assistant object
        setShowEditForm(true);
    };

    const handleCloseEditForm = () => {
        setShowEditForm(false);
        setSelectedAssistant(null);
        queryClient.invalidateQueries('assistants');
    };

    const handleCloseThreadDialog = () => {
        setShowThreadDialog(false);
        setSelectedAssistant(null);
    };

    if (error) {
        return <Typography>Error: {error}</Typography>;
    }

    const functionsGeneric = [
        {
            title: 'Novo',
            tooltip: 'Criar grupo',
            icon: 'ppi ppi-plus',
            onClick: () => { handleAddAssistant() },
            disabled: false
        }
    ];

    const columns: TableColumn[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: '30%',
            align: 'left',
            renderCell: (row: Assistant) => <span>{row.name}</span>,
        },
        {
            field: 'model',
            headerName: 'Model',
            width: '30%',
            align: 'left',
            renderCell: (row: Assistant) => <span>{row.model}</span>,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: '40%',
            align: 'center',
            renderCell: (row: Assistant) => (
                <div className="action-buttons">
                    <IconButton
                        onClick={() => handleShowEditForm(row)}
                        title="Edit"
                        className="icon-button"
                    >
                        <FaEdit />
                    </IconButton>
                    {/* Add more buttons if needed */}
                </div>
            ),
        },
    ];

    return (
        <Layout
            pageTitle="Assistentes"
            loading={false}
            className="dashboard-layout"
            functionsGeneric={functionsGeneric}
        >
            {isLoading && <LcLoading loading={isLoading} />}

            {!showEditForm && data && data.length > 0 && (
                <Box className="table-container">
                    <LcInfiniteTable
                        columns={columns}
                        rows={data}
                    />
                </Box>
            )}

            {showEditForm && selectedAssistant && (
                <AssistantEditDialog
                    assistant={selectedAssistant}
                    handleCloseEditForm={handleCloseEditForm}
                    handleCloseThreadDialog={handleCloseThreadDialog}
                />
            )}
        </Layout>
    );
};

export default AssistantsList;

import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
/*import './index.css';*/
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import LcLoading from '../../../components/Generic/LcLoading';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import LCDashboard, { Card, PositionCard } from '../../../components/Data/Dashboard/LCDashboard';
import { Chart, CollectoresModel, ExceptionModal, OperacialSystemEvent, ResumeEvent, devicesEvent, processEvent } from '../resumeEvent/Models';
import EventsGraph from '../resumeEvent/EventsGraph';
import MostTargetGraph from '../resumeEvent/MostTargetGraph';
import GaugeChartCore from '../../../components/Layout/Dashboard/gaugeChartCore';
import { FinOpsChartService } from '../../../services/finOps/finOpsChartService';
import ReactECharts from 'echarts-for-react';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import PPDropDown from '../../../components/PPDropDown';
import history from '../../../history';
import { PiArrowSquareOutLight, PiBriefcaseLight, PiDatabaseLight, PiFileCsvLight, PiFilePdfLight, PiMonitorLight, PiPackageLight, PiShieldCheck, PiShieldCheckLight, PiShieldWarningLight } from 'react-icons/pi';
import './index.css'
import LcNoData from '../../../components/Generic/LcNoData'; 
import { MdAppBlocking } from 'react-icons/md';
import { AiOutlineException } from 'react-icons/ai';
import moment from 'moment';
import ImgLog from '../../../assets/images/logo.png';
import { createRoot } from 'react-dom/client';
import ReportResumePDF from './ReportResumePDF';
import imgHorizantalBar from '../../../assets/images/horizontalbar_print.png';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { BsFiletypeCsv, BsFiletypePdf } from 'react-icons/bs';
import { useSnackbar } from 'notistack';
import exportFromJSON from 'export-from-json';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}
interface EndpointDetectionResponseResumeProps {
    isHome?: boolean,
    qtdGroups: number
    secOpsQtdGroups: number
    isSecOpsPage?: boolean
}
export interface MostTargetChartModel {
    labels: string[]
    colors: string[]
    data: number[]
}
interface DeviceData {
    device: string;
    classification: string;
    count: number;
}

type DataEntry = devicesEvent | ExceptionModal | CollectoresModel | processEvent;

const EndpointDetectionResponseResume: React.FC<EndpointDetectionResponseResumeProps> = (props) => {
    const finOpsChartService: FinOpsChartService = (new FinOpsChartService(props));
    const endpointDetectionResponseService = new EndpointDetectionResponseService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [iotData, setIotData] = useState<any>();
    const [serversData, setServersData] = useState<any>();
    const [workstationsData, setWorkstationsData] = useState<any>();
    const [eventsChart, setEventsChart] = useState<any>();
    const [mostTargetedChart, setMostTargetedChart] = useState<MostTargetChartModel>({labels:[], colors:[], data:[]});
    const [mostTargetedCollectorsChart, setMostTargetedCollectorsChart] = useState<MostTargetChartModel>({labels:[], colors:[], data:[]});
    const [sloChart, setSloChart] = useState<number>(0);
    const [slaChart, setSlaChart] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingExceptions, setLoadingExceptions] = useState<boolean>(true);
    const [loadingEventsBlock, setLoadingEventsBlock] = useState<boolean>(true);
    const [loadingCollectoresChart, setLoadingCollectoresChart] = useState<boolean>(true);
    const [loadingMostTarget, setLoadingMostTarget] = useState<boolean>(true);
    const [chamadosChart, setChamadosChart] = useState<Chart>();
    const [isLoadingChamadosChart, setIsLoadingChamadosChart] = useState<boolean>(true);
    const [totalChamadosChart, setTotalChamadosChart] = useState<Chart>();
    const [isLoadingTotalChamadosChart, setIsLoadingTotalChamadosChart] = useState<boolean>(true);
    const [chamadosPorOrigemChart, setChamadosPorOrigemChart] = useState<Chart>();
    const [isLoadingChamadosPorOrigemChart, setIsLoadingChamadosPorOrigemChart] = useState<boolean>(true);
    const [day, setDay] = useState<string>('01');
    const [month, setMonth] = useState<string>(moment().format('M'));
    const [year, setYear] = useState<string>(moment().format('YYYY'));
    const [searchStartDate, setSearchStartDate] = useState<string>(`${year}${month.padStart(2, '0')}${day}`);
    const [resumeEventReport, setResumeEventReport] = useState<ResumeEvent>({devices: [], process: [], classification:[], collectors: [], coletoresOperationalSystems: [], listCollectores: [], mostTargetsCollectores: []});
    const [colectorStatus, setColectorStatus] = useState<any>()
    const [colectorSystem, setColectorSystem] = useState<Chart>()
    const [collectorsView, setCollectorsView] = useState<string>('geral');
    const [slaView, setSlaView] = useState<string>('sla');
    const [totalEventsBlock, setTotalEventsBlock] = useState<number>(0);
    const [totalExcections, setTotalExcections] = useState<number>(0);
    const [periodFilter, setPeriodFilter] = useState<string>('allTime')
    const [mostTargetSelected, setMostTargetSelected] = useState<string>('user')
    const [exception, setException] = useState<ExceptionModal[]>([]);
    const [eventBlocked, setEventsBlocked] = useState<devicesEvent[]>([])
    const [eventBlockedCollectors, setEventsBlockedCollectors] = useState<devicesEvent[]>([])
    const [processEvents, setProcessEvents] = useState<processEvent[]>([])
    const [systemOperation, setSystemOperation] = useState<OperacialSystemEvent[]>([])
    //identificando charts
    const chartDispositivosGeral = useRef<ReactECharts>(null);
    const [chartRefEvents, setChartRefEvents] = useState<any>();
    const [chartRefDivices, setChartRefDivices] = useState<any>();
    const [focus, setFocus] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();
    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setPeriodFilter(date);
    };
    const beginOfMonth = () => {
        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
    
        let result = false;
    
        if (dayOfMonth > 0) {
            result = false;
        } else {
            result = true;
        }
        return result;
    }
    const periodSelection = {
        periods: [
            {
                label: 'Período total', text: 'Período total',
                fields: [
                    { name: 'alltime', value: 'alltime' },
                    
                ],
                default: true
            },
            {
                label: 'Útimos 7 dias', text: 'Útimos 7 dias',
                fields: [
                    { name: 'last7Days', value: 'last7Days' },
                    
                ],
                
                default: beginOfMonth()
            },
            {
                label: 'Útimos 30 dias', text: 'Útimos 30 dias',
                fields: [
                    { name: 'last30Days', value: 'last30Days' }
                ],
                default: beginOfMonth()
            },
            {
                label: 'Últimos 3 meses', text: 'Últimos 3 meses',
                fields: [
                    { name: 'last90Days', value: 'last90Days' }
                ],
                default: beginOfMonth()
            },
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };
    const [periodSelected, setPeriodSelected] = useState<any>(periodSelection)


    const groupBy = (items, key) => items.sort(function (a, b) {
        if (a.classificationRank < b.classificationRank) return 1;
        if (a.classificationRank > b.classificationRank) return -1;
        return 0;
    }).reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );

    const slaOptions = [
        { label: 'SLA', value: "sla" }, { label: 'SLO', value: "slo" }
    ];

    const collectorsOptions = [
        { label: 'Geral', value: "geral" }, { label: 'Operacional', value: "system" }
    ];
 
    const mostTargetsOpt = [{label: 'Usuários', value: 'user' }, {label: 'Coletores', value: 'collectors' }]

    const positions5Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 4, col: 1, hidden: !props?.isSecOpsPage},
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: !props?.isSecOpsPage},
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: !props?.isSecOpsPage},
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 1, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
        }];

    const positions3Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 4, col: 1, hidden: !props?.isSecOpsPage},
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden: !props?.isSecOpsPage},
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: !props?.isSecOpsPage},
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 1, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
        }];

    const positions2Groups: Card[] = [
        {
            id: 1, position: { ColSpan: 2, RowSpan: 1, row: 4, col: 1, hidden: props.secOpsQtdGroups > 1 ? true: false},
        },
        {
            id: 2, position: { ColSpan: 2, RowSpan: 1, row: 5, col: 1, hidden:props.secOpsQtdGroups > 1 ? true: false},
        },
        {
            id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: props.secOpsQtdGroups > 1 ? true: false},
        },
        {
            id: 4, position: { ColSpan: 1, RowSpan: 1, row: 1, col: 10, hidden: true },
        },
        {
            id: 5, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: false },
        },
        {
            id: 6, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5, hidden: true },
        },
        {
            id: 7, position: { ColSpan: 1, RowSpan: 3, row: 4, col: 2, hidden: true },
        }];

    function positionHome(cardId: number | undefined, qtdGroups: number): PositionCard {
        var Id = cardId as number;
        if (qtdGroups <= 2) {
            return positions2Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter(c => c.id == Id)[0].position;
        }

        return {} as unknown as PositionCard;
    }

    useEffect(() => {
        setSlaChart(0);
        // setChamadosChart([]);
        // setTotalChamadosChart([]);
        // setChamadosPorOrigemChart([]);
        setLoading(true);
        setLoadingEventsBlock(true)
        setLoadingMostTarget(true)
        if (user.ClientGroupSelected) {
            
            endpointDetectionResponseService.RequestEventsReportResume()
                .then(responseEventsReportResume => {
                    setResumeEventReport(responseEventsReportResume.data);
                    getClassificationChart(responseEventsReportResume.data.devices);
                    setMostTargetedChart(getMostTargetChart(responseEventsReportResume.data.devices));
                    setMostTargetedCollectorsChart(getMostTargetChart(responseEventsReportResume.data.mostTargetsCollectores));
                    getCollectorsChart(GetCollectoresforStatusAndOsFamily(responseEventsReportResume.data.listCollectores));
                    setColectorStatus(getCollectoresForStatus(responseEventsReportResume.data.listCollectores));
                    setEventsBlocked(responseEventsReportResume.data.devices)
                    setEventsBlockedCollectors(responseEventsReportResume.data.mostTargetsCollectores)
                    setProcessEvents(responseEventsReportResume.data.process)
                    setSystemOperation(GetCollectoresforStatusAndOsFamily(responseEventsReportResume.data.listCollectores) as OperacialSystemEvent[])

                }).catch(error => {
                    console.error(error);

                }).finally(() => {
                    setLoadingEventsBlock(false)
                    setLoadingCollectoresChart(false)
                    setLoadingMostTarget(false)
                })
                endpointDetectionResponseService
                .RetrieveEndpointSecurityMetrics()
                .then(response => {
                    if ((response && response.data)) {
                        setIotData(response.data.iot);
                        setServersData(response.data.servers);
                        setWorkstationsData(response.data.workstations);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setIotData({});
                    setServersData({});
                    setWorkstationsData({});

                })
                getExcetions()
            let collectors = {
                labels: [],
                datasets: [{
                    data: [],
                    label: "Collectors",
                    backgroundColor: ["#dc291e", "#81bc00", "#ffa400", "#d7af64", "#ddbb7b", "#b3b3b3"],
                    fill: false
                }],
            };
            setLoading(true);

            endpointDetectionResponseService
                .GetCards(month, year)
                .then(response => {

                    if (response.data !== null) {
                        setSloChart(response.data.slaConclusao == 'NaN' ? 100 : ((response.data.slaConclusao.toFixed(1) / 100) * 100));
                        setSlaChart(response.data.slaAtendimento == 'NaN' ? 100 : ((response.data.slaAtendimento.toFixed(1) / 100) * 100));

                        let maxChamadosChart = response.data.totalChamadosHC >= response.data.totalChamadosFHC ?
                            (response.data.totalChamadosHC + 10)
                            : (response.data.totalChamadosFHC + 10);

                        let chamadosChart: DoughnutChartValue = {
                            title: 'Chamados por horário',
                            labels: [],
                            values: []
                        }
                        let newChamadosChart: any = [];

                        if (response.data.totalChamadosHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosHC);
                            chamadosChart.labels.push('Comercial');
                            let comercial = {
                                value: response.data.totalChamadosHC,
                                name: "Comercial",
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newChamadosChart.push(comercial)
                        }

                        if (response.data.totalChamadosFHC > 0) {
                            chamadosChart.values.push(response.data.totalChamadosFHC);
                            chamadosChart.labels.push('Não comercial');
                            let notComercial = {
                                value: response.data.totalChamadosFHC,
                                name: "Não comercial",
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newChamadosChart.push(notComercial)
                        }

                        setChamadosChart(newChamadosChart);
                        setIsLoadingChamadosChart(false)

                        let totalChamadosChart: DoughnutChartValue = {
                            title: 'Chamados por situação',
                            labels: [],
                            values: []
                        }
                        let newTotalChamadosChart: any = []

                        if (response.data.totalChamadosAbertas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosAbertas);
                            totalChamadosChart.labels.push('Abertos');
                            let open = {
                                value: response.data.totalChamadosAbertas,
                                name: 'Abertos',
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newTotalChamadosChart.push(open);
                        }

                        if (response.data.totalChamadosFechadas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosFechadas);
                            totalChamadosChart.labels.push('Fechados');
                            let closed = {
                                value: response.data.totalChamadosFechadas,
                                name: 'Fechados',
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newTotalChamadosChart.push(closed);
                        }

                        if (response.data.totalChamadosCanceladas > 0) {
                            totalChamadosChart.values.push(response.data.totalChamadosCanceladas);
                            totalChamadosChart.labels.push('Cancelados');
                            let cancel = {
                                value: response.data.totalChamadosCanceladas,
                                name: 'Cancelados',
                                itemStyle: {
                                    color: '#804e3b'
                                }
                            }
                            newTotalChamadosChart.push(cancel);
                        }

                        setTotalChamadosChart(newTotalChamadosChart);
                        setIsLoadingTotalChamadosChart(false)

                        let clientName: string = user.ClientGroupSelectedDescription?.toString().toLowerCase() as string;
                        let _c = clientName.split(' ')
                        let capitalized = '';
                        _c.map((e: any) => {
                            capitalized = capitalized + e[0].toUpperCase() + e.substr(1) + ' '
                        })

                        let chamadosPorOrigemChart: DoughnutChartValue = {
                            title: 'Chamados por origem',
                            labels: [],
                            values: []
                        }

                        let newChamadosPorOrigem: any = []

                        if (response.data.totalChamadosAbertosPeloCliente > 0) {
                            chamadosPorOrigemChart.labels.push(capitalized)
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPeloCliente)
                            let openByClients = {
                                value: response.data.totalChamadosAbertosPeloCliente,
                                name: capitalized,
                                itemStyle: {
                                    color: '#5969cd'
                                }
                            }
                            newChamadosPorOrigem.push(openByClients)
                        }

                        if (response.data.totalChamadosAbertosPelaProcessor > 0) {
                            chamadosPorOrigemChart.labels.push('Processor')
                            chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPelaProcessor)
                            let openByProc = {
                                value: response.data.totalChamadosAbertosPeloCliente,
                                name: 'Processor',
                                itemStyle: {
                                    color: '#e273c5'
                                }
                            }
                            newChamadosPorOrigem.push(openByProc)
                        }
                        setChamadosPorOrigemChart(newChamadosPorOrigem)
                        setIsLoadingChamadosPorOrigemChart(false)
                    }
                })
                .catch(error => {
                    console.error(error);
                    setIsLoadingTotalChamadosChart(false)
                    setIsLoadingChamadosPorOrigemChart(false)
                    setIsLoadingChamadosChart(false)
                })
                .finally(() => {
                    setLoading(false);
                    setIsLoadingTotalChamadosChart(false)
                    setIsLoadingChamadosPorOrigemChart(false)
                    setIsLoadingChamadosChart(false)
                });
        }
    }, [user.ClientGroupSelected, searchStartDate, user.refreshFlag]);

    const getClassificationChart = (resumeEventReport: devicesEvent[]) => {
        var ress = groupBy(resumeEventReport, "classification");

        let ressColor = [...new Set(resumeEventReport.sort(function (a, b) {
            if (a.classificationRank < b.classificationRank) return 1;
            if (a.classificationRank > b.classificationRank) return -1;
            return 0;
        }).map(g => g.color))];;
        let eventsData = Object.keys(ress).map((t, index) => {
            return {
                name: t,
                value: resumeEventReport.sort(function (a, b) {
                    if (a.classificationRank < b.classificationRank) return 1;
                    if (a.classificationRank > b.classificationRank) return -1;
                    return 0;
                }).filter(b => b.classification == t)
                    && resumeEventReport.filter(b => b.classification == t).length > 0
                    ? resumeEventReport.filter(b => b.classification == t)
                        .map(vn => vn.count).reduce((previousValue, currentValue) => previousValue + currentValue) : 0,
                itemStyle: {
                    color: ressColor[index]

                }
            }
        })
        setEventsChart(eventsData);
    }

    const getMostTargetChart = (resumeEventReport: devicesEvent[]) => {
        if(resumeEventReport && resumeEventReport.length > 0) {
            let devices = resumeEventReport.filter(dev => dev.classificationRank !== 5 && dev.classificationRank !== 4)
            var color = ['#dc291e', '#d3281d', '#ffa400', '#ddbb7b'];
            let somatorioMal = 0;
            let somatorioSUS = 0;
            let somatorioPPi = 0;
            let somatorioINC = 0;
            let somatorioMaliciosa = devices.reduce((total, current) => current.classification == "Maliciosa" ? total + current.count : total, somatorioMal)
            let somatorioSuspeito = devices.reduce((total, current) => current.classification == "Suspeita" ? total + current.count : total, somatorioSUS)
            let somatorioInconclusivo = devices.reduce((total, current) => current.classification == "Inconclusiva" ? total + current.count : total, somatorioINC)
            let somatorioPPI = devices.reduce((total, current) => current.classification == "PUP" ? total + current.count : total, somatorioPPi)
            let newMostTarget = {
                labels: ["Maliciosa", 'Suspeita', 'Inconclusiva', 'PUP'],
                data: [somatorioMaliciosa, somatorioSuspeito, somatorioInconclusivo, somatorioPPI],
                colors: color
    
            }
            return newMostTarget;
        } else {
            return {labels:[], colors:[], data:[]};
        }
    }

    const statusColor = (label: any) => {
        switch (label) {
            case 'Online':
                return '#81bc00';
            case 'Executando':
                return '#81bc00';
            case 'Desabilitado':
                return '#b3b3b3';
            case 'Desconectado':
                return '#dc291e';
            case 'Degradado':
                return '#ffa400';
            default:
                return '#777777';
        }
    }
    const status = (label: any) => {
        switch (label) {
            case 'Running':
                return 'Online';
            case 'Disconnected':
                return 'Desconectado';
            case 'Disabled':
                return 'Desabilitado';
            case 'Degraded':
                return 'Degradado';
            case 'RebootPending':
                return 'Pendente reiniciar';
            case 'Isolated':
                return 'Isolado';
            default:
                return 'Executando';
        }
    }

    const getCollectorsChart = (resumeEventReport: any[]) => {

        // let colors = resumeEventReport.collectors.map(t => statusColor(t.state)),
        //     collectors = {
        //         labels: resumeEventReport.collectors.map(t => t.state),
        //         data: resumeEventReport.collectors.map(t => t.count),
        //         colors: colors
        //     }

        let labels = resumeEventReport.sort((a, b) => {
            if (a.state < b.state) return -1;
            if (a.state > b.state) return 1;
            return 0;
        }).map(labels => labels.osFamily);
        let uniqueLables = [...new Set(labels)]
        let labelsDataset = resumeEventReport.sort((a, b) => {
            if (a.state < b.state) return -1;
            if (a.state > b.state) return 1;
            return 0;
        }).map(labels => labels.state);
        let uniqueLablesDataset = [...new Set(labelsDataset)]

        let collectorsOperationSystem = {
            labels: uniqueLables,
            datasets: uniqueLablesDataset.map((label) => {
                return {
                    // systemLabel: resumeEventReport.coletoresOperationalSystems,
                    type: 'bar',
                    stack: 'Ad',
                    name: label,
                    emphasis: {
                        focus: 'series'
                    },
                    data:
                        resumeEventReport.sort((a, b) => {
                            const osFamilyOrder = {
                                "Windows": 1,
                                "Windows Server": 2,
                                "Linux Server": 3
                            };

                            return osFamilyOrder[a.osFamily] - osFamilyOrder[b.osFamily];
                        }).filter(data => {
                            return data.state == label
                        }).map(i => i.count ? i.count : 0),
                    itemStyle: {
                        color: statusColor(label)
                    }
                }

            }),
        }
        // setColectorStatus(collectors);
        setColectorSystem(collectorsOperationSystem);
    }

    const getCollectoresForStatus = (arrayDeObjetos: CollectoresModel[]) => {
        let stateCount = {};
      if(arrayDeObjetos && arrayDeObjetos.length > 0) {
          arrayDeObjetos.forEach(obj => {
            let state = obj.state;
        
            if (stateCount.hasOwnProperty(state)) {
              stateCount[state]++;
            } else {
              // Se não, inicializar a contagem para 1
              stateCount[state] = 1;
            }
          });
      // Mapear os estados para o formato desejado
      let resultado = Object.keys(stateCount).map(state => ({
          name:  status(state),
          value: stateCount[state],
          itemStyle: {
            color: statusColor(status(state))
          }
        }));
        return resultado

      } else {
        return []
      }
      //? Caso precise voltar pro barras, só trocar o return
        // let labels: string[] = Object.keys((stateCount));
        // let data: number[] = Object.values(stateCount);
        // let translateLabls: string[] =  labels.map(label => status(label))
        // // Retornar o objeto no formato desejado
        // console.log(resultado)
        // return {
        //     labels:translateLabls,
        //     data: data,
        //     colors: translateLabls.map(label => statusColor(label)),
        // };
      }

    const  GetCollectoresforStatusAndOsFamily = (arrayDeObjetos: CollectoresModel[]) => {
        let statusCount = {};
        if(arrayDeObjetos &&arrayDeObjetos.length > 0) {
            arrayDeObjetos.forEach(obj => {
                let osFamily = obj.osFamily;
                let state = obj.state;
            
                let chave = `${osFamily}_${state}`;
                let estadoTraduzido = status(state);
    
                if (statusCount.hasOwnProperty(chave)) {
                    statusCount[chave].count++;
                } 
                else {
                    statusCount[chave] = {
                        count: 1,
                        osFamily: osFamily,
                        state: estadoTraduzido
                    };
                }
            });
        
            let resultado = Object.values(statusCount);
            return resultado;
        } else {
            return []
        }
    }

    const getExcetions = () => {
        setLoadingExceptions(true)
        endpointDetectionResponseService &&
        endpointDetectionResponseService.GetExceptions()
            .then(response => {
                setException(response.data);
                setTotalExcections(response.data.length);
            })
            .catch(error => {
                console.warn(error);
            })
            .then(() => {
                setLoadingExceptions(false);
            })
    }

    function getIconColor(inUse: number, sip: number): string {
        if (inUse === 0 && sip === 0) {
            return "#2CA02C"; // Verde
        } else if (inUse > sip) {
            return "#D3281D"; // Vermelho
        } else if (inUse >= 0.65 * sip) {
            return "#FFA400"; // Laranja
        } else {
            return "#2CA02C"; // Verde
        }
    }

    const cards = [
        //*IoT
        {
            id: 1,
            bgColor:(props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            type: 'Custom', 
            cssClass: (props.isHome)? 'fix-card-content' : undefined,
            position: (props.isHome || props.isSecOpsPage)?  { ColSpan: 3, RowSpan: 1, row: 1, col: 7 } : { ColSpan: 2, RowSpan: 1, row: 1, col: 5 },
            showFocusButton: false,
            title: !(props.isHome || props.isSecOpsPage)? () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">IoT</div>
                </div>
            ) : undefined,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={!iotData} >
                        {iotData && iotData.inUse >= 0 && iotData.sip >= 0 &&
                            <>
                                <IconValue
                                    icon={<PiPackageLight size={props.isHome? 24 : 32} /> }
                                    value={iotData ? `${iotData.inUse} de ${iotData.sip}` : `${0} de ${0}`}
                                    color={iotData ? getIconColor(iotData.inUse, iotData.sip) : "#2E1956"}
                                    description={(props.isHome || props.isSecOpsPage)? "IoT" : 'Utilizado/Disponível'}
                                    isHome={props.isHome}
                                ></IconValue>

                            </>
                        }

                        {
                            !(iotData && iotData.inUse >= 0 && iotData.sip >= 0) &&
                            <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
        //*Servers
        {
            id: 2,
            type: 'Custom',
            position: (props.isHome || props.isSecOpsPage)? { ColSpan: 3, RowSpan: 1, row: 1, col: 1 } : { ColSpan: 2, RowSpan: 1, row: 1, col: 1 },
            showFocusButton: false,
            bgColor: (props.isHome || props.isSecOpsPage)? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            cssClass: (props.isHome)? 'fix-card-content' : undefined,
            title: !(props.isHome || props.isSecOpsPage)? () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Servers</div>
                </div>
            ) : undefined,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={!serversData} >
                        {serversData && serversData.inUse >= 0 && serversData.sip >= 0 && (
                            <>
                                <IconValue
                                    icon={<PiDatabaseLight size={props.isHome? 24 : 32}/>}
                                    value={serversData ? `${serversData.inUse} de ${serversData.sip}` : `${0} de ${0}`}
                                    /*color="#2E1956"*/
                                    color={serversData ? getIconColor(serversData.inUse, serversData.sip) : "#2E1956"}
                                    description={(props.isHome || props.isSecOpsPage)? "Servers" : 'Utilizado/Disponível'} isHome={props.isHome}
                                />
                            </>
                        )}

                        {
                            !(serversData && serversData.inUse >= 0 && serversData.sip >= 0) &&
                            <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
        //*Worksstation
        {
            id: 3,
            type: 'Custom', 
            position: (props.isHome || props.isSecOpsPage)? { ColSpan: 3, RowSpan: 1, row: 1, col: 4 }  : { ColSpan: 2, RowSpan: 1, row: 1, col: 3 },
            showFocusButton: false,
            cssClass: (props.isHome)? 'fix-card-content' : undefined,
            bgColor: (props.isHome || props.isSecOpsPage)? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            title: !(props.isHome || props.isSecOpsPage)? () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Workstation</div>
                </div>
            ) : undefined,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={!workstationsData} >
                        {workstationsData && workstationsData.inUse >= 0 && workstationsData.sip >= 0 &&
                            <>
                                <IconValue
                                    icon={<PiMonitorLight size={props.isHome? 24 : 32} />}
                                    value={workstationsData ? `${workstationsData.inUse} de ${workstationsData.sip}` : `${0} de ${0}`}
                                    color={workstationsData ? getIconColor(workstationsData.inUse, workstationsData.sip) : "#2E1956"}
                                    description={(props.isHome || props.isSecOpsPage)? "Workstation" : 'Utilizado/Disponível'} isHome={props.isHome}
                                ></IconValue>
                            </>
                        }
                        {
                            !(workstationsData && workstationsData.inUse >= 0 && workstationsData.sip >= 0) &&
                            <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
        //*Niveis de serviço
        {
            id: 4,
            type: 'Custom', 
            position: { ColSpan: 4, RowSpan: 1, row: 1, col: 9 },
            showFocusButton: true,
            title: () => {
                return (
                    <div className="lc-segment-title-row-resume">
                        <div className="lc-title ellipsis">Níveis de serviço</div>
                        <PPDropDown
                            options={slaOptions}
                            onChange={(e) => e.value == '' ? setSlaView('sla') : setSlaView(e.value)}
                            defaultValue={slaOptions.find(option => option.value == slaView)}
                            value={slaOptions.find(option => option.value == slaView)}
                        />
                    </div>
                )
            },
            customContentRender: (focused: boolean) => {
                const screenWidth = window.innerWidth;
                let maxheight: string = screenWidth <= 1366 ? '120px' : screenWidth <= 1600 ? '150px' : '180px'
                if (focused) {
                    setPeriodSelected(periodFocusSelection);
                    const style = document.createElement("style");
                    style.innerHTML = `.ppdropdown {display:none}`;
                    document.head.appendChild(style);
                    return (
                        <div className="inner-grid" >
                            <div className="card-frame focused" style={{ gridArea: "1 / 1 / 3 / 7" }}>
                                <div className="lc-title ellipsis"></div>
                                <div className="card-content-body">
                                    <LcLoading loading={loading} >
                                        <GaugeChartCore className='focused' style={{ maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '200px' }} value={slaChart} title="SLA de atendimento"></GaugeChartCore>
                                    </LcLoading >
                                </div>
                            </div>

                            <div className="card-frame focused" style={{ gridArea: "1 / 7 / 3 / 13" }}>
                                <div className="lc-title ellipsis"></div>
                                <div className="card-content-body">
                                    <LcLoading loading={loading} >
                                        <GaugeChartCore className='focused' style={{ maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '200px' }} value={sloChart} title="SLO de conclusão"></GaugeChartCore>
                                    </LcLoading >
                                </div>
                            </div>

                            <div className="card-frame focused" style={{ gridArea: "3 / 1 / 5 / 5" }}>
                                <div className="lc-title ellipsis">Chamados por horário</div>
                                <div className="card-content-body">
                                    <LcLoading loading={isLoadingChamadosChart}>
                                        {
                                            chamadosChart ?
                                            
                                            // <ReactECharts style={{ maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%' }} 
                                            //     opts={{ renderer: 'svg', locale: 'PT-br' }}
                                            //     option={finOpsChartService.getDougnutChartWithLegend(chamadosChart)}
                                            // />
                                            <EventsGraph data={chamadosChart}/>
                                            : 
                                            <LcNoData/>
                                        }
                                    </LcLoading>
                                </div>
                            </div>
                            <div className="card-frame focused" style={{ gridArea: "3 / 5 / 5 / 9" }}>
                                <div className="lc-title ellipsis">Chamados por origem</div>
                                <div className="card-content-body">
                                    <LcLoading loading={isLoadingChamadosChart}>
                                        {
                                            chamadosPorOrigemChart ?
                                            // <ReactECharts style={{ maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%' }} 
                                            //     opts={{ renderer: 'svg', locale: 'PT-br' }}
                                            //     option={finOpsChartService.getDougnutChartWithLegend(chamadosPorOrigemChart)}
                                            // />
                                            <EventsGraph data={chamadosPorOrigemChart}/>
                                            :
                                            <LcNoData/>
                                        }
                                    </LcLoading>
                                </div>
                            </div>
                            <div className="card-frame focused" style={{ gridArea: "3 / 9 / 5 / 13" }}>
                                <div className="lc-title ellipsis">Chamados por situação</div>
                                <div className="card-content-body">
                                    <LcLoading loading={isLoadingTotalChamadosChart}>
                                        {
                                            totalChamadosChart ?
                                            // <ReactECharts
                                            //     option={finOpsChartService.getDougnutChartWithLegend(totalChamadosChart)}
                                            // />
                                            <EventsGraph data={totalChamadosChart}/>
                                            :
                                            <LcNoData/>
                                        }
                                        {/*<DoughnutChart data={totalChamadosChart} ></DoughnutChart> */}
                                    </LcLoading>
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    if( periodSelected.periods.length === 1) setPeriodSelected(periodSelection)
                    // setPeriodSelected(periodSelection)
                    const style = document.createElement("style");
                    style.innerHTML = `.ppdropdown {display:block}`;
                    document.head.appendChild(style);
                    return (
                        <LcLoading loading={loading} >
                            {
                                slaView == 'sla' ?
                                    <GaugeChartCore style={{ maxWidth: '100%', minWidth: '50%', minHeight: '100%', maxHeight: '90px' }} value={slaChart} title="SLA de atendimento"/>
                                :
                                    <GaugeChartCore style={{ maxWidth: '100%', minWidth: '50%', minHeight: '100%', maxHeight: '90px' }} value={sloChart} title="SLO de conclusão"/>
                            }
                        </LcLoading >
                    )
                }
            }
        },
        //*Eventos
        {
            id: 5,
            type: 'Custom',
            position: (props.isHome || props.isSecOpsPage)?{ ColSpan: 4, RowSpan: 4, row: 2, col: 1 }:  { ColSpan: 4, RowSpan: 2, row: 3, col: 1 },
            showFocusButton: false,
            hideHeader: (props.isHome || props.isSecOpsPage),
            ticketButtonLabel: "Ver eventos",            
            ticketButton: !(props.isHome || props.isSecOpsPage)? () => { return <PiArrowSquareOutLight onClick={() => { history.push("/EndpointDetectionResponse/Events") }} /> } : undefined,
            title: !(props.isHome || props.isSecOpsPage)? () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Classificação de eventos</div>
                </div>
            ) : undefined,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingEventsBlock}>
                        {eventsChart && (
                        <>
                            <div
                            className="eventsBlock"
                            style={{
                                position: "relative",
                                height: "100%",
                                width: "100%",
                            }}
                            >
                            <EventsGraph
                                onRef={setChartRefEvents}
                                data={eventsChart}
                                legendOrientation={
                                props.isHome || props.isSecOpsPage
                                    ? "horizontal"
                                    : "vertical"
                                }
                                title={
                                props.isHome || props.isSecOpsPage
                                    ? "Eventos no momento"
                                    : undefined
                                }
                            />
                            {(props.isHome || props.isSecOpsPage) && (
                                <span className="legend-title-home">Eventos</span>
                            )}
                            </div>
                        </>
                    )}
                  </LcLoading>
                );
            }
        },
        //*Principais alvos
        {
            id: 6,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 3, row: 2, col: 5 },
            showFocusButton: false,
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Principais alvos</div>
                    <PPDropDown
                            options={mostTargetsOpt}
                            onChange={(e) =>  setMostTargetSelected(e.value)}
                            defaultValue={mostTargetsOpt.find(option => option.value == mostTargetSelected)}
                            value={mostTargetsOpt.find(option => option.value == mostTargetSelected)}
                            width={'10vw'}
                        />
                </div>
            ),
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingMostTarget} >
                        {
                            resumeEventReport  ?
                            <MostTargetGraph graphSelected={mostTargetSelected} resumeEventReport={resumeEventReport} mostTargetData={mostTargetSelected === 'user' ?  mostTargetedChart : mostTargetedCollectorsChart} />
                            :
                            <LcNoData />
                        }
                    </LcLoading>
                )
            }
        },
        //* Dispositivos (Coletores)
        {
            id: 7,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9 },
            showFocusButton: false,
            ticketButtonLabel: "Ver coletores",
            ticketButton: !(props.isHome || props.isSecOpsPage)? () => { return <PiArrowSquareOutLight onClick={() => { history.push("/EndpointDetectionResponse/Collectors") }} /> } : undefined,
            title: () => {
                return (
                    <div className="lc-segment-title-row-resume">
                        <div className="lc-title ellipsis">Dispositivos (Coletores) </div>
                        <PPDropDown
                            options={collectorsOptions}
                            onChange={(e) => {
                                // if (chartDispositivosGeral.current) {
                                //     chartDispositivosGeral.current.getEchartsInstance().clear();
                                // }
                                e.value === '' ? setCollectorsView('geral') : setCollectorsView(e.value);
                            }}

                            defaultValue={collectorsOptions.find(option => option.value == collectorsView)}
                            value={collectorsOptions.find(option => option.value == collectorsView)}
                            width={(props.isHome || props.isSecOpsPage)? '130px' : undefined}
                        />
                    </div>
                )
            },
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingCollectoresChart} >
                            {
                                collectorsView !== 'geral' ?
                                    <ReactECharts style={{ minHeight: '100%', minWidth: '100%' }}
                                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                                        ref={chartDispositivosGeral}
                                        option={finOpsChartService.getBarChartCollectorsStacked(colectorSystem, props.isHome)}
                                    />
                                    :
                                    <EventsGraph onRef={setChartRefDivices} data={colectorStatus} legendOrientation={'vertical'} />
                        }
                        {/* // <CollectoresGraph colectoresView={collectorsView} colectorStatus={colectorStatus} colectorSystem={colectorSystem}/> */}
                    </LcLoading>
                )
            }
        },

    ]

    const cardNotHome = [
        //* Relatórios
        {
            id: 8,
            type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 1, col: 7 },
            showFocusButton: false,
            title: () => "Relatórios",
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingEventsBlock} >
                        <div className="row" style={{justifyContent: 'space-around'}}>
                            <LcIconLink
                                icon={<PiFilePdfLight size={32} />}
                                tooltip='Gerar sumário executivo'
                                onClick={exportToPdf}
                                tooltipPosition='right'
                                color='var(--icon-default)'
                                size='big'
                                />
                            <LcIconLink
                                icon={<PiFileCsvLight size={32}  />}
                                tooltip='Gerar CSV coletores'
                                onClick={getCollectorsCSV}
                                tooltipPosition='left'
                                color='var(--icon-default)'
                                size='big'
                                />
                        </div>
                    </LcLoading>
                )
            } 
        },
        //* Eventos bloqueados
        {
            id: 9,
            type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 2, col: 1 },
            showFocusButton: false,
            ticketButtonLabel: "Ver eventos",
            ticketButton: () => { return <PiArrowSquareOutLight onClick={() => { history.push("/EndpointDetectionResponse/Events") }} /> },
            title: () => "Eventos",
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingEventsBlock}  >
                        <IconValue
                            icon={<PiShieldWarningLight size={32} />}
                            value={totalEventsBlock}
                            color='var(--status-red)'
                        ></IconValue>
                    </LcLoading>
                )
            } 
        },
        {
            //*Exceções
            id: 10,
            type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 2, col: 3 },
            showFocusButton: false,
            ticketButtonLabel: "Ver exceções",
            ticketButton: () => { return <PiArrowSquareOutLight onClick={() => { history.push("/EndpointDetectionResponse/Exceptions") }} /> },
            title: () => "Exceções",
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loadingExceptions}>
                        <IconValue
                            icon={<PiShieldCheckLight  size={32} />}
                            value={totalExcections}
                            color='var(--status-green)'
                        ></IconValue>
                    </LcLoading>
                )
            }
        },
    ]

    const periodFocusSelection ={ 
        periods: [
            {
                label: 'Este mês', text: 'Este mês',
                fields: [
                    { name: 'currentMonth', value: 'currentMonth' }
                ],
                default: true
            }
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    }

    const processPeriodData = (days: number, devicesKey: string, collectorsKey: string) => {
        let initialValue = 0
        const eventsBlockPerDays = filterArrayPerDate(resumeEventReport.devices, days, devicesKey);
        const eventsBlockCollectorsPerDays = filterArrayPerDate(resumeEventReport.mostTargetsCollectores, days, devicesKey);
        const processPerDays = filterArrayPerDate(resumeEventReport.process, days, devicesKey);
        // const collectores = filterArrayPerDate(resumeEventReport.listCollectores, days, devicesKey);
        setProcessEvents(processPerDays as processEvent[])
        setEventsBlocked(eventsBlockPerDays as devicesEvent[])
        setEventsBlockedCollectors(eventsBlockCollectorsPerDays as devicesEvent[])
        const eventsBlockPerDaysCollectors = filterArrayPerDate(resumeEventReport.mostTargetsCollectores, days, collectorsKey);
        const collectorsPerDays = filterArrayPerDate(resumeEventReport.listCollectores, days, 'collectors');
        setTotalExcections(filterArrayPerDate(exception, days, 'exception').length);
        setTotalEventsBlock(eventsBlockPerDays.reduce((accumulator, currentValue) => accumulator + (currentValue as devicesEvent).count, initialValue));
        setMostTargetedChart(getMostTargetChart(eventsBlockPerDays as devicesEvent[]));
        setMostTargetedCollectorsChart(getMostTargetChart(eventsBlockPerDaysCollectors as devicesEvent[]));
        getClassificationChart(eventsBlockPerDays as devicesEvent[]);
        setColectorStatus(getCollectoresForStatus(collectorsPerDays as CollectoresModel[]));
        getCollectorsChart(GetCollectoresforStatusAndOsFamily(collectorsPerDays as CollectoresModel[]));
        setSystemOperation(GetCollectoresforStatusAndOsFamily(collectorsPerDays as CollectoresModel[]) as OperacialSystemEvent[]);

    };
    useEffect(() => {

        let initialValue = 0

        if (exception && resumeEventReport) {
            if (periodFilter === 'last7Days') {
                processPeriodData(7, 'devices', 'devices');
            } else if (periodFilter === 'last30Days') {
                processPeriodData(30, 'devices', 'devices');
            } else if (periodFilter === 'last90Days') {
                processPeriodData(90, 'devices', 'devices');
            } else {
                setTotalExcections(exception.length)
                setTotalEventsBlock(resumeEventReport.devices.reduce((accumulator, currentValue) => accumulator + currentValue.count,
                initialValue,))
                setMostTargetedChart(getMostTargetChart(resumeEventReport.devices));
                setMostTargetedCollectorsChart(getMostTargetChart(resumeEventReport.mostTargetsCollectores));
                setColectorStatus(getCollectoresForStatus(resumeEventReport.listCollectores as CollectoresModel[]));
                getClassificationChart(resumeEventReport.devices as devicesEvent[]);
                getCollectorsChart(GetCollectoresforStatusAndOsFamily(resumeEventReport.listCollectores));
                setProcessEvents(resumeEventReport.process)
                setEventsBlocked(resumeEventReport.devices)
                setEventsBlockedCollectors(resumeEventReport.mostTargetsCollectores)
                setSystemOperation(GetCollectoresforStatusAndOsFamily(resumeEventReport.listCollectores)  as OperacialSystemEvent[])
            }
        }
    },[periodFilter, exception, resumeEventReport])
    
    const getCollectorsCSV = () => {
        enqueueSnackbar(`Gerando arquivo`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        })

        if (user.ClientGroupSelected) {
            endpointDetectionResponseService &&
            endpointDetectionResponseService.GetCollectorsCSV('', '', '')
            .then(response => {
                const csvRows = response.data.split("|EndLine|");

                const csvHeader = csvRows.shift();

                const statusMapping = {
                    "Running": "Online",
                    "Degraded": "Degradado",
                    "Disabled": "Desabilitado",
                    "Disconnected": "Desconectado",
                    "Pending Reboot": "Reinicialização pendente",
                    "Isolated": "Isolado",
                    "Expired": "Expirado",
                    "Migrated": "Migrado",
                    "Pending Migration": "Migração pendente"
                }

                const csvData = csvRows.map((csvRow) => {
                    const fields = csvRow.split("||");
                    return {
                        "Grupo": fields[0],
                        "Nome do dispositivo": fields[1],
                        "SO": fields[2],
                        "Último login": fields[3],
                        "Status": statusMapping[fields[4]],
                        "Atualizado há": fields[5],
                    }
                })

                const data = csvData;
                const fileName = 'Coletores';
                const exportType = 'csv';
                const withBOM = true;
                const fields = csvHeader.split("||");
                exportFromJSON({ data, fields, fileName, exportType, withBOM, delimiter: ';' });

                enqueueSnackbar(`Arquivo gerado com sucesso`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
            })
        }
    }
    const exportToPdf = () => {
        let periodDate = periodFilter === 'last7Days' ? 
        ` ${moment().add(-7, 'days').format('DD/MM/YYYY')}` : 
        periodFilter === 'last30Days' ? 
        `de ${moment().add(-30, 'days').format('DD/MM/YYYY')}` : 
        periodFilter === 'last90Days' ? 
        `de ${moment().add(-90, 'days').format('DD/MM/YYYY')}` : 
        'inicial';
        let topEvents : any[] = getTopEvents(processEvents)
        let topEventsUsers = calculateTopDevices(  mostTargetSelected === 'user' ? eventBlocked : eventBlockedCollectors)
        const iframe = document.createElement('iframe');
        let client = user.ClientGroupSelectedDescription as string;
        document.body.appendChild(iframe);
        const janela: any = iframe.contentWindow;
        janela.document.open();
        janela.document.write(`
        <!DOCTYPE html>
        <html>
            <head>
                <title>Sumário Executivo</title>
                <style>
                    @media print {
                        @page {
                            margin: 0;
                        }
                        *{
                            font-family: Helvetica;        
                        }
                        body {
                            margin: 0mm;
                            size: A4 portrait; 
                        }
                        header {
                            left: 0;
                            position: fixed;
                            top: 0;
                            text-align: left; 
                            margin-bottom: 100px
                            color:#fff
                        }
                        footer {
                            margin-top: 50px;
                            left 0;
                            margin: 0;
                            position: fixed;
                            bottom: 0;
                            width: 100%;
                            height: 60px;
                            color: #fff; 
                            text-align: left; 
                            display:flex;
                            align-item: center;
                        }
                        #componente-pdf {
                            margin-left: 10px;
                            margin-top: 120px;
                            margin-bottom: 200px;
                            height: auto;
                        }
                        h2 {
                            margin-left:10px;
                        }
                        h3{
                            margin-left:15px;
                        }
                        .colored {
                            background-color: #d3d3d3;
                        }
                    }
                </style>
            </head>
            <body>
                <header>
                    <title>Sumário executivo LiveCloud EDR</title>
                    <img src=${imgHorizantalBar} style="position: absolute; width: 100%; height: 100px; z-index: -1;"/>
                    <div style="display:flex; justify-content: space-between; width: 100%; position:relative; z-index:1; ">
                        <div>
                            <h1 style="margin-top:20px; margin-left: 20px;  color:#fff; fontSize:18px; fontWeight:bold";>Sumário executivo LiveCloud EDR</h1>
                            <p style="margin-top:-20px; margin-left: 20px; color:#fff; fontSize:14px; fontWeight:600;">Dados coletados do cliente 
                                ${client.charAt(0).toUpperCase() + client.slice(1)} no período ${periodDate} à ${moment().format('DD/MM/YYYY')}</p>
                        </div>
                        <img style="margin-top:30px; margin-right:20px" src=${ImgLog} width="10%" height="15%"></img>
                    </div>
                </header>
                <div id="componente-pdf" style="width: 100%; height:auto "></div>
                <footer style="margin-top:5px; color:#fff; fontSize:14px; fontWeight:600; display: flex; justify-content: space-between;">
                    <img src=${imgHorizantalBar} style="position: absolute; width: 1000px; height: 60px;  z-index: -1;"/>
                    <p style="margin-top:20px; margin-left: 20px; color:#fff; fontSize:14px; fontWeight:600; display: flex; z-index:1">
                        Relatório gerado em ${moment().format("DD/MM/YYYY")} às ${moment().format("HH:mm")} na plataforma&nbsp;
                        <a target="_blank" style="color:#fff; text-decoration:none; font-weight:bold" href="https://livecloud.processoronline.com.br/EndpointDetectionResponse/Resume">LiveCloud</a>
                        </p>
                    <img style="margin-top:20px; margin-right:20px" src=${ImgLog} width="10%" height="30%" ></img>
                </footer>
            </body>
        </html>`
        );
        janela.document.close()
        const root = createRoot(janela.document.getElementById('componente-pdf'))
            root.render(<
                    ReportResumePDF
                        eventImg={chartRefEvents}
                        dispositivosImg={chartRefDivices} 
                        totalEventsBlock={totalEventsBlock}
                        resumeReport={systemOperation} 
                        topEvents={topEvents}
                        topEventsUsers={topEventsUsers}
                        workstation={workstationsData}
                        IOT={iotData}
                        servers={serversData}
                        period={periodFilter}
                        mostTargetSelected={mostTargetSelected}
                    />)
        setTimeout(() => {
            janela.print();
        }, 500);
    };

    function getTopEvents(actions: processEvent[]) {
        const maxProcessByClassification: any = {};
    
        actions.forEach((action) => {
            const key = action.classification;
            if (!maxProcessByClassification[key] || maxProcessByClassification[key].count < action.count) {
                maxProcessByClassification[key] = { process: action.process, count: action.count, classification: action.classification };
            }
        });
    
        const maxProcessActions = Object.values(maxProcessByClassification);
    
        return maxProcessActions;
    }

    
    const filterArrayPerDate = (array: DataEntry[] , period: number, tag: string) => {
        let today = new Date();
        let daysAgoDate = new Date();
        daysAgoDate.setDate(today.getDate() - period);
        daysAgoDate.setHours(0, 0, 0, 0); // Define horas, minutos, segundos e milissegundos para 0
        // Filtra as datas dos últimos 7 dias
        let datasNosUltimosDias = array.filter(data => {
            if (tag === 'exception') {
                return 'createdAt' in data && (data as ExceptionModal).createdAt && new Date((data as ExceptionModal).createdAt) >= daysAgoDate && new Date((data as ExceptionModal).createdAt) <= today;
            } else if(tag === 'devices') {
                return 'lastSeen' in data && (data as devicesEvent).lastSeen && new Date((data as devicesEvent).lastSeen) >= daysAgoDate && new Date((data as devicesEvent).lastSeen) <= today;
            } else {
                return 'lastSeenTime' in data && (data as CollectoresModel).lastSeenTime && new Date((data as CollectoresModel).lastSeenTime) >= daysAgoDate && new Date((data as CollectoresModel).lastSeenTime) <= today;
            }
        });
        return datasNosUltimosDias
    }
    
    function calculateTopDevices(data: DeviceData[]): { classification: string, topDevices: { device: string, totalCount: number }[] }[] {
        const classifiedDevices: { [classification: string]: { [device: string]: number } } = {};
        data.forEach(entry => { 
            if (entry.device.trim() !== "") { // Verifica se o dispositivo não está vazio
                if (!classifiedDevices[entry.classification]) {
                    classifiedDevices[entry.classification] = {};
                }
                if (!classifiedDevices[entry.classification][entry.device]) {
                    classifiedDevices[entry.classification][entry.device] = 0;
                }
                classifiedDevices[entry.classification][entry.device] += entry.count;
            }
        });
    
        const topDevicesByClassification: { classification: string, topDevices: { device: string, totalCount: number }[] }[] = [];
        for (const classification in classifiedDevices) {
            const devicesArray = Object.entries(classifiedDevices[classification]).map(([device, totalCount]) => ({ device, totalCount }));
            const sortedDevices = devicesArray.sort((a, b) => b.totalCount - a.totalCount).slice(0, 5);
            topDevicesByClassification.push({ classification, topDevices: sortedDevices });
        } 
        return topDevicesByClassification;
    }
    return (<>
        {!(props.isHome || props.isSecOpsPage) ? 
        <Layout
            pageTitle="Resumo"
            periodSelection={periodSelected}
            functionsGeneric={[{
                icon: <PiFilePdfLight />,
                tooltip: 'Gerar sumário executivo',
                onClick: () => exportToPdf(),
                clickble: (loadingEventsBlock),
                disabled:  loadingEventsBlock,
            }]}
            >
            <LCDashboard cards={[...cards, ...cardNotHome]} rightSidePanel={{
                title: '',
                content: () => '',
                pinned: false,
                show: false
            }} />
        </Layout>
        :
            <LCDashboard cssClass={(props.secOpsQtdGroups === 1 ? true : false ) ? '' : 'EdrHome'} 
            isHomeType grid={props.isHome ? [2,2] : [6, 2]} cards={props.secOpsQtdGroups && props.secOpsQtdGroups > 0 ? cards.map((card, index) => {
                if (props.secOpsQtdGroups) {
                    var posi = positionHome(card.id, props.secOpsQtdGroups);
                    if (posi !== undefined) {
                        card.position = posi;
                    }
                }
                return card;
            }) : []} rightSidePanel={{
                title: '',
                content: () => '',
                pinned: false,
                show: false
            }} />
        }
    </>
    );
}

export default EndpointDetectionResponseResume;


import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import { PiAppleLogoLight,  PiArrowsCounterClockwiseLight, PiArrowSquareOutLight, PiDevicesLight, PiFirstAid, PiFirstAidKitLight, PiInfoLight, PiLinuxLogoLight, PiPackageLight, PiWarningLight, PiWindowsLogoLight } from 'react-icons/pi';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { useHistory } from 'react-router-dom';
import InstalledVsMissingGraph from './InstalledVsMissingGraph';
import MissingPatchesGraph from './MissingPatchesGraph';
import SystemsByPlatformGraph from './SystemsByPlatformGraph';
import PPDropDown from '../../../components/PPDropDown';
import { PatchManagerService } from '../../../services/patchmanager/patchManagerService';
import { GraphModel, Patch, PatchDetail, PatchStatus, SystemByPlataformList, SystemDetail } from '../../../services/patchmanager/patchManagerModel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import moment from 'moment';
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { LiaBanSolid } from 'react-icons/lia';
import { PPModal } from 'processor-plataform-ui';
import { getStatusRow, translateStatus } from '../SystemListPatchManager';
import './index.css';


interface Props {
    isHome: boolean;
    qtdGroups: number;
    secOpsQtdGroups: number;
    isSecOpsPage: boolean
}

const ResumePathManager: React.FC<Props> = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const patchManagerService = new PatchManagerService(props);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const SystemPlataformOpt = [{ label: 'Suportados', value: 1 }, { label: 'Obsoletos', value: 2 }]
    const [systemPlataformSelected, setSystemPlataformSelected] = useState(1);
    const [patchSummariesResume, setPatchSummariesResume] = useState<GraphModel[]>([])
    const [patchSummariesPMResume, setPatchSummariesPMResume] = useState<any>({ name: 'instalados', value: 0 })
    const [loadingPMPatchesNotDistincts, setLoadingPMPatchesNotDistincts] = useState<boolean>(true)
    const [systemByPlatformSupported, setSystemByPlatformSupported] = useState<GraphModel[]>()
    const [systemByPlatformObsolete, setSystemByPlatformObsolete] = useState<GraphModel[]>()
    const [patchSummariesMissingSeverity, setPatchSummariesMissingSeverity] = useState<GraphModel[]>()
    const [patchSummariesInstalledVsMissing, setPatchSummariesInstalledVsMissing] = useState<GraphModel[]>()

    const [systemRebootRequired, setSystemRebootRequired] = useState<SystemDetail[]>([])
    const [systemCriticalPatches, setSystemCriticalPatches] = useState<SystemDetail[]>([])
    const [systemFailurePatches, setSystemFailurePatches] = useState<SystemDetail[]>([])
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loadingModal, setLoadingModal] = useState<boolean>(false)
    const [focus, setFocus] = useState<boolean>(false)
    const [rowsPatcheSelected, setRowsPatcheSelected] = useState<PatchDetail[]>([]);

    const columnsSystem = [
        // {
        //     field: "", headerName: "", width: "10px", align: 'right',
        //     renderCell: (row: SystemByPlataformList) => <div style={{ width: '3px', height: '25px', borderRadius: '10px', backgroundColor: row.agentStatus === 'on' ? '#dc291e' : '#3ebb3f' }}></div>
        // },
        {
            field: "hostName", headerName: "HostName", width: "15%", align: 'left', fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
                        <LcIconLink className='notHover' clickble={false} icon={
                            row.operationalSystem.toLocaleLowerCase().includes('windows') ? <PiWindowsLogoLight size={'1rem'} />
                                :
                                row.operationalSystem.toLocaleLowerCase().includes('mac') ? <PiAppleLogoLight size={'1rem'} />
                                    :
                                    <PiLinuxLogoLight size={'1rem'} />
                        } />
                        <span className='font-4x'>{row.hostName}</span>
                    </div>
                )
            }
        },
        {
            field: "agentStatus", headerName: "Ligado", width: "12%", align: 'center', fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return row.agentStatus === 'off' ?
                    <div style={{ width: '10px', height: '10px', borderRadius: '100%', backgroundColor: '#dc291e' }}></div>
                    :
                    <div style={{ width: '10px', height: '10px', borderRadius: '100%', backgroundColor: '#3ebb3f' }}></div>
            }
        },
        {
            field: "lastCommunication", headerName: "Última comunicação", width: "15%", align: 'center', fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return moment(row.lastCommunication).format('DD/MM/YYYY hh:mm')
            }
        },
        {
            field: "rebootRequired", headerName: "Reboot necessário", width: "15%", align: 'center', overflow: "visible", fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return row.rebootRequired === 1 &&
                    <LcIconLink size='small'
                        icon={<PiInfoLight color='#ffb530' size={18} />}
                        tooltip="Necessário reiniciar a máquina"
                        tooltipPosition='right'

                    />
            }
        },
        {
            field: "operational_System", headerName: "Sistema operacional", width: "27%", align: 'center', overflow: 'visible', fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return row.legacySystem ?
                    <div className='operationalSystem'>
                        <p>{row.operationalSystem}</p>
                        <LcIconLink size='small'
                            icon={<PiInfoLight color='#ffb530' fontSize={'1rem'} />}
                            tooltip="Sistema legado"
                            tooltipPosition='left'
                            clickble={false}
                        />
                    </div>
                    :
                    <div className='operationalSystem'>{row.operationalSystem}</div>
            }
        }
    ];

    const positions: { group: number, cards: Card[] }[] = [
        {
            group: 2, cards: [
                {
                    id: 1, position: { ColSpan: 1, RowSpan: 1, row: 3, col: 2, hidden: true },
                },
                {
                    id: 2, position: { ColSpan: 1, RowSpan: 1, row: 4, col: 2, hidden: true },
                },
                {
                    id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: true },
                },
                {
                    id: 4, position: { ColSpan: 4, RowSpan: 1, row: 4, col: 1, hidden: props.secOpsQtdGroups > 1 ? true : false },
                },
                {
                    id: 5, position: { ColSpan: 4, RowSpan: 1, row: 5, col: 1, hidden: props.secOpsQtdGroups > 1 ? true : false },
                },
                {
                    id: 6, position: { ColSpan: 4, RowSpan: 1, row: 6, col: 1, hidden: props.secOpsQtdGroups > 1 ? true : false },
                },
                {
                    id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 8, position: { ColSpan: 3, RowSpan: 1, row: 1, col: 1, hidden: false },
                },
                {
                    id: 9, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 10, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 11, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 12, position: { ColSpan: 1, RowSpan: 1, row: 2, col: 2, hidden: true },
                },
            ]
        },
        {
            group: 6, cards: [
                {
                    id: 1, position: { ColSpan: 3, RowSpan: 1, row: 4, col: 1, hidden: true },
                },
                {
                    id: 2, position: { ColSpan: 3, RowSpan: 1, row: 5, col: 1, hidden: true },
                },
                {
                    id: 3, position: { ColSpan: 4, RowSpan: 1, row: 6, col: 1, hidden: true },
                },
                {
                    id: 4, position: { ColSpan: 4, RowSpan: 1, row: 2, col: 1, hidden: !props.isSecOpsPage },
                },
                {
                    id: 5, position: { ColSpan: 4, RowSpan: 1, row: 3, col: 1, hidden: !props.isSecOpsPage },
                },
                {
                    id: 6, position: { ColSpan: 4, RowSpan: 1, row: 4, col: 1, hidden: !props.isSecOpsPage },
                },
                {
                    id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 8, position: { ColSpan: 3, RowSpan: 1, row: 1, col: 1, hidden: false },
                },
                {
                    id: 9, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 10, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 11, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 12, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: true },
                },
            ]
        }
    ];

    function positionHome(cardId: number | undefined, qtdGroups: number) {
        if (cardId === undefined) return undefined;
        const groupData = positions.find(group => group.group >= qtdGroups);
        if (groupData) {
            const card = groupData.cards.find(c => c.id === cardId);
            if (card) {
                return card.position;
            }
        }
        return undefined;
    }
    function capitalizeFirstLetter(str) {
        if (str.length === 0) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    const detailsPatches = [
        {
            field: "resource_name", headerName: "Nome do Computador", width: "15%", align: 'left',
            renderCell: (row: PatchStatus) => capitalizeFirstLetter(row.resource_name)
        },
        { field: "domain_netbios_name", headerName: "Domínio", width: "20%", align: 'left' },
        { field: "os_name", headerName: "Sistema operacional", width: "28%", align: 'left' },
        { field: "patch_id", headerName: "Patch Id", width: "10%", align: 'left' },
        {
            field: "resource_health_status", headerName: "Status da instalação", width: "15%", align: 'center', overflow: 'visible',
            renderCell: (row: PatchStatus) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }} >
                        <LcIconLink tooltip={translateStatusPatch(row.affected_patch_status.toString())}
                            icon={row.affected_patch_status === 202 ? <LiaBanSolid color='#dc291e' /> :
                                row.affected_patch_status === 201 ? <AiOutlineCheckCircle color='#3ebb3f' /> :
                                    <AiOutlineExclamationCircle color='#ffa400' />} />
                        {/* <span className='ml-5'>{translateStatusPatch(row.affected_patch_status.toString())}</span> */}
                    </div>
                )
            }
        },
        {
            field: "severity", headerName: "Severidade", width: "10%", align: 'center',
            renderCell: (row: PatchStatus) => {
                return <div className='severityLabelDetails ' style={{
                    padding: '0px 8px',
                    backgroundColor: translateSeverityColor(row.severity)
                }}>{translateSeverityLabel(row.severity)} </div>
            }
        }
    ]

    const loadDetailsData = async (row) => {
        setOpenModal(true);
        setLoadingModal(true);
        var detailslist = await patchManagerService.GetAllPatchDetails(row.patchId);
        detailslist && setRowsPatcheSelected(detailslist.filter(x => x.affected_patch_status === 202));
        setLoadingModal(true);
    }

    const columnsPatches = [
        { field: "patchId", headerName: "Patch Id", width: "8%", align: 'left', },
        {
            field: "severity", headerName: "Severidade", width: "15%", align: 'center',
            renderCell: (row: Patch) => {
                return <div className='severityLabelDetails' style={{
                    padding: '0px 8px',
                    backgroundColor: translateSeverityColor(row.severity)
                }}>
                    {translateSeverityLabel(row.severity)}
                </div>
            }
        },
        // { field: "kbNumber", headerName: "Número KB", width: "10%", align: 'left' },
        {
            field: "patchDescription", headerName: "Descrição do patch", width: "35%", align: 'left',
            renderCell: (row: any) => <div className='overflowEllipsis'>{row.patchDescription && row.patchDescription}</div>
        },
        // {
        //     field: "approveStatus", headerName: "Status de aprovação", width: "12%", align: 'center', overflw: 'visisble',
        //     renderCell: (row: Patch) => {
        //         return <div style={{ display: 'flex', alignItems: 'center'}} >
        //             <LcIconLink className='notHover' tooltip={translateApprovalStatus(row.approveStatus)}  
        //             clickble={false}  icon={row.approveStatus === 'Reproved' ? <LiaBanSolid color='#dc291e' /> : <AiOutlineCheckCircle color='#3ebb3f' />} />
        //             {/* <span className={'ml-5'}>{translateApprovalStatus(row.approveStatus)}</span> */}
        //         </div>
        //     }
        // },
        {
            field: "platform", headerName: "Plataforma", width: "10%", align: 'left',
            renderCell: (row: any) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
                        {row.platform && <LcIconLink className='notHover' clickble={false} icon={
                            row.platform.includes('Windows') ? <PiWindowsLogoLight className='notHover' size={'1rem'} />
                                :
                                row.platform.includes('mac') ? <PiAppleLogoLight size={'1rem'} />
                                    :
                                    <PiLinuxLogoLight size={'1rem'} />
                        } />}
                        <span>{row.platform}</span>
                    </div>
                )
            }
        },
        {
            field: "rebootRequired", headerName: "Reboot", width: "10%", align: 'center', overflow: "visible",
            renderCell: (row: any) => {
                return !((row.rebootRequired) > 0) ?
                    <LcIconLink size='small' clickble={false}
                        icon={<PiInfoLight color='#ffb530' size={18} />}
                        tooltip="Necessário reiniciar a máquina"
                        tooltipPosition='right'
                    /> : <div>-</div>
            }
        },
        {
            field: "missingSystem", headerName: "Sistemas", width: "15%", align: 'center', overflow: 'visible',
            renderCell: (row: Patch) => <div onClick={() => {
                loadDetailsData(row);
            }} style={{ color: 'var(--text-link)', fontWeight: 600, cursor: 'pointer' }}>
                <LcIconLink
                    size='small'                    
                    icon={<PiDevicesLight size={18} />}
                    tooltip="Ver detalhes"
                    tooltipPosition='right'
                />
            </div>
        },
    ];

    const GetPatchinSystemsSummariesInstalledVsMissing = async () => {
        setLoadingPMPatchesNotDistincts(true);
        try {
            var result = await patchManagerService.GetAllPatchesInstalledSystems();
            setPatchSummariesPMResume(result);
        } catch (e) {
            console.error(e)
        } finally {
            setLoadingPMPatchesNotDistincts(false);
        }

    }

    const GetPatchSummariesResume = async () => {
        try {
            const patchesResponse = await patchManagerService.GetPatchSummariesResume();
            if (patchesResponse && patchesResponse.getPatchSummariesResume) {
             
            } else {
                setLoading(false)
            }
            setPatchSummariesResume(patchesResponse.getPatchSummariesResume)

            if (patchesResponse && patchesResponse.getSystemByPlatformObsolete)
                setSystemByPlatformObsolete(patchesResponse.getSystemByPlatformObsolete)

            //if (patchesResponse && patchesResponse.getSystemByPlatform)
            //    setSystemByPlatform(patchesResponse.getSystemByPlatform)

            if (patchesResponse && patchesResponse.getSystemByPlatformSupported)
                setSystemByPlatformSupported(patchesResponse.getSystemByPlatformSupported)

            if (patchesResponse && patchesResponse.getPatchSummariesMissingSeverity)
                setPatchSummariesMissingSeverity(patchesResponse.getPatchSummariesMissingSeverity)

            if (patchesResponse && patchesResponse.getPatchSummariesInstalledVsMissing)
                setPatchSummariesInstalledVsMissing(patchesResponse.getPatchSummariesInstalledVsMissing)

        } catch (error) {
            console.error('Error:', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true);
        Promise.all([
            GetPatchSummariesResume(), GetPatchinSystemsSummariesInstalledVsMissing()
        ]).finally(() => setLoading(false));
    }, [user.ClientGroupSelected, user.refreshFlag,]);

    const translateSeverityColor = (status: number) => {
        const tranlateStatusColor = {
            4: '#ff4400', // 'Important'
            3: '#ffa400', // 'Important'
            2: "#f6de00",// 'Moderate' 
            1: "#3ebb3f",//'Low'
            0: "#cccccf",//'Unrated'
        };
        return tranlateStatusColor[status];
    }
    const translateSeverityLabel = (status: number) => {
        const tranlateStatusColor = {
            4: 'Crítico'
            , 3: 'Importante'
            , 2: 'Moderado'
            , 1: 'Baixo'
            , 0: 'Não Classificado'
        };
        return tranlateStatusColor[status];
    }

    const translateStatusLabel = (status: string) => {
        const tranleteStatusLabel = {
            'Important': 'Importante',
            'High': 'Alta',
            'Medium': 'Media',
            'Critical': "Crítico",
            'Moderate': "Moderado",
            'Low': "Baixo",
            'Unclassified': "Não Classificado",
        };
        return tranleteStatusLabel[status];
    }

    const translateStatusPatch = (status: string) => {
        const translateStatus = {
            '202': 'Ausente',
            '201': "Instalado",
            '206': "Falha"
        };
        return translateStatus[status];
    }
    const translateApprovalStatus = (status: string) => {
        const tranleteStatus = {
            'Approved': 'Aprovado',
            'Reproved': "Reprovado",

        };
        return tranleteStatus[status];
    }

    const cards: Card[] = [
        //Patchs críticos
        {
            id: 1,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            hideHeader: (props.isHome || props.isSecOpsPage),
            position: { ColSpan: 2, RowSpan: 1, row: 1, col: 1 },
            showFocusButton: true,
            title: () => <div className="ellipsis-title">Patches críticos</div>,

            customContentRender: (focused: boolean) => {
                if (focused) {
                    return (patchSummariesResume.length > 0 ? <LcInfiniteTable
                        rows={patchSummariesResume.filter(c => c.name === 'Criticos')[0].list as object[]}
                        columns={columnsPatches}
                        loadMore={() => undefined}
                        size={systemCriticalPatches.length}
                        hidePagination
                    /> : <></>
                    )
                } else {
                    return (
                        <LcLoading loading={loading}>
                            {
                                patchSummariesResume && patchSummariesResume.length > 0 ?
                                    <IconValue
                                        icon={<PiWarningLight size={32} />}
                                        value={patchSummariesResume.filter(c => c.name === 'Criticos')[0].value}
                                        color="#e0443a"
                                        description={(props.isHome || props.isSecOpsPage) ? 'Patches críticos ausentes' : 'Ausentes'}
                                        cssClass='font-xl'
                                    />
                                    :
                                    <LcNoData size="small" />
                            }
                        </LcLoading>

                    )
                }
            }
        },
        //com falhas
        {
            id: 2,
            type: 'Custom',
            position: { ColSpan: 2, RowSpan: 1, row: 1, col: 3 },
            showFocusButton: true,
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            hideHeader: (props.isHome || props.isSecOpsPage),
            title: () => <div className="ellipsis-title">{focus ? "Falhas em patches" : "Falharam"}</div>,
            customContentRender: (focused: boolean) => {
                setFocus(focused)
                if (focused) {

                    return (
                        patchSummariesResume && patchSummariesResume.length > 0 ? <LcInfiniteTable
                            rows={patchSummariesResume.filter(c => c.name === 'Falhas')[0].list as object[]}
                            columns={columnsPatches}
                            loadMore={() => undefined}
                            size={systemFailurePatches.length}
                            hidePagination
                            height='calc(100% - 10px)'
                        /> : <></>
                    )
                } else {
                    return (
                        <LcLoading loading={loading}>
                            {
                                patchSummariesResume && patchSummariesResume.length > 0 ?
                                    <IconValue
                                        icon={<PiWarningLight size={32} />}
                                        value={patchSummariesResume.filter(c => c.name === 'Falhas')[0].value}
                                        color="#ffb530"
                                        description={(props.isHome || props.isSecOpsPage) ? 'Patches que falharam' : 'Patches'}
                                        cssClass='font-xl'
                                    />
                                    :
                                    <LcNoData size="small" />
                            }
                        </LcLoading>
                    )
                }
            }
        },
        //Agentes
        {
            id: 3,
            type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 1, col: 5 },
            showFocusButton: true,
            hideHeader: (props.isHome || props.isSecOpsPage),
            title: () => <div className="ellipsis-title">{focus ? "Agentes Desconectados" : "Agentes"}</div>,

            customContentRender: (focused: boolean) => {

                let agentResume = patchSummariesResume && patchSummariesResume.find(patch => patch.name === 'Agentes');
                setFocus(focused)
                if (focused) {
                    return (
                        patchSummariesResume && patchSummariesResume.length > 0 ?
                            <LcInfiniteTable
                                rows={agentResume && agentResume.list?.filter((fill: any) => fill.agentStatus === 'off') || []}
                                columns={columnsSystem}
                                loadMore={() => undefined}
                                size={systemFailurePatches.length}
                                hidePagination
                                height='calc(100% - 15px)'
                                status={getStatusRow}
                                tooltipContentColumn='agentStatus'
                                customTooltipContentColumn={translateStatus}
                            /> : <LcNoData />
                    )
                } else {
                    return (
                        <LcLoading loading={loading}>
                            {
                                patchSummariesResume && patchSummariesResume.length > 0 ?
                                    <IconValue
                                        icon={<PiWarningLight size={32} />}
                                        value={agentResume && agentResume.list?.filter((fill: any) => fill.agentStatus === 'off').length}
                                        color="#e0443a"
                                        description='Desconectados'
                                        cssClass='font-xl'
                                    />
                                    :
                                    <LcNoData size="small" />
                            }
                        </LcLoading>

                    )
                }
            }
        },
        //Reboot Pendente
        {
            id: 4,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            hideHeader: (props.isHome || props.isSecOpsPage),
            position: (props.isHome || props.isSecOpsPage) ? { ColSpan: 3, RowSpan: 1, row: 1, col: 7 } : { ColSpan: 2, RowSpan: 1, row: 1, col: 7 },
            showFocusButton: true,
            cssClass: 'fix-card-content',
            title: () => <div className="ellipsis-title">Reboot pendente</div>,

            customContentRender: (focused: boolean) => {
                if (focused) {
                    return (
                        patchSummariesResume && patchSummariesResume.length > 0 ? <LcInfiniteTable
                            rows={patchSummariesResume.filter(c => c.name == 'RebootPendente')[0].list as object[]}
                            columns={columnsSystem}
                            loadMore={() => undefined}
                            size={systemRebootRequired.length}
                            hidePagination
                            status={getStatusRow}
                            tooltipContentColumn='agentStatus'
                            customTooltipContentColumn={translateStatus}
                        /> : <></>
                    )
                } else {
                    return (
                        <LcLoading loading={loading}>
                            {
                                patchSummariesResume && patchSummariesResume.length > 0 ?
                                    <IconValue
                                        value={patchSummariesResume.filter(c => c.name === 'RebootPendente')[0].value}
                                        icon={<PiArrowsCounterClockwiseLight size={props.isHome? 24 : 32} />}
                                        color="#ffb530"
                                        description={(props.isHome || props.isSecOpsPage) ? 'Sistemas com reboot pendente' : 'Sistemas'}
                                        cssClass={(props.isHome || props.isSecOpsPage) ? '' : 'font-xl'}
                                        isHome={props.isHome}
                                    />
                                    :
                                    <LcNoData size="small" />
                            }
                        </LcLoading>

                    )
                }
            }
        },
        //Sistemas
        {
            id: 5,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            hideHeader: (props.isHome || props.isSecOpsPage),
            position: (props.isHome || props.isSecOpsPage) ? { ColSpan: 4, RowSpan: 4, row: 2, col: 1 } : { ColSpan: 4, RowSpan: 1, row: 1, col: 9 },
            ticketButton: () => { return <LcIconLink tooltipPosition='right' tooltip='Ir para sistemas' icon={<PiArrowSquareOutLight />} onClick={() => { history.push('/patchmanager/systems') }} /> },
            showFocusButton: false,
            cssClass: 'fix-card-content',
            title: () => <div className="ellipsis-title">Sistemas</div>,

            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        {
                            patchSummariesResume && patchSummariesResume.length > 0 ?
                                <IconValue
                                    value={patchSummariesResume.filter(c => c.name === 'Systems')[0].value}
                                    color="#2E1956"
                                    icon={<PiDevicesLight size={props.isHome? 24 : 32} />}
                                    description={(props.isHome || props.isSecOpsPage) ? 'Sistemas gerenciados' : 'Gerenciados'}
                                    cssClass='font-xl'
                                    isHome={props.isHome}
                                />
                                :
                                <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
        //Systems HighVulnerability
        {
            id: 12,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            position: { ColSpan: 2, RowSpan: 1, row: 2, col: 1 },
            showFocusButton: true,
            cssClass: 'fix-card-content',
            hideHeader: (props.isHome || props.isSecOpsPage),
            infoContent: () => <div><span>Sistemas que possuem patches<br/> críticos ou importantes <br/> pendentes de instalação.</span></div>,
            title: () => <div title='Alta vulnerabilidade' className="ellipsis-title">{focus ? "Sistemas com alta vulnerabilidade" : "Alta vulnerabilidade"}</div>,
            customContentRender: (focused: boolean) => {
                let highVulnerabilityResume = patchSummariesResume && patchSummariesResume.find(patch => patch.name === 'HighVulnerability');
                setFocus(focused)
                if (focused) {
                    return (
                        patchSummariesResume && patchSummariesResume.length > 0 && highVulnerabilityResume ? <LcInfiniteTable
                            rows={highVulnerabilityResume && (highVulnerabilityResume.list?.filter((fill: any) => fill.healthStatus === '3') || [])}
                            columns={columnsSystem}
                            loadMore={() => undefined}
                            size={highVulnerabilityResume && highVulnerabilityResume.list?.filter((fill: any) => fill.healthStatus === '3').length}
                            hidePagination
                            height='calc(100% - 15px)'
                            status={getStatusRow}
                            tooltipContentColumn='agentStatus'
                            customTooltipContentColumn={translateStatus}
                        /> : <LcNoData />
                    )

                } else {
                    return (
                        <LcLoading loading={loading}>
                            {
                                patchSummariesResume && patchSummariesResume.length > 0 ?
                                    <IconValue
                                        icon={<PiFirstAidKitLight size={props.isHome? 24 : 32} />}
                                        value={patchSummariesResume.filter(c => c.name === 'HighVulnerability')[0].value}
                                        color="#e0443a"
                                        description={(props.isHome || props.isSecOpsPage) ? 'Sistemas com alta vulnerabilidade' : 'Sistemas'}
                                        cssClass='font-xl'
                                    />
                                    :
                                    <LcNoData size="small" />
                            }
                        </LcLoading>

                    )
                }
            }
        },
        //Patchs
        {
            id: 6,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            position: { ColSpan: 2, RowSpan: 1, row: 2, col: 3 },
            showFocusButton: false,
            hideHeader: (props.isHome || props.isSecOpsPage),
            ticketButton: () => { return <LcIconLink tooltipPosition='right' tooltip='Ir para patches' icon={<PiArrowSquareOutLight />} onClick={() => { history.push('/patchmanager/patches') }} /> },
            title: () => <div className="ellipsis-title">Patches</div>,

            customContentRender: () => {
                return (
                    <LcLoading loading={loadingPMPatchesNotDistincts}>
                        {
                            patchSummariesPMResume && patchSummariesPMResume.length > 0 ?
                                <IconValue
                                    icon={<PiPackageLight size={32} />}
                                    value={patchSummariesPMResume[0].value}
                                    color="#2E1956"
                                    description={(props.isHome || props.isSecOpsPage) ? 'Patches implantados' : 'Implantados'}
                                    cssClass='font-xl'
                                    isHome={props.isHome}
                                />
                                :
                                <LcNoData size="small" />
                        }
                    </LcLoading> 
                )
            }
        },
        //Patches instalados vc ausentes
        {
            id: 7,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 3, row: 3, col: 1 },
            showFocusButton: false,
            title: () => <div className="ellipsis-title">Patches instalados vs ausentes</div>,

            customContentRender: () => <InstalledVsMissingGraph patchSummariesInstalledVsMissing={patchSummariesInstalledVsMissing} />
        },
        //Patches ausentes
        {
            id: 8,
            type: 'Custom',
            hideHeader: (props.isHome || props.isSecOpsPage),
            position: (props.isHome || props.isSecOpsPage) ? { ColSpan: 1, RowSpan: 1, row: 1, col: 1 }
                : { ColSpan: 4, RowSpan: 4, row: 2, col: 5 },
            showFocusButton: false,
            title: !(props.isHome || props.isSecOpsPage) ? () => <div className="ellipsis-title">Patches ausentes</div>
                : undefined,
            customContentRender: () =>
                <LcLoading loading={loading}>
                    <MissingPatchesGraph
                        qtsGroups={props.secOpsQtdGroups}
                        isHome={props.isHome}
                        isSecOps={props.isSecOpsPage}
                        title={(props.isHome || props.isSecOpsPage) ? 'Patches ausentes' : ''}
                        patchSummariesMissingSeverity={patchSummariesMissingSeverity}
                    />
                </LcLoading>
        },
        //Sistemas por Plataforma
        {
            id: 9,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 4, row: 2, col: 9 },
            showFocusButton: false,
            title: () => {
                return <>
                    {
                        <div className={'lc-segment-title-row-resume'} style={{ alignItems: 'flex-end' }}>
                            <div className={'lc-title ellipsis'}>Sistemas por plataforma</div>
                            <PPDropDown
                                name='rankType'
                                options={SystemPlataformOpt}
                                defaultValue={SystemPlataformOpt.find(option => option.value === systemPlataformSelected)}
                                value={SystemPlataformOpt.find(option => option.value === systemPlataformSelected)}
                                onChange={(event) => setSystemPlataformSelected(event.value)}
                                width='128px'
                            />
                        </div>
                    }</>
            },
            customContentRender: () => <SystemsByPlatformGraph systemByPlatformSupported={systemByPlatformSupported} systemByPlatformObsolete={systemByPlatformObsolete} selected={systemPlataformSelected} />
        }
    ];

    const tranleteStatusColorRowDetails = (row: PatchDetail) => {
        const tranlateStatusColor = {
            'Important': '#ffa400',
            'Critical': "#dc291e",
            'High': "#dc291e",
            'Medium': "#f6de00",
            'Moderate': "#f6de00",
            'Low': "#3ebb3f",
            'Unclassified': "#cccccf",
        };
        return tranlateStatusColor[row.severity_name];
    }
    return (
        (props.isHome || props.isSecOpsPage) ?
            (
                <LCDashboard isHomeType cssClass={props.secOpsQtdGroups === 1 ? '' : 'patchManagerHome'}
                    grid={props.isHome ? [1, 1] : [4, 2]}
                    cards={props.secOpsQtdGroups && props.secOpsQtdGroups > 0 ? cards.map((card, index) => {
                        if (props.secOpsQtdGroups) {
                            var posi = positionHome(card.id, props.secOpsQtdGroups);
                            if (posi !== undefined) {
                                card.position = posi;
                            }
                        }
                        return card;
                    }) : []}
                />
            )
            :
            (
                <Layout pageTitle='Resumo'>
                    <LCDashboard cards={cards} />
                    <PPModal visible={openModal} onClose={() => setOpenModal(false)} size='large' title="Detalhes do Patch">
                        <LcLoading loading={loadingModal} label='Carregando Patches...' >
                            <LcInfiniteTable
                                rows={rowsPatcheSelected}
                                columns={detailsPatches}
                                loadMore={() => undefined}
                                size={rowsPatcheSelected.length}
                                hidePagination
                                status={tranleteStatusColorRowDetails}
                            />
                        </LcLoading>
                    </PPModal>
                </Layout>
            )
    );
}

export default ResumePathManager;
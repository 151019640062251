import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { PrefState } from '../../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';

import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable"
import moment from 'moment';

import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import { PPForm2, PPModal } from 'processor-plataform-ui'
import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import { HeyHoService } from '../../../services/heyHoService';
import { useSnackbar } from 'notistack';
import { HeyHoProps } from '../../Heyho/List';
import { UserState } from '../../../store/reducers/userReducer';
import LcLoading from '../../../components/Generic/LcLoading';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { truncate } from 'fs';
import HeyHoNewRequest, { FormDataHeyho } from '../../../components/Generic/HeyHoNewRequest';
import { PiHeadsetBold, PiHeadsetLight, PiListLight } from 'react-icons/pi';

interface Filter {
    device: string,
    process: string,
    classifications: string,
    severities: string,
    firstSeenFrom: string,
    firstSeenTo: string,
    sorting: string
}

interface Events {
    eventId: number,
    device: string,
    process: string,
    classification: string,
    classificationValue: string,
    classificationLabel: string,
    firstSeen: string,
    lastSeen: string,
    sorting: string
    severity: string,
    severityValue: string,
    severityLabel: string,
    processOwner: string
    processPath: string,
    destinations: string
}

const EndpointDetectionResponseEvents: React.FC<HeyHoProps> = (props) => {

    const endpointDetectionResponseService = new EndpointDetectionResponseService(props);
    const [ServiceInstance] = useState<HeyHoService>(new HeyHoService(props));
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        record: ServiceInstance.emptyRecord
    };

    const defaultFilter: Filter = {
        device: "",
        process: "",
        classifications: "",
        severities: "",
        firstSeenFrom: "",
        firstSeenTo: "",
        sorting: ""
    }

    const classifications = [
        { value: '', label: 'Todas' },
        { value: 'Malicious', label: 'Maliciosa' },
        { value: 'Suspicious', label: 'Suspeita' },
        { value: 'Inconclusive', label: 'Inconclusiva' },
        { value: 'Likely Safe', label: 'Provavelmente segura' },
        { value: 'PUP', label: 'PUP' },
        { value: 'Safe', label: 'Segura' }
    ];

    const severities = [
        { value: '', label: 'Todas' },
        { value: 'Critical', label: 'Crítica' },
        { value: 'High', label: 'Alta' },
        { value: 'Medium', label: 'Média' }
    ];

    const noDeviceLabel = "(Dispositivo não informado)";

    const status = (row: any) => {
        switch (row.classification) {
            case 'Safe':
                return '#81bc00';
            case 'Likely Safe':
                return '#969696';
            case 'PUP':
                return '#ddbb7b';
            case 'Suspicious':
                return '#d3281d';
            case 'Malicious':
                return '#dc291e';
            case 'Inconclusive':
                return '#ffa400';
            default:
                return '#b3b3b3';
        }
    }
    
    // const status = (row: any) => {
    //     switch (row.classification) {
    //         case 'Safe':
    //             return { color: '#81bc00', label: 'Segura' };
    //         case 'Likely Safe':
    //             return { color: '#969696', label: 'Provavelmente segura' };
    //         case 'PUP':
    //             return { color: '#ddbb7b', label: 'PUP' };
    //         case 'Suspicious':
    //             return { color: '#d3281d', label: 'Suspeita' };
    //         case 'Malicious':
    //             return { color: '#dc291e', label: 'Maliciosa' };
    //         case 'Inconclusive':
    //             return { color: '#ffa400', label: 'Inconclusiva' };
    //         default:
    //             return { color: '#b3b3b3', label: 'Desconhecido' };
    //     }
    // };
    
    const [loading, setLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<Events[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<Events[]>([]);
    const [devicesGroup, setDevicesGroup] = useState<any[]>(['']);
    const [selectedDevice, setSelectedDevice] = useState<any>();
    const [filter, setFilter] = useState<Filter>(defaultFilter);
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [eventsLength, setEventsLength] = useState<number>(0);

    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [loadHeyHoData, setLoadHeyHoData] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState(false);
    const [fieldsFormData, setFieldsFormData] = useState<PPFormInput[]>([]);
    const [titleRequest, setTitleRequest] = useState('');
    const [descriptionRequest, setDescriptionRequest] = useState('');
    const [contratRequest, setContractRequest] = useState('');
    const [ServiceTreeRequest, setServiceTreeRequest] = useState('');
    const [newRequestModalVisible, setNewRequestModalVisible] = useState<boolean>(false);
    const [anexos, setAnexos] = useState<File[]>();
    const [selectedDeviceRequest, setSelectedDeviceRequest] = useState<any>();
    const [detailsModalVisible, setDetailsModalVisible] = useState<boolean>(false);
    const [record, setRecord] = useState(emptyRecordMessage);
    const [formData, setFormData] = useState<FormDataHeyho>({
        title: '',
        description: '',
        gtmType: 0,
    });

    const loadData = () => {
        setLoading(true);
        endpointDetectionResponseService.GetAllEvents()
            .then(response => {
                if (response.data.length > 0) {
                    let _events = response.data
                        .map((item: Events) => {
                            const classification = classifications.find(c => c.value === item.classification);
                            const severity = severities.find(c => c.value === item.severity);
                            return {
                                ...item,
                                device: item.device !== "" ? item.device : noDeviceLabel,
                                classificationValue: classification?.value,
                                classificationLabel: classification?.label,
                                severityValue: severity?.value,
                                severityLabel: severity?.label
                            };
                        })
                        .sort((a: Events, b: Events) => a.eventId > b.eventId ? 1 : -1);

                    let _groupedEvents = groupByField(_events, 'device');
                    if (Object.keys(_groupedEvents).length == 0)
                        _groupedEvents = [];

                    let _devices = Object.keys(_groupedEvents)
                        .sort((a, b) => a < b || b == noDeviceLabel || a == noDeviceLabel ? -1 : 1);

                    setDevicesGroup(_devices);
                    setEvents([_groupedEvents]);
                    setFilteredEvents([_groupedEvents]);
                    setEventsLength(countUniqueEvents(_events));                    
                }
            })
            .catch(console.error)
            .then(() => {
                if (loadHeyHoData) {
                    ServiceInstance.RetrieveContractsAndGTM();
                    setLoadHeyHoData(false);
                }
            })
            .catch(console.error)
            .then(() => {
                setFieldsFormData(fieldsForm);
                setLoading(false);
            });
    }

    const countUniqueEvents = (loadedEvents: any): number => {
        let _allIds: any[] = [];
        if (Array.isArray(loadedEvents))
            _allIds = loadedEvents.map((le: Events) => le.eventId);
        else
            Object.keys(loadedEvents)
                .forEach(dev => loadedEvents[dev].forEach(le => _allIds.push(le.eventId)));

        let _uniqueIds = [...new Set(_allIds)];

        return _uniqueIds.length;
    }

    const groupByField = (array: any[], campo: string) => {
        const arrayCampo: object[] = [];
        return array.reduce((grupos, objeto) => {
            const valorCampo = objeto[campo];
            arrayCampo.push(objeto[campo])
            if (grupos[valorCampo]) {
                grupos[valorCampo].push(objeto);
            } else {
                grupos[valorCampo] = [objeto];
            }
            return grupos;
        }, {});
    }

    const applyFilter = useCallback(() => {
        if (events.length > 0) {
            // Converte o objeto de eventos em um array de eventos
            let allEvents: Events[] = [];
            Object.values(events[0]).forEach(eventGroup => {
                allEvents = allEvents.concat(eventGroup as Events[]);
            });

            let _filteredEvents: Events[] = allEvents;
            let isFilterApplied = false;

            // Aplica os filtros de 'device', 'process', 'classifications', 'severities', 'firstSeenFrom' e 'firstSeenTo'
            if (filter.device !== defaultFilter.device) {
                _filteredEvents = _filteredEvents.filter(event =>
                    event.device.toLowerCase().includes(filter.device.toLowerCase()) ||
                    event.eventId.toString().includes(filter.device)
                );
                isFilterApplied = true;
            }

            if (filter.process !== defaultFilter.process) {
                _filteredEvents = _filteredEvents.filter(event =>
                    event.process.toLowerCase().includes(filter.process.toLowerCase())
                );
                isFilterApplied = true;
            }

            if (filter.classifications !== defaultFilter.classifications) {
                _filteredEvents = _filteredEvents.filter(event =>
                    event.classification === filter.classifications
                );
                isFilterApplied = true;
            }

            if (filter.severities !== defaultFilter.severities) {
                _filteredEvents = _filteredEvents.filter(event =>
                    event.severity === filter.severities
                );
                isFilterApplied = true;
            }

            if (filter.firstSeenFrom) {
                _filteredEvents = _filteredEvents.filter(event =>
                    moment(event.firstSeen).isSameOrAfter(filter.firstSeenFrom)
                );
                isFilterApplied = true;
            }

            if (filter.firstSeenTo) {
                _filteredEvents = _filteredEvents.filter(event =>
                    moment(event.lastSeen).isSameOrAfter(filter.firstSeenTo)
                );
                isFilterApplied = true;
            }

            if (_filteredEvents.length === 0) {
                setDevicesGroup([]);
                setFilteredEvents([]);
                setEventsLength(0);
                // Mensagem de não há dados para serem exibidos
            } else {
                let _groupedEvents = groupByField(_filteredEvents, 'device');
                let _devices = Object.keys(_groupedEvents);

                setDevicesGroup(_devices);
                setFilteredEvents([_groupedEvents]); // Certifique-se de que está passando um array de grupos corretamente
                setEventsLength(countUniqueEvents(_groupedEvents));
            }

            dispatch(setFilterActive(isFilterApplied));
        }
    }, [filter, events, dispatch]);

    useEffect(loadData, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        // Aplica filtro após os dados serem carregados
        if (events.length > 0) {
            applyFilter();
        }
    }, [events]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter]);


    useEffect(() => {
        // Esta função é chamada quando o componente é desmontado
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const fieldsForm: PPFormInput[] = [
        {
            name: 'contrato',
            type: 'dropdown',
            title: 'Contrato',
            required: true,
            options: ServiceInstance.state.data.contratos,
            // value: contratRequest
        }, {
            name: 'titulo',
            type: 'text',
            title: 'Assunto',
            required: true,
            value: titleRequest
        }, {
            name: 'ServiceTree',
            type: 'dropdown',
            title: 'Árvore de serviço',
            required: true,
            options: record.record.contrato >= 0 ? ServiceInstance.state.data.chamadoTree.filter((y: any) => {
                let gtmids = (ServiceInstance.state.data.contratos as any[]).find((c: any) => c.value == record.record.contrato)?.gtmids || [];
                return gtmids.includes(y.gtmid);
            }) : ServiceInstance.state.data.chamadoTree,
            // value: ServiceTreeRequest
        },
        {
            name: 'Descricao',
            type: 'textarea',
            title: 'Descrição detalhada',
            required: true,
            rows: 15,
            value: descriptionRequest
        },
        {
            name: 'Anexo',
            type: 'file',
            title: 'Anexar arquivos: Coloque evidencias que ajudem a entender o contexto',
        }
    ]

    const handleNewRequest = (row) => {
        setLoadingForm(true);
        setSelectedDeviceRequest(row);
        setNewRequestModalVisible(true);
        let _record = record.record;

        _record.titulo = `Evento do tipo ${row.classification} detectado no dispositivo ${row.device}`;
        _record.Descricao = `Evento: ${row.classification} \nDestino: ${row.destinations} \nDispositivo: ${row.device} \nProcesso: ${row.process} \nPath: ${row.processPath}`;
        fieldsForm[1].value = `Evento do tipo ${row.classification} detectado no dispositivo ${row.device}`;
        fieldsForm[3].value = `Evento: ${row.classification} \nDestino: ${row.destinations} \nDispositivo: ${row.device} \nProcesso: ${row.process} \nPath: ${row.processPath}`;

        setFieldsFormData([...fieldsForm]);
        setRecord({ ...record, record: _record })
        setFormData({
            title: `Evento do tipo ${row.classification} detectado no dispositivo ${row.device}`,
            description: `Evento: ${row.classification} \nDestino: ${row.destinations} \nDispositivo: ${row.device} \nProcesso: ${row.process} \nPath: ${row.processPath}`
        })
       
        setLoadingForm(false);
    }

    const columns = [
        { field: 'eventId', headerName: 'Dispositivo / ID', width: "15%" },
        { field: 'device', headerName: 'Dispositivo', width: "17%", hide: devicesGroup.length != 0 },
        {
            field: 'process',
            headerName: 'Processo',
            width: "25%",
            truncate: true            
        },

        { field: 'classification', headerName: 'Classificação', width: "12%", renderCell: (row: any) => row.classificationLabel },
        { field: 'severities', headerName: 'Criticidade', width: "12%", renderCell: (row: any) => row.severityLabel },
        {
            field: 'firstSeen', headerName: 'Registrado em', width: "20%",
            renderCell: (row: any) => {
                return moment(row.firstSeen).format('DD/MM/YYYY HH:mm:ss')
            }
        },
        {
            field: 'lastSeen', headerName: 'Última ocorrência', width: "20%",
            renderCell: (row: any) => {
                return moment(row.lastSeen).format('DD/MM/YYYY HH:mm:ss')
            }
        },
        {
            field: 'newRequest', headerName: '', width: "38px", overflow: "visible",
            renderCell: (row: any) => {
                return <LcIconLink icon={ <PiHeadsetLight/> }size='small' tooltip={"Novo chamado"} tooltipPosition='inline-left' onClick={() => {
                    setLoadingForm(true);
                    ServiceInstance.state.data.chamadoTree &&
                        ServiceInstance.state.data.contratos &&
                        ServiceInstance.state.data.chamadoTree.length > 0 &&
                        ServiceInstance.state.data.contratos.length > 0 &&
                        handleNewRequest(row);
                }} />
            }
        },
        {
            field: 'id', headerName: '', width: "38px", overflow: "visible",
            renderCell: (row: any) => {
                return <LcIconLink icon={<PiListLight />} size='small' tooltip={"Detalhes"} tooltipPosition='inline-left' onClick={() => {
                    setSelectedDevice(row); setDetailsModalVisible(true);
                }} />
            }
        }
    ];

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;

        // Sort com os dados já carregados
        let _events = { ...events[0] };
        Object.keys(_events).forEach(device => {
            _events[device] = _events[device].sort((a: any, b: any) => {
                if (sort.direction == 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
                else return (a[sort.column] > b[sort.column]) ? -1 : 1;
            });
        });
        setEvents([_events]);

        // Sort pela request com recarga dos dados

        // let sorting = `%7B%22${sort.column}%22%3A${sort.direction === 'asc' ? 'false' : 'true'}%7D`;
        // setFilter({ ...cleanFilter, sorting });
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const _filterAdvanced = {
        fields: [
            { label: 'Dispositivo ou ID do evento', name: 'device', type: 'text', value: '' },
            { label: 'Processo', name: 'process', type: 'text', value: '' },
            { label: 'Classificação', name: 'classifications', type: 'select', options: classifications, value: '' },
            { label: 'Criticidade', name: 'severities', type: 'select', options: severities, value: '' },
            { label: 'Registrado a partir de', name: 'firstSeenFrom', type: 'date' },
            { label: 'Última ocorrência a partir de', name: 'firstSeenTo', type: 'date' }
        ],
        onChange: (f: any, s: number) => {
            setFilter({
                ...filter,
                device: f[0].value,
                process: f[1].value,
                classifications: f[2].value,
                severities: f[3].value,
                firstSeenFrom: f[4].value,
                firstSeenTo: f[5].value
            });
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    async function saveTicket(
        ServiceInstance: any,
        setRecord: React.Dispatch<React.SetStateAction<{ detail: boolean; edit: boolean; add: boolean; record: any; }>>,
        emptyRecordMessage: { detail: boolean; edit: boolean; add: boolean; record: any; },
    ) {
        setLoading(true);
        try {
            await ServiceInstance.Save(record.record)
                .then(async (response) => {
                    enqueueSnackbar(`Chamado ${response.data.id} criado com sucesso.`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });

                    if (anexos != undefined) {
                        anexos.map(async (anexo) => {
                            await ServiceInstance.sendFile(anexo, response.data.id, anexo.name)
                                .then((response2) => {
                                    if (response2.data == 201) {
                                        enqueueSnackbar(`Anexo para o ticket ${response.data.id} criado com sucesso.`, {
                                            variant: 'success',
                                            preventDuplicate: true,
                                            persist: false,
                                        });
                                    }
                                    if (response2.data == 400) {
                                        enqueueSnackbar(`Erro ao anexar arquivo.`, {
                                            variant: 'error',
                                            preventDuplicate: true,
                                            persist: false,
                                        });
                                    }
                                })
                                .catch((error) => {
                                    enqueueSnackbar(`Erro ao anexar arquivo.`, {
                                        variant: 'error',
                                        preventDuplicate: true,
                                        persist: false,
                                    });
                                    console.warn(error);
                                })
                        })

                    }
                })
                .catch((error) => {
                    enqueueSnackbar(`Erro ao criar chamado.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.warn(error);
                })
                .finally(() => {
                    setRecord({ ...emptyRecordMessage });
                    setAnexos(undefined);
                    setLoading(false);
                })

        } catch (e) {
            console.error(e);
            setLoading(false);
        }
        setAnexos(undefined);
        setLoading(false);
    }

    const handleSubmit = () => {
        setSubmitting(true);
        saveTicket(ServiceInstance, setRecord, emptyRecordMessage)
            .then(result => {
            })
            .catch(console.warn)
            .then(() => {
                setSubmitting(false);
                setNewRequestModalVisible(false);
            })
    }

    const onChangeForm = (event) => {

        let _record = record.record;
        // _record.contrato = event.contrato;
        _record.titulo = event.titulo;
        // _record.ServiceTree = event.ServiceTree;
        _record.Descricao = event.Descricao;
        _record.Anexo = event.Anexo;

        setFormData({
            title: event.titulo,
            description: event.Descricao
,        })
        // setContractRequest(event.contrato);
        // setServiceTreeRequest(event.ServiceTree)

        if (event.Anexo != undefined) {
            setAnexos(event.Anexo);
        }
        setRecord({ ...record, record: _record })
    }

    const onChagePeriod = (selected: any) => {

    };
    //! Filtro por mês já existe no filtro normal. caso sair do principal e venha pra cá só recolocar 

    const periodSelection = {
        periods: [
            {
                label: 'Este mês', text: 'Este mês',
                fields: [
                    { name: 'currentMonth', value: 'currentMonth' }
                ],
                default: true
            }
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };


    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} loadingType='Helix'>
                        <LcInfiniteTable
                            tooltipContentColumn={'classificationLabel'}
                            loading={loading}
                            columns={columns}
                            rows={filteredEvents}
                            groupName={devicesGroup}
                            size={eventsLength}
                            loadMore={() => { }}
                            notLoadMore={true}
                            onSortChange={onSortChange}
                            filter={_filterAdvanced}
                            disableFilterModal={true}
                            status={status}
                            density={pref.data?.density || "high"}
                            noDataMessage='Não há dados para serem exibidos no momento.'
                        />

                    </LcLoading>
                )
            }

        }
    ]

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={_filterAdvanced.fields}
                onFilterChange={handleFilterChange}
            />
        );
    };

    const handleFilterChange = (updatedFilters) => {
        // Atualize o estado do filtro e aplique-o
        const newFilter = { ...filter };
        updatedFilters.forEach(f => {
            newFilter[f.name] = f.value;
        });
        setFilter(newFilter);
    };

    return (
        <Layout
            periodSelection={periodSelection}
            pageTitle="Eventos"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: eventsLength
            }}
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                    //setShow: setFilterVisible
                }}
            />

            <PPModal title={"Detalhes " + selectedDevice?.eventId} onClose={() => { setDetailsModalVisible(false); setSelectedDevice(undefined); }} visible={detailsModalVisible} loading={!selectedDevice} >
                {
                    selectedDevice &&
                    <>
                        <p><b>Dispositivo:</b> {selectedDevice.device}</p>
                        <p><b>Classificação:</b> {selectedDevice.classificationLabel}</p>
                        <p><b>Usuário:</b> {selectedDevice.processOwner !== "" ? selectedDevice.processOwner : "(Sem informações)"}</p>
                        {/*  <p><b>Processo:</b> {selectedDevice.process}</p>*/}
                        <p><b>Caminho:</b> {selectedDevice.processPath}</p>
                        <p><b>Destinos:</b> {selectedDevice.destinations}</p>
                        <p><b>Criticidade:</b> {severities.find(s => s.value == selectedDevice.severity)?.label ?? "(Sem informações)"}</p>
                        {/*<p><b>Registrado a partir de:</b> {selectedDevice.firstSeenFrom}</p>*/}
                        {/*<p><b>Última ocorrência a partir de:</b> {selectedDevice.lastSeenFrom}</p>*/}
                    </>
                }
            </PPModal>

            {!loadingForm && ServiceInstance.state.data.chamadoTree && ServiceInstance.state.data.contratos &&
                ServiceInstance.state.data.chamadoTree.length > 0 && ServiceInstance.state.data.contratos.length > 0 && 
                <HeyHoNewRequest 
                    openModal={newRequestModalVisible} 
                    onClose={() => { setNewRequestModalVisible(false); setSelectedDeviceRequest(undefined)} } 
                    needAtachmente 
                    formData={formData}
                />
                // <PPModal
                //     visible={newRequestModalVisible}
                //     onClose={() => { setNewRequestModalVisible(false); setSelectedDeviceRequest(undefined) }}
                //     title={'Novo chamado'}
                //     loading={loading}
                // > <PPForm2
                //         fields={fieldsFormData}
                //         returnType="object"
                //         onChange={onChangeForm}
                //         onSubmit={handleSubmit}
                //         submitting={submitting}
                //         functions={
                //             {
                //                 otherButtos: [
                //                     {
                //                         label: 'Cancelar',
                //                         context: 'grey',
                //                         onClick: () => {
                //                             setRecord({ ...emptyRecordMessage });
                //                             setNewRequestModalVisible(false);
                //                         }
                //                     }
                //                 ]
                //             }
                //         }
                //     />
                // </PPModal>
            }
        </Layout>
    );
};

export default EndpointDetectionResponseEvents;
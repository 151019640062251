import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import DoughnutChart from '../../../components/Layout/Dashboard/Doughnut';
import { SaasService } from '../../../services/saas/saasService';
import './index.css';

//Redux
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import Moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import LCDashboard, { Card, PositionCard } from '../../../components/Data/Dashboard/LCDashboard';
import ManageOfficeStatusService from '../../../services/manageOfficeStatus/manageOfficeStatus';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import ReactECharts from 'echarts-for-react';
import { LCTechService } from '../../../services/LCTech/LCTechService';
import GaugeChartCore from '../../../components/Layout/Dashboard/gaugeChartCore';
import LcNoData from '../../../components/Generic/LcNoData';
import moment from 'moment';
import { PiArrowSquareOutLight, PiCheckCircleLight, PiWarningCircleLight } from 'react-icons/pi';
import history from '../../../history';
import PPDropDown from '../../../components/PPDropDown';
import IconValueCardCol from '../../../components/Layout/Dashboard/IconValueCardCol';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import { SaasUserService } from '../../../services/SaasUserService';
import PivotTableWebix from '../../../components/webix';

import { useHistory, useLocation } from 'react-router-dom';
import { SharePointSiteUsageDetail } from '../../../services/saas/SharePointSiteUsageStorage';
import { SecureScore } from '../../../services/LCTech/LCTechModel';
import { HealthCheckCard, IconColorResult, getIconAndColorSecure } from '../../../components/Layout/Cards/HealthCheckCard';

import { HealthCheckTable } from '../../../components/Layout/Cards/HealthCheckTable';
import SubscriptionComercial from '../../SaaS/Insights/SubscriptionComercial';
import { SubscriptionData } from '../../../services/saas/SaasModel';
import { ProgressBarWithLabel } from '../../../components/Layout/Dashboard/ProgressBarWithLabel';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}
export function sCdateToJsDate(cSDate: any): Date {
    //
    if (!cSDate) {
        return new Date();
    }
    // cSDate is '2017-01-24T14:14:55.807'
    var datestr = cSDate.toString();
    var dateAr = datestr.split('-');
    var year = parseInt(dateAr[0]);
    var month = parseInt(dateAr[1]) - 1;
    var day = parseInt(dateAr[2].substring(0, dateAr[2].indexOf("T")));
    var timestring = dateAr[2].substring(dateAr[2].indexOf("T") + 1);
    var timeAr = timestring.split(":");
    var hour = parseInt(timeAr[0]);
    var min = parseInt(timeAr[1]);
    var sek = parseInt(timeAr[2]);
    var date = new Date(year, month, day, hour, min, sek, 0);
    return date;
}
export function calculateMaxY(maxValue) {
    // Calcula o próximo milhar mais significativo baseado no valor máximo atual
    let base = 1000; // Base para arredondamento
    let nextSignificantMultiple = Math.ceil(maxValue / base) * base; // Arredonda para o próximo milhar

    // Se estiver muito próximo ao valor máximo, adiciona um extra para garantir margem
    if (nextSignificantMultiple - maxValue < base * 0.1) {
        nextSignificantMultiple += base;
    }

    return nextSignificantMultiple;
}

export function formatNumberLC(value) {
    if (value == null || value === 'NaN' || isNaN(Number(value))) return '0,00';

    const numValue = Number(value);
    // billions and above
    if (numValue >= 1.0e+9) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+9));
        return formattedAmount + "B";
    }
    // millions
    else if (numValue >= 1.0e+6) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+6));
        return formattedAmount + "M";
    }
    // thousands
    else if (numValue >= 1.0e+3) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+3));
        return formattedAmount + "k";
    }
    else {
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format(numValue);
        return formattedAmount;
    }
}
interface CSPResumeProps {
    isHome?: boolean,
    qtdGroups?: number
}
export function getIconAndColor(value: number, unity?: string, isHome?: boolean): IconColorResult {
    const iconSize = isHome ? 24 : 32;

    if (value === 0 && !unity) {
        return {
            color: "var(--status-green)", // Verde
            icon: <PiCheckCircleLight size={iconSize} />
        };
    } else if (value === 0 && unity === "%") {
        return {
            color: "var(--status-red)", // Vermelho
            icon: <PiWarningCircleLight size={iconSize} />
        };
    } else if (value >= 90 && value <= 100) {
        return {
            color: "var(--status-green)", // Verde
            icon: <PiCheckCircleLight size={iconSize} />
        };
    } else {
        return {
            color: "var(--status-red)", // Vermelho
            icon: <PiWarningCircleLight size={iconSize}  />
        };
    }
}


const CSPResume: React.FC<CSPResumeProps> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [topUsersView, setTopUsersView] = useState<string>('onedrive');
    const [sloChart, setSloChart] = useState<number>(0);
    const [slaChart, setSlaChart] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [chamadosChart, setChamadosChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingChamadosChart, setIsLoadingChamadosChart] = useState<boolean>(true);
    const [totalChamadosChart, setTotalChamadosChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingTotalChamadosChart, setIsLoadingTotalChamadosChart] = useState<boolean>(true);
    const [chamadosPorOrigemChart, setChamadosPorOrigemChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [isLoadingChamadosPorOrigemChart, setIsLoadingChamadosPorOrigemChart] = useState<boolean>(true);
    const [day, setDay] = useState<string>('01');
    const [month, setMonth] = useState<string>(Moment().format('M'));
    const [year, setYear] = useState<string>(Moment().format('YYYY'));
    const [searchStartDate, setSearchStartDate] = useState<string>(`${year}${month.padStart(2, '0')}${day}`);
    const saasService = new SaasService(props);
    const serviceManagedOfficeStatus = new ManageOfficeStatusService(props);
    const [resourceUsagePivotRecords, setResourceUsagePivotRecords] = useState<any[]>([]);
    const [resourceUsagePivotNoRecordMsg, setResourceUsagePivotNoRecordMsg] = useState<string>('');
    const [resourceUsagePivotRecordsLoading, setResourceUsagePivotRecordsLoading] = useState<boolean>(true);

    const [usersChart, setUsersChart] = useState<any>("Default");
    const [usersChartLoading, setUsersChartLoading] = useState<any>(true);
    const [emailActivityChart, setEmailActivityChart] = useState<any>("Default");
    const [emailActivityChartLoading, setEmailActivityChartLoading] = useState<any>(true);
    const [emailUsageChart, setEmailUsageChart] = useState<any>("Default");
    const [emailUsageChartLoading, setEmailUsageChartLoading] = useState<any>(true);
    const [oneDriveUsageChart, setOneDriveUsageChart] = useState<any>("Default");
    const [oneDriveUsageChartLoading, setOneDriveUsageChartLoading] = useState<any>(true);
    const statusEnum = [
        {
            statusNuvem: 'Investigating',
            statusLC: 'warning',
        },
        {
            statusNuvem: 'ServiceDegradation',
            statusLC: 'warning',
        },
        {
            statusNuvem: 'ServiceInterruption',
            statusLC: 'danger',
        },
        {
            statusNuvem: 'RestoringService',
            statusLC: 'warning',
        },
        {
            statusNuvem: 'ExtendedRecovery',
            statusLC: 'warning',
        },
        {
            statusNuvem: 'InvestigationSuspended',
            statusLC: 'warning',
        },
        {
            statusNuvem: 'ServicesRestored',
            statusLC: 'success',
        },
        {
            statusNuvem: 'FalsePositive',
            statusLC: 'success',
        },
        {
            statusNuvem: 'PostIncidentReportPublished',
            statusLC: 'success',
        },
        {
            statusNuvem: 'ServiceOperational',
            statusLC: 'success',
        },
    ];
    const [recordsMonitoramentoStatusLoading, setRecordsMonitoramentoStatusLoading] = useState<boolean>(true);
    const [optionLicensesContratadasLoading, setOptionLicensesContratadasLoading] = useState<any>(true);
    const ServiceInstance = new LCTechService({});
    const [secureScore, setSecureScore] = useState<SecureScore>();
    const [secureScoreLoading, setSecureScoreLoading] = useState<any>(true);
    const [ADConnectCount, setADConnectCount] = useState<number>(100); // TODO: backend data connection
    const [ADConnectCountLoading, setADConnectCountLoading] = useState<boolean>(false); // TODO: backend data connection
    const [fraudEventsReportCount, setFraudEventsReportCount] = useState<any>();
    const [fraudEventsReportCountLoading, setFraudEventsReportCountLoading] = useState<any>(true);
    const [usersRecordsEmailUsage, setUsersRecordsEmailUsage] = useState<any[]>([]);
    const [usersRecordsEmailUsageLoading, setUsersRecordsEmailUsageLoading] = useState<boolean>(true);
    const [usersRecordsOneDriveUsage, setUsersRecordsOneDriveUsage] = useState<any[]>([]);
    const [sharePointSiteUsageDetail, setSharePointSiteUsageDetail] = useState<SharePointSiteUsageDetail[]>([]);
    const [usersRecordsOneDriveUsageLoading, setUsersRecordsOneDriveUsageLoading] = useState<boolean>(true);
    const [sharePointSiteUsageDetailLoading, setSharePointSiteUsageDetailLoading] = useState<boolean>(true);
    const [subscriptions, setSubscriptions] = useState<SubscriptionData>();
    const [formattedData, setFormattedData] = useState<any[]>();
    const [expandedData, setExpandedData] = useState<any[]>();
    const [loadingLicenseFormattedData, setLoadingLicenseFormattedData] = useState<boolean>();
    const [pivotlicensesFocus, setPivotlicensesFocus] = useState<boolean>(false);


    const loadUsers = async () => {
        try {
            const SaaS_Service = new SaasUserService(props);
            setLoadingLicenseFormattedData(true);
            var expandedDataLocal = await SaaS_Service.GetUserServices();
            setExpandedData(expandedDataLocal.pivot);
            setFormattedData(expandedDataLocal.pivot);
        } catch (e) {
            console.error(e);
        }
        setLoadingLicenseFormattedData(false);
    }

    const getCards = (month, year) => {
        saasService.GetCards(month, year)
            .then(response => {
                if (response.data != null) {
                    setSloChart(response.data.slaConclusao == 'NaN' ? 100 : ((response.data.slaConclusao.toFixed(1) / 100) * 100));

                    setSlaChart(response.data.slaAtendimento == 'NaN' ? 100 : ((response.data.slaAtendimento.toFixed(1) / 100) * 100));
                    let maxChamadosChart = response.data.totalChamadosHC >= response.data.totalChamadosFHC ?
                        (response.data.totalChamadosHC + 10)
                        : (response.data.totalChamadosFHC + 10);

                    let chamadosChart: DoughnutChartValue = {
                        title: 'Chamados por horário',
                        labels: [],
                        values: []
                    }
                    if (response.data.totalChamadosHC > 0) {
                        chamadosChart.values.push(response.data.totalChamadosHC);
                        chamadosChart.labels.push('Comercial');
                    }
                    if (response.data.totalChamadosFHC > 0) {
                        chamadosChart.values.push(response.data.totalChamadosFHC);
                        chamadosChart.labels.push('Não comercial');
                    }
                    setChamadosChart(chamadosChart);
                    setIsLoadingChamadosChart(false)

                    let totalChamadosChart: DoughnutChartValue = {
                        title: 'Chamados por situação',
                        labels: [],
                        values: []
                    }
                    if (response.data.totalChamadosAbertas > 0) {
                        totalChamadosChart.values.push(response.data.totalChamadosAbertas);
                        totalChamadosChart.labels.push('Abertos');
                    }
                    if (response.data.totalChamadosAbertas > 0) {
                        totalChamadosChart.values.push(response.data.totalChamadosFechadas);
                        totalChamadosChart.labels.push('Fechados');
                    }
                    if (response.data.totalChamadosAbertas > 0) {
                        totalChamadosChart.values.push(response.data.totalChamadosCanceladas);
                        totalChamadosChart.labels.push('Cancelados');
                    }
                    setTotalChamadosChart(totalChamadosChart);
                    setIsLoadingTotalChamadosChart(false)

                    let clientName: string = user.ClientGroupSelectedDescription?.toString().toLowerCase() as string;
                    let _c = clientName.split(' ')
                    let capitalized = '';
                    _c.map((e: any) => {
                        capitalized = capitalized + e[0].toUpperCase() + e.substr(1) + ' '
                    })

                    let chamadosPorOrigemChart: DoughnutChartValue = {
                        title: 'Chamados por origem',
                        labels: [],
                        values: []
                    }
                    if (response.data.totalChamadosAbertosPeloCliente > 0) {
                        chamadosPorOrigemChart.labels.push(capitalized)
                        chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPeloCliente)
                    }
                    if (response.data.totalChamadosAbertosPelaProcessor > 0) {
                        chamadosPorOrigemChart.labels.push('Processor')
                        chamadosPorOrigemChart.values.push(response.data.totalChamadosAbertosPelaProcessor)
                    }
                    setChamadosPorOrigemChart(chamadosPorOrigemChart)
                    setIsLoadingChamadosPorOrigemChart(false)
                }

            })
            .catch(error => {
                console.error(error);
            })
    }

    useEffect(() => {
        setSecureScoreLoading(true)

        ServiceInstance.GetSecureScores()
            .then(response => {
                setSecureScore(response);
            })
            .catch(error => {
                console.error(error)
                if (error.message && typeof error.message == 'string')
                    return
            })
            .finally(() => setSecureScoreLoading(false));
        setUsersRecordsOneDriveUsageLoading(true)
        saasService.GetOneDriveUsageAccountReport(30)
            .then(response => {
                setUsersRecordsOneDriveUsageLoading(false);

                setUsersRecordsOneDriveUsage(response.sort((a, b) => { return a.storageUsedByte < b.storageUsedByte ? 1 : -1; }));
            })
            .catch(error => {
                setUsersRecordsOneDriveUsageLoading(false);
                if (error.response.data.message && typeof error.response.data.message == 'string') {

                    return
                }
            });

        saasService.GetSharePointSiteUsageDetail(30)
            .then(response => {
                setSharePointSiteUsageDetailLoading(false);

                setSharePointSiteUsageDetail(response.sort((a, b) => { return a.storageUsed < b.storageUsed ? 1 : -1; }));
            })
            .catch(error => {
                setUsersRecordsOneDriveUsageLoading(false);
                if (error.response.data.message && typeof error.response.data.message == 'string') {

                    return
                }
            });

        setUsersRecordsEmailUsageLoading(true);
        saasService.GetOffice365EmailActivityUserDetail(30)
            .then(response => {

                setUsersRecordsEmailUsage(response.data.sort((a, b) => { return (a.sendCount + a.receiveCount) > (b.sendCount + b.receiveCount) ? 1 : -1; }));
                setUsersRecordsEmailUsageLoading(false);
            })
            .catch(error => {
                setUsersRecordsEmailUsageLoading(false);
                if (error.response.data.message && typeof error.response.data.message == 'string') {

                    return
                }
            });

    }, [user.ClientGroupSelected, user.refreshFlag,]);

    useEffect(() => {
        setFraudEventsReportCountLoading(true);
        ServiceInstance.GetFraudEventsReportByClientGroupId()
            .then(response => {
                setFraudEventsReportCount(response.data);
                setFraudEventsReportCountLoading(false);
            })
            .catch(error => {
                setFraudEventsReportCountLoading(false);
                if (error.response.data.message && typeof error.response.data.message == 'string') {

                    return
                }
            });
    }, [user.ClientGroupSelected, user.refreshFlag,]);

    const calculateGridBottom = (height) => {
        // Define a sigmoid function
        const sigmoid = x => 1 / (1 + Math.exp(-x));

        // Normalize height (adjusting the 0.005 factor changes the "speed" of the transition)
        const normalizedHeight = (height - 1080) * 0.005;

        // Calculate percentage value using the sigmoid function
        const percentage = 25 - sigmoid(normalizedHeight) * 15;  // This will range between 5% (large screens) and 20% (small screens)
        return `${percentage.toFixed(2)}%`;
    };

    const screenWidth = window.innerWidth;
    let fontSize = screenWidth <= 1400 ? 10 : 12;


    // const optsChart = {
    //     title: {
    //         text: props.isHome ? 'Softwares no momento' : undefined,
    //         right: "0",
    //         top: "0",
    //         textStyle: {
    //             fontSize: 11,
    //             fontWeight: 'normal',
    //             color: '#8b90a3',
    //             fontFamily: 'Ubuntu',
    //         },
    //         backgroundColor: props.isHome ? '#ffffff90' : 'transparent',
    //         borderRadius: [10],
    //         padding: [4, 8, 4, 8],
    //         itemGap: 8
    //     },
    //     grid: {
    //         top: !props.isHome ? '25px' : '40px',
    //         bottom: props.isHome ? '50px' : '29%',
    //         left: '1%',
    //         right: '1%',
    //         containLabel: props.isHome ? false : true,
    //     },
    //     tooltip: {
    //         backgroundColor: '#f1f5fb',
    //         borderWidth: 0,
    //         textStyle: {
    //             fontSize: 10,
    //             fontFamily: 'Ubuntu',
    //             color: '#2B304F',
    //         },
    //         trigger: 'axis',
    //         axisPointer: {
    //             type: 'shadow'
    //         },
    //         formatter: function (params) {
    //             let tooltip = "<b>" + params[0].axisValueLabel + "</b><br>";
    //             let consumedIndex = params.findIndex(p => p.seriesName === 'Atribuídas');
    //             let contractedIndex = params.findIndex(p => p.seriesName === 'Contratadas');

    //             if (consumedIndex !== -1 && contractedIndex !== -1) {
    //                 let consumedUnits = params[consumedIndex].value;
    //                 let contractedUnits = params[contractedIndex].value;
    //                 let percentage = ((consumedUnits / contractedUnits) * 100).toFixed(2);

    //                 tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[consumedIndex].color + '"></span> Atribuídas: ' + consumedUnits + "<br>";
    //                 tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[contractedIndex].color + '"></span> Contratadas: ' + contractedUnits + "<br>";
    //                 tooltip += "Porcentagem: " + percentage + "%";
    //             } else if( consumedIndex !== -1 && contractedIndex === -1) {
    //                 let consumedUnits = params[consumedIndex].value;
    //                 tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[consumedIndex].color + '"></span> Atribuídas: ' + consumedUnits + "<br>";
    //             } else {
    //                 let contractedUnits = params[contractedIndex].value;
    //                 tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[contractedIndex].color + '"></span> Contratadas: ' + contractedUnits + "<br>";
    //             }
    //             return tooltip;
    //         }
    //     },
    //     xAxis: {
    //         type: 'category',
    //         data: subscriptions.map(item => item.displayName.trim()),
    //         axisLabel: {
    //             interval: 0,
    //             formatter: function (value) {
    //                 return value.length > 9 ? value.replace("Microsoft", "").slice(0, 9) + "..." : value;
    //             },
    //             fontSize: fontSize,
    //             fontFamily: 'Ubuntu',
    //             color: '#8b90a3',
    //         },
    //         show: true,
    //     },
    //     yAxis: {
    //         axisLabel: {
    //             fontSize: fontSize,
    //             fontFamily: 'Ubuntu',
    //             color: '#8b90a3',
    //         },
    //         show: props.isHome ? false : true,
    //         splitLine: {
    //             show: true,
    //             lineStyle: {
    //                 type: 'dashed'
    //             },
    //         },
    //     },
    //     legend: {
    //         data: ['Atribuídas', 'Contratadas'],
    //         width: '100%',
    //         right: 'center',

    //         textStyle: {
    //             fontFamily: 'Ubuntu',
    //             color: '#8b90a3',
    //             fontSize: props.isHome ? 9 : fontSize,
    //         },
    //         bottom: props.isHome ? '5px' : '15%',
    //         inactiveColor: '#ededed',
    //         inactiveBorderColor: '#ededed',
    //         icon: 'circle',
    //     },
    //     series: [
    //         {
    //             name: 'Atribuídas',
    //             type: 'bar',
    //             //barGap: '20%',
    //             itemStyle: {
    //                 color: '#471fcc',
    //                 opacity: 0.85
    //             },
    //             emphasis: {
    //                 itemStyle: {
    //                     opacity: 1
    //                 }
    //             },
    //             label: {
    //                 show: true,
    //                 position: 'top',
    //                 color: '#2B304F',
    //                 formatter: '{c}',
    //                 textStyle: {
    //                     color: '#9a9fb0', // Cor do texto
    //                     fontFamily: 'Ubuntu', // Fonte do texto
    //                     fontSize: 9, // Tamanho da fonte
    //                 },
    //             },
    //             data: subscriptions.map(item => item.consumedUnits)
    //         },
    //         {
    //             name: 'Contratadas',
    //             type: 'bar',
    //             //barGap: '-100%',
    //             itemStyle: {
    //                 color: '#a8acba',
    //                 opacity: 0.85
    //             },
    //             emphasis: {
    //                 itemStyle: {
    //                     opacity: 1
    //                 }
    //             },
    //             label: {
    //                 show: true, // Mostra o label
    //                 position: 'top', // Posição do label acima da barra
    //                 color: '#2B304F', // Cor do texto
    //                 formatter: '{c}', // Formatação do conteúdo do label para mostrar o valor da barra
    //                 textStyle: {
    //                     color: '#9a9fb0', // Cor do texto
    //                     fontFamily: 'Ubuntu', // Fonte do texto
    //                     fontSize: 9, // Tamanho da fonte
    //                 },
    //             },
    //             data: subscriptions.map(item => item.prepaidUnid)
    //         },
    //     ],
    //     dataZoom:  subscriptions.length > 3 ? [{
    //         type: 'slider',
    //         start: 0,
    //         end: ((props.isHome ? 3 : 6) / subscriptions.length * 100) - 5,
    //         height: 17,
    //         show: !props.isHome,
    //         bottom: '12px'
    //     }] : []
    // }

    const RetrieveList = () => {
        let gridBottomValue = calculateGridBottom(window.innerHeight); // Replace 'yourContainerId' with your actual container's ID
        setLoading(true);
        // setSubscriptions();
        setOptionLicensesContratadasLoading(true);
        ServiceInstance.Retrieve(1)
            .then((response) => {
                if (response && response.data) {
                    setSubscriptions(response.data);
                    setLoading(false);
                    setOptionLicensesContratadasLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setOptionLicensesContratadasLoading(false);
            })
            .finally(() => {
                setOptionLicensesContratadasLoading(false);
                setLoading(false);
            });
        Promise.all([
            GetDataChart(7, 1),
            GetDataChart(7, 2),
            GetDataChart(7, 3),
            GetDataChart(7, 4),
        ])
    };

    const statusServices = [
        {
            statusNuvem: 'Investigating',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'ServiceDegradation',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'ServiceInterruption',
            statusLC: 'danger',
        },

        {
            statusNuvem: 'RestoringService',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'ExtendedRecovery',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'InvestigationSuspended',
            statusLC: 'warning',
        },

        {
            statusNuvem: 'ServicesRestored',
            statusLC: 'success',
        },

        {
            statusNuvem: 'FalsePositive',
            statusLC: 'success',
        },

        {
            statusNuvem: 'PostIncidentReportPublished',
            statusLC: 'success',
        },

        {
            statusNuvem: 'ServiceOperational',
            statusLC: 'success',
        },

    ]

    useEffect(() => {
        setSloChart(0);
        setSlaChart(0);
        setChamadosChart({ title: "", labels: [], values: [] });
        setTotalChamadosChart({ title: "", labels: [], values: [] });
        setChamadosPorOrigemChart({ title: "", labels: [], values: [] });
        setRecordsMonitoramentoStatusLoading(true);
        if (user.ClientGroupSelected) {
            setLoading(true)
            Promise.all([
                loadUsers(),
                RetrieveList(),
                getCards(month, year),
            ]).finally(() => setLoading(false))
        }

        ServiceInstance.RetrieveUsage(1, true, month, year,)
            .then((response) => {
                setLoading(true);
                setResourceUsagePivotRecords([]);
                setResourceUsagePivotRecordsLoading(true);

                if (response && response.data) {
                    try {
                        let _hasProp = response.data.hasOwnProperty('resourceUsageRecords');
                        let _hasAny = _hasProp && response.data['resourceUsageRecords'].length > 0;
                        let _hasCloud = response.data.hasOwnProperty('lastModifiedDate');
                        if (_hasProp && _hasAny) {
                            //  setResourceUsagePivotRecords([]);
                            let resourcePivot: any[] = [];
                            var trintaDiasAtras = new Date();

                            trintaDiasAtras = moment(trintaDiasAtras).subtract(30, 'day').toDate();
                            response.data.resourceUsageRecords.filter(item => sCdateToJsDate(item.usageDate) >= trintaDiasAtras && sCdateToJsDate(item.usageDate) < (new Date())).forEach((resourceUsage) => {
                                try {

                                    let month = sCdateToJsDate(resourceUsage.usageDate).getMonth() + 1;
                                    let fullYear = sCdateToJsDate(resourceUsage.usageDate).getFullYear();
                                    // Filtrar apenas os últimos 30 dias

                                    let record = {
                                        "Assinatura": resourceUsage.entitlementName,
                                        "Grupo do Recurso": resourceUsage.resourceGroupName,
                                        "Recurso": resourceUsage.resourceName,
                                        "Categoria": resourceUsage.meterCategory,
                                        "SubCategoria": resourceUsage.meterSubCategory,
                                        "Mês Consumo": month,
                                        "Ano Consumo": fullYear,
                                        "Dia Consumo": sCdateToJsDate(resourceUsage.usageDate).getDate(),
                                        "Tags": resourceUsage.tag,
                                        "Moeda": resourceUsage.currencyCode,
                                        "Total": resourceUsage.totalCost && resourceUsage.totalCost.toFixed(6),
                                        "Unidade": resourceUsage.unitOfMeasure,
                                        "Quantidade": parseInt(resourceUsage.quantityUsed),
                                        "Nome do Medidor": resourceUsage.meterName
                                    };

                                    resourcePivot.push(record);
                                } catch (e) {

                                }
                            });

                            // Obter a data atual e subtrair 30 dias
                            const totalPorData = resourcePivot.reduce((acc, item) => {
                                // Converter o valor total para número
                                const total = parseFloat(item.Total);

                                // Converter dia e mês para uma string de data
                                const data = `${item['Ano Consumo'].toString()}-${item['Mês Consumo'].toString().padStart(2, '0')}-${item['Dia Consumo'].toString().padStart(2, '0')}`;

                                // Adicionar o valor total à data correspondente
                                if (acc[data]) {
                                    acc[data] += total;
                                } else {
                                    acc[data] = total;
                                }

                                return acc;
                            }, {});

                            // Converter o objeto para um array e ordenar por data
                            let arrayFinal = Object.entries(totalPorData).map(([data, total]) => {
                                const [ano, mes, dia] = data.split('-');
                                return { data: new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia)), total };
                            });

                            arrayFinal.sort((a, b) => b.data.getTime() - a.data.getTime());

                            setResourceUsagePivotRecords(arrayFinal);
                            setResourceUsagePivotRecordsLoading(false);

                        } else {
                            _hasCloud ?
                                setResourceUsagePivotNoRecordMsg('Não há dados disponíveis de consumo.') :
                                setResourceUsagePivotNoRecordMsg('Não foram encontrados dados de cloud pública.');
                        }
                    } catch (e) {
                        console.error('FooterError');
                    }
                }
            })
            .catch((error) => {

                console.error(error);
                setResourceUsagePivotRecordsLoading(false);
            })
            .finally(() => {

                setLoading(false);
                setResourceUsagePivotRecordsLoading(false);
            });

    }, [user.ClientGroupSelected, searchStartDate, user.refreshFlag]);

    const onChagePeriod = (selected: any) => {
        let momentDate = Moment(selected.fields[0].value).format('YYYY-M');
        let date = momentDate;
        let currentDay = '01';
        let currentMonth = date.split('-')[1];
        let currentYear = date.split('-')[0];
        setDay(currentDay);
        setMonth(currentMonth);
        setYear(currentYear);
        setSearchStartDate(`${currentYear}${currentMonth.padStart(2, '0')}${currentDay}`);
    }

    const [lastUpdate, setLastUpdate] = useState<string>("");

    const showLastUpdate = (max_date: any) => {

    }
    let _showLastUpdate: boolean = true

    function SetUsersChart(response: any) {

        if (response != null) {

            let max_Date: any[] = []
            response.map((item: any) => {
                let date = new Date(item.reportDate)
                let d = date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                let h = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
                max_Date.push(
                    {
                        date: d,
                        hours: h
                    }
                )
            })
            setLastUpdate(new Date(response[response.length - 1].reportDate).toLocaleString());
            showLastUpdate(max_Date[max_Date.length - 1])

            let usersChart = {
                labels: response.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),

                datasets: [
                    {
                        name: 'Office365',
                        data: response.map((Item: any) => {
                            return Item.office365
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'Exchange',
                        data: response.map((Item: any) => {
                            return Item.exchange
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'OneDrive',
                        data: response.map((Item: any) => {
                            return Item.oneDrive
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'SharePoint',
                        data: response.map((Item: any) => {
                            return Item.sharePoint
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    // {
                    //     name: 'SkypeForBusiness',
                    //     data: response. map((Item: any) => {
                    //         return Item.skypeForBusiness
                    //     }),
                    //     type: 'line',
                    //     smooth: true,
                    // },
                    {
                        name: 'Yammer',
                        data: response.map((Item: any) => {
                            return Item.yammer
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'Teams',
                        data: response.map((Item: any) => {
                            return Item.teams
                        }),
                        type: 'line',
                        smooth: true,
                    }
                ]
            };
            setUsersChart(usersChart);
            setUsersChartLoading(false);

        } else {
            let usersChart = {
                labels: [],
                datasets: []
            };
            setUsersChart(usersChart);
            setUsersChartLoading(false);
        }
    };

    function SetEmailActivityChart(response: any) {
        if (response.data != null) {

            let emailActivityChart = {
                labels: response.data.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: "Enviado",
                        data: response.data.map((Item: any) => {
                            return Item.send
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: "Recebido",
                        data: response.data.map((Item: any) => {
                            return Item.receive
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: "Lido",
                        data: response.data.map((Item: any) => {
                            return Item.read
                        }),
                        type: 'line',
                        smooth: true,
                    }
                ]
            };
            setEmailActivityChart(emailActivityChart);
        }
    };

    function SetEmailUsageChart(response: any) {
        if (response.data != null) {

            let emailUsageChart = {
                labels: response.data.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: "Total",
                        data: response.data.map((Item: any) => {
                            return Item.total
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: "Ativas",
                        data: response.data.map((Item: any) => {
                            return Item.active
                        }),
                        type: 'line',
                        smooth: true,
                    }
                ]
            };
            setEmailUsageChart(emailUsageChart);
        }
    };

    function SetOneDriveUsageChart(response: any) {
        if (response.data != null) {

            let oneDriveUsageChart = {
                labels: response.data.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: "Total de contas",
                        data: response.data.map((Item: any) => {
                            return Item.total
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: "Contas ativas",
                        data: response.data.map((Item: any) => {
                            return Item.active
                        }),
                        type: 'line',
                        smooth: true,
                    }
                ]
            };
            setOneDriveUsageChart(oneDriveUsageChart);
        }
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-M') }
                ],
                default: true
            }
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    }
    async function GetDataChart(days: number, graph: number) {
        const saasService = new SaasService(props);
        switch (graph) {
            case 1:
                setUsersChart("Default");
                setUsersChartLoading(true);
                await saasService.GetUsersChart(days)
                    .then(response => {
                        SetUsersChart(response);
                    })
                    .catch(error => {
                        console.error(error);
                        setUsersChartLoading(false);
                    });
                break;
            case 2:
                setEmailActivityChart("Default");
                setEmailActivityChartLoading(true);
                await saasService.GetEmailActivityChart(days)
                    .then(response => {
                        setEmailActivityChartLoading(false);

                        SetEmailActivityChart(response);
                    })
                    .catch(error => {
                        setEmailActivityChartLoading(false);
                        console.error(error);
                    });
                break;
            case 3:
                setEmailUsageChart("Default");
                setEmailUsageChartLoading(true);
                await saasService.GetEmailUsageChart(days)
                    .then(response => {
                        setEmailUsageChartLoading(false);
                        SetEmailUsageChart(response);
                    })
                    .catch(error => {
                        setEmailUsageChartLoading(false);
                        console.error(error);
                    });
                break;
            case 4:
                setOneDriveUsageChart("Default");
                setOneDriveUsageChartLoading(true);
                await saasService.GetOneDriveUsageChart(days)
                    .then(response => {

                        setOneDriveUsageChartLoading(false);
                        SetOneDriveUsageChart(response);
                    })
                    .catch(error => {
                        setOneDriveUsageChartLoading(false);
                        console.error(error);
                    });
                break;
            default:
                console.log("Tipo de gráfico não reconhecido.");
                break;
        }
    };

    const positions5Groups: Card[] = [{
        id: 1,
        position: {
            ColSpan: 2,
            RowSpan: 3, row: 1, col: 1, hidden: false
        }
    }, {
        id: 2,
        position: { RowSpan: 1, ColSpan: 1, row: 3, col: 1, hidden: true },
    }, {//Top 5 Caixas Email
        id: 3,
        position: {
            ColSpan: 2,
            RowSpan: 2, row: 1, col: 1, hidden: true
        },


    }, {
        id: 4,
        position: {
            ColSpan: 2,
            RowSpan: 2, row: 1, col: 1, hidden: true
        },
    },
    {//Monitoramento dos serviços Office
        id: 5,

        position: {
            ColSpan: 2,
            RowSpan: 1, row: 4, col: 1, hidden: false
        },


    }, {
        // Últimos 30 dias de consumo Cloud
        id: 6,
        position: { RowSpan: 1, ColSpan: 3, row: 1, col: 10, hidden: true },
    },
    //Status do AD Connect
    {
        id: 7,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 5, col: 1, hidden: false
        },
    }, {//Alertas de Fraude
        id: 8,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 6, col: 1, hidden: false
        },

    }, {
        //Usuários ativos / dia
        id: 9,
        position: {
            ColSpan: 3,
            RowSpan: 1, row: 3, col: 9, hidden: true
        },
    }, {
        //Atividades de e-mail
        id: 10,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 3, col: 11, hidden: true
        },
    }, {
        //OneDrive ativas / uso
        id: 11,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 4, col: 9, hidden: true
        },
    }, {
        //Caixas - ativas / uso
        id: 12,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 4, col: 11, hidden: true
        },
    }];

    const positions3Groups: Card[] = [{
        id: 1,
        position: {
            ColSpan: 2,
            RowSpan: 3, row: 1, col: 1, hidden: false
        }
    }, {
        id: 2,
        position: { RowSpan: 1, ColSpan: 1, row: 3, col: 1, hidden: true },
    }, {//Top 5 Caixas Email
        id: 3,
        position: {
            ColSpan: 2,
            RowSpan: 2, row: 1, col: 1, hidden: true
        },


    }, {
        id: 4,
        position: {
            ColSpan: 2,
            RowSpan: 2, row: 1, col: 1, hidden: true
        },
    },
    {//Monitoramento dos serviços Office
        id: 5,

        position: {
            ColSpan: 2,
            RowSpan: 1, row: 4, col: 1, hidden: false
        },


    }, {
        // Últimos 30 dias de consumo Cloud
        id: 6,
        position: { RowSpan: 1, ColSpan: 3, row: 1, col: 10, hidden: true },
    },
    //Status do AD Connect
    {
        id: 7,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 5, col: 1, hidden: false
        },
    }, {//Alertas de Fraude
        id: 8,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 6, col: 1, hidden: false
        },

    }, {
        //Usuários ativos / dia
        id: 9,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 3, col: 9, hidden: true
        },
    }, {
        //Atividades de e-mail
        id: 10,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 3, col: 11, hidden: true
        },
    }, {
        //OneDrive ativas / uso
        id: 11,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 4, col: 9, hidden: true
        },
    }, {
        //Caixas - ativas / uso
        id: 12,
        position: {
            ColSpan: 2,
            RowSpan: 1, row: 4, col: 11, hidden: true
        },
    }];

    const positions2Groups: Card[] = [{
        id: 1,
        position: {
            ColSpan: 1,
            RowSpan: 3, row: 1, col: 1, hidden: false
        }
    },
    {
        id: 2,
        position: { RowSpan: 1, ColSpan: 1, row: 3, col: 1, hidden: true },
    },
    {//Top 5 Caixas Email
        id: 3,
        position: {
            ColSpan: 2,
            RowSpan: 2, row: 1, col: 1, hidden: true
        },
    },
    //Top 5 OneDrives (GB)
    {
        id: 4,
        position: {
            ColSpan: 1,
            RowSpan: 3, row: 1, col: 2, hidden: false
        },
    },
    {//Monitoramento dos serviços Office
        id: 5,

        position: {
            ColSpan: 1,
            RowSpan: 1, row: 4, col: 1, hidden: false
        },
    },
    {
        // Últimos 30 dias de consumo Cloud
        id: 6,
        position: { RowSpan: 1, ColSpan: 3, row: 1, col: 10, hidden: true },
    },
    //Status do AD Connect
    {
        id: 7,
        position: {
            ColSpan: 1,
            RowSpan: 1, row: 5, col: 1, hidden: false
        },
    },
    {//Alertas de Fraude
        id: 8,
        position: {
            ColSpan: 1,
            RowSpan: 1, row: 6, col: 1, hidden: false
        },

    },
    {
        //Usuários ativos / dia
        id: 9,
        position: {
            ColSpan: 1,
            RowSpan: 1, row: 4, col: 2, hidden: false
        },
    },
    {
        //Atividades de e-mail
        id: 10,
        position: {
            ColSpan: 1,
            RowSpan: 1, row: 5, col: 2, hidden: false
        },
    },
    {
        //OneDrive ativas / uso
        id: 11,
        position: {
            ColSpan: 1,
            RowSpan: 1, row: 3, col: 2, hidden: true
        },
    },
    {
        //Caixas - ativas / uso
        id: 12,
        position: {
            ColSpan: 1,
            RowSpan: 1, row: 6, col: 2, hidden: false
        },
    }];
    function positionHome(cardId: number | undefined, qtdGroups: number): PositionCard {
        var Id = cardId as number;
        if (qtdGroups <= 2) {
            return positions2Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter(c => c.id == Id)[0].position;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter(c => c.id == Id)[0].position;
        }

        return {} as unknown as PositionCard;
    }


    //identificando charts
    const chartEmail = useRef<ReactECharts>(null);
    const chartOneDrive = useRef<ReactECharts>(null);
    const chartSharepoint = useRef<ReactECharts>(null);

    const chartOptions = [
        { label: 'Caixas de email', value: "email" },
        { label: 'OneDrive (GB)', value: "onedrive" },
        //   { label: 'Site Sharepoint (GB)', value: "sharepoint" }
    ];

    // Event handler for clicks on the chart
    const onChartClick = (params: any) => {
        console.log('Clicked chart item params:', params);
        console.log('expandedData:', expandedData);

        if (params && expandedData) {
            var users = expandedData
                .filter(b => {
                    const lncsLower = b.lncs ? b.lncs.toLowerCase() : "";
                    const nameLower = params.name ? params.name.toLowerCase() : "";
                    return lncsLower === nameLower;
                });
            if (users.length == 0) {
                users = expandedData
                    .filter(b => {
                        const lncsLower = b.sku ? b.sku.toLowerCase() : "";
                        const nameLower = params.name ? params.name.toLowerCase() : "";
                        return lncsLower === nameLower;
                    });
            }
            setFormattedData(users);
        }
    }

    const history2 = useHistory();

    const handleOfficeTicketButtonClick = () => {
        // Verifica a rota atual e redireciona para a rota apropriada
        if (document.location.pathname === '/SaaS/Resume') {
            history2.push('/ManageOfficeStatus');
        } else if (document.location.pathname === '/CSP/Resume') {
            history2.push('/csp/ManageOfficeStatus');
        }
    };

    const handleSegurancaTicketButtonClick = () => {
        // Verifica a rota atual e redireciona para a rota apropriada
        if (document.location.pathname === '/SaaS/Resume') {
            history2.push('/SaaS/SecurityScore');
        } else if (document.location.pathname === '/CSP/Resume') {
            history2.push('/CSP/SecurityScore');
        }
    };
    const handleClick = useCallback((params) => {
        if (!props.isHome) {
            onChartClick(params);
            setPivotlicensesFocus(true);
        }
    }, [props.isHome, onChartClick, setPivotlicensesFocus]);

    const getFraudEventReportValue = () => {
        return fraudEventsReportCount && fraudEventsReportCount > 0 ?
            'Enviamos os detalhes por e-mail para você' : 'Não existem alertas no momento';
    }

    const getADConnectReportValue = () => {
        return 'Está tudo em funcionamento'
    }

    var handleSoftwareChartFocusClick: Function | undefined = undefined;
    const softwaresMemoChart = useMemo(() => {
        if (subscriptions && subscriptions.subscriptions.length > 0) {
            return (
                <SubscriptionComercial
                    subscriptionData={subscriptions}
                    isHome={props.isHome}
                    onEvents={props.isHome ? {} : {
                        'click': (params: any) => {
                            onChartClick(params);
                            setTimeout(() => {
                                if (handleSoftwareChartFocusClick) {
                                    handleSoftwareChartFocusClick();
                                    setPivotlicensesFocus(true);
                                }
                            }, 150);
                        }
                    }}
                    horizontal
                />
            )
        }
    }, [subscriptions])

    var cards: Card[] = [
        {//  Softwares Consumidos  vs Contratados
            id: 1,
            type: 'Custom',
            showFocusButton: !props.isHome,
            hideHeader: props.isHome,
            position: { ColSpan: 4, RowSpan: 3, row: 1, col: 1 },
            title: () => {
                return <>{
                    <div className={'lc-segment-title-row'} >
                        <div className={'lc-title'}>
                            Subscrições Atribuídas vs Contratadas
                        </div>
                    </div>
                }</>
            },
            customContentRender: (focused?: boolean, handleFocusClick?: Function) => {
                if ((!props.isHome)) {
                    setPivotlicensesFocus(focused === true);
                }

                return (

                    <LcLoading loading={optionLicensesContratadasLoading}>
                        {
                            subscriptions && subscriptions.subscriptions.length > 0 ?
                                softwaresMemoChart :
                                <LcNoData size="small" label="Não foram encontrados dados de licenciamento." />
                        }
                    </LcLoading>
                )
            }
        },
        {// Score de segurança
            id: 2,
            type: 'Custom',
            ticketButtonLabel: !props.isHome ? "Ir para Segurança" : undefined,
            ticketButton: !props.isHome && document.location.pathname !== '/SaaS/Resume' ? () => {
                return <PiArrowSquareOutLight onClick={handleSegurancaTicketButtonClick} />;
            } : undefined,
            position: { RowSpan: 2, ColSpan: 4, row: 4, col: 5 },
            infoContent: () => `Dados provenientes do serviço de segurança da Microsoft Azure`,
            title: () => "Score de segurança",
            customContentRender: (focused?: boolean) => {
                let textTooltip = (secureScore) ? `${secureScore.currentScore} pontos de ${secureScore.maxScore} possíveis` : `0 pontos de 0 possíveis`
                return (
                    <LcLoading loading={secureScoreLoading}>
                        {secureScore && secureScore?.maxScore > 0 ?
                            <ProgressBarWithLabel
                                atualPercentScore={secureScore.currentScore}
                                maxPercentScore={secureScore.maxScore}
                                textTooltip={textTooltip}
                                label='Meu score'
                                size='small'
                            />
                            :
                            <LcNoData size='small' />
                        }
                        {/* {secureScore && secureScore?.maxScore > 0 &&
                        <GaugeChartCore style={{ maxHeight: '150px', width: '100%' }}
                            title={(Math.round(secureScore?.currentScore) || 0) + " pontos de " + (secureScore?.maxScore || 0) + " possíveis"}
                            value={((secureScore?.currentScore * 100) / secureScore?.maxScore) || 100}
                        />
                    } */}
                    </LcLoading>
                )
            },
        },
        {//Usuários
            id: 3, type: 'Custom',
            showFocusButton: !props.isHome,
            position: { ColSpan: 4, RowSpan: 3, row: 1, col: 5 },
            title: () => {
                return (
                    <div className="lc-segment-title-row-resume">
                        <div className="lc-title ellipsis">Usuários</div>
                        <PPDropDown
                            options={chartOptions}
                            onChange={(e) => {
                                if (chartEmail.current) {
                                    chartEmail.current.getEchartsInstance().clear();
                                }
                                if (chartOneDrive.current) {
                                    chartOneDrive.current.getEchartsInstance().clear();
                                }
                                e.value == '' ? setTopUsersView('email') : setTopUsersView(e.value);
                            }}

                            defaultValue={chartOptions.find(option => option.value == topUsersView)}
                            value={chartOptions.find(option => option.value == topUsersView)}
                        />
                    </div>
                )
            },
            customContentRender: (focused?: boolean) => {
                const maxUsedStorageValue = Math.max(...sharePointSiteUsageDetail.map(c => Number(c.storageAllocated)));

                return (<>
                    {topUsersView == 'email' && <LcLoading loading={usersRecordsEmailUsageLoading}>
                        {usersRecordsEmailUsage && <ReactECharts ref={chartEmail} style={{ width: '100%', height: '100%' }} opts={{ renderer: 'svg', locale: 'PT-br' }}
                            option={{
                                grid: {
                                    left: '0',
                                    right: '10%',
                                    top: '10%',
                                    bottom: '0',
                                    containLabel: true
                                },
                                tooltip: {
                                    backgroundColor: '#f1f5fb',
                                    borderWidth: 0,
                                    textStyle: {
                                        fontSize: 11,
                                        fontFamily: 'Ubuntu',
                                        color: '#2B304F',
                                    },
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow'
                                    },
                                    formatter: function (params) {
                                        // Supondo que cada ponto de dados inclua o userPrincipalName, você precisa ajustar conforme sua estrutura de dados
                                        var userPrincipalName = usersRecordsEmailUsage[params[0].dataIndex].userPrincipalName;
                                        var valueFormatted = params[0].value.toLocaleString('pt-BR');
                                        return `${userPrincipalName}<br/>Emails: ${valueFormatted}`;
                                    }
                                },
                                yAxis: {
                                    type: 'category',
                                    data: usersRecordsEmailUsage.map(c => c.displayName.split(' - ')[0]),
                                    show: true,
                                    axisLabel: {
                                        fontSize: '10px',
                                        fontFamily: 'Ubuntu',
                                        color: '#8b90a3',
                                    },
                                },
                                xAxis: {
                                    show: false,
                                    type: 'value',
                                    axisLabel: {
                                        fontSize: '10px',
                                        fontFamily: 'Ubuntu',
                                        color: '#8b90a3',
                                    },
                                },
                                series: [{
                                    name: 'Emails:',
                                    type: 'bar',
                                    data: usersRecordsEmailUsage.map(c => { return (Number(c.sendCount + c.receiveCount) / (1)); }),
                                    itemStyle: {
                                        color: function (params) {
                                            var colorList = ['#79c1cb', '#79c1cb', '#79c1cb', '#79c1cb', '#79c1cb'];
                                            return colorList[params.dataIndex]
                                        }
                                    },
                                    label: {
                                        show: true,
                                        position: 'right',
                                        formatter: function (params) {
                                            return `${params.value.toLocaleString('pt-BR')}`;
                                        },
                                        textStyle: {
                                            color: '#9a9fb0', // Cor do texto
                                            fontFamily: 'Ubuntu', // Fonte do texto
                                            fontSize: 9.5, // Tamanho da fonte
                                        },
                                    },
                                }]
                            }} />}
                    </LcLoading>}
                    {topUsersView == 'onedrive' && <LcLoading loading={usersRecordsOneDriveUsageLoading}>
                        {usersRecordsOneDriveUsage && <ReactECharts ref={chartOneDrive} style={{ width: '100%', height: '100%' }} opts={{ renderer: 'svg', locale: 'PT-br' }}
                            option={{
                                grid: {
                                    left: '0%',
                                    right: '15%',
                                    top: '10%',
                                    bottom: '5%',
                                    containLabel: true
                                },
                                tooltip: {
                                    backgroundColor: '#f1f5fb',
                                    borderWidth: 0,
                                    textStyle: {
                                        fontSize: 11,
                                        fontFamily: 'Ubuntu',
                                        color: '#2B304F',
                                    },
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow'
                                    }
                                },
                                yAxis: {
                                    type: 'category',
                                    data: usersRecordsOneDriveUsage.map(c => c.ownerDisplayName.split(' - ')[0]),
                                    show: true,
                                    axisLabel: {
                                        fontSize: '10px',
                                        fontFamily: 'Ubuntu',
                                        color: '#8b90a3',
                                    },
                                    inverse: true
                                },
                                xAxis: {
                                    show: false,
                                    type: 'value',
                                    axisLabel: {
                                        fontSize: '10px',
                                        fontFamily: 'Ubuntu',
                                        color: '#8b90a3',
                                    },
                                },
                                series: [{
                                    name: 'GB Utilizado',
                                    type: 'bar',
                                    data: usersRecordsOneDriveUsage.map(c => (Number(c.storageUsedByte) / (1024 * 1024 * 1024)).toFixed(1)), // Replace with your real values

                                    label: {
                                        show: true,
                                        position: 'right',
                                        formatter: function (params) {
                                            return `${params.value.toLocaleString('pt-BR')}`;
                                        },
                                        textStyle: {
                                            color: '#9a9fb0', // Text color
                                            fontFamily: 'Ubuntu', // Text font
                                            fontSize: 9.5, // Font size
                                        },
                                    },
                                }],
                                dataZoom: [{
                                    type: 'slider',
                                    yAxisIndex: 0,
                                    filterMode: 'filter',
                                    start: 0,
                                    zoomLock: true,
                                    end: 5, // This value might need adjustment based on total items vs. desired items per view                                             
                                    showDataShadow: false, // Optionally hide the data shadow
                                    left: '93%',    // Position the zoom slider to the right side of the chart
                                    width: 15, // Width of the slider
                                    showDetail: false, // Optionally hide the detailed percentage information
                                    orient: 'vertical',
                                }]
                            }} />}
                    </LcLoading>}
                    {topUsersView == 'sharepoint' && <LcLoading loading={sharePointSiteUsageDetailLoading}>
                        {sharePointSiteUsageDetail && <ReactECharts ref={chartSharepoint} style={{ width: '100%', height: '100%' }} opts={{ renderer: 'svg', locale: 'PT-br' }}
                            option={{
                                grid: {
                                    left: '3%',
                                    right: '15%', // Reduced from '15%' which might be too much space
                                    top: '10%',
                                    bottom: '5%',
                                    containLabel: true
                                },
                                tooltip: {
                                    backgroundColor: '#f1f5fb',
                                    borderWidth: 0,
                                    textStyle: {
                                        fontSize: 11,
                                        fontFamily: 'Ubuntu',
                                        color: '#2B304F',
                                    },
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow'
                                    }
                                },
                                yAxis: {
                                    scale: true,
                                    type: 'category',
                                    data: sharePointSiteUsageDetail.map(c => c.ownerDisplayName),
                                    show: true,
                                    axisLabel: {
                                        fontSize: '10px',
                                        fontFamily: 'Ubuntu',
                                        color: '#8b90a3',
                                    },
                                    inverse: true
                                },
                                xAxis: {
                                    scale: true,
                                    show: false,
                                    type: 'log', // Change from 'value' to 'log' for a logarithmic scale
                                    logBase: 10, // Common choice for logarithmic scale, adjust as necessary 
                                    axisLabel: {
                                        fontSize: '10px',
                                        fontFamily: 'Ubuntu',
                                        color: '#8b90a3',
                                    }, max: 400, min: 0
                                },
                                series: [
                                    {
                                        name: 'GB Utilizado',
                                        type: 'bar',
                                        stack: 'total',
                                        data: sharePointSiteUsageDetail.map(c => (Number(c.storageUsed) / (1024 * 1024 * 1024)).toFixed(1)), // Used storage
                                        label: {
                                            show: true,
                                            position: 'insideLeft',
                                            formatter: function (params) {
                                                return `${params.value.toLocaleString('pt-BR')} GB`;
                                            },
                                            textStyle: {
                                                color: '#fff',
                                                fontFamily: 'Ubuntu',
                                                fontSize: 12,
                                            },
                                        },
                                    },
                                    //{
                                    //    name: 'GB Alocado',
                                    //    type: 'bar',
                                    //    stack: 'total',

                                    //    data: sharePointSiteUsageDetail.map(c => (Number(c.storageAllocated) / (1024 * 1024 * 1024)).toFixed(1)), // Allocated storage
                                    //    label: {
                                    //        show: true,
                                    //        position: function (params) {
                                    //            // Check if the bar is long enough for the label to be inside
                                    //            //var isLabelInside = params.value > 10; // Define 'someThreshold' based on your data scale
                                    //            return 'right'; // Position label inside the bar if long, or outside if short
                                    //        },
                                    //        formatter: function (params) {
                                    //            // This label will not be shown directly but you can adjust visibility and content based on your needs
                                    //            return '';
                                    //        },
                                    //        textStyle: {
                                    //            color: '#fff',
                                    //            fontFamily: 'Ubuntu',
                                    //            fontSize: 12,
                                    //        },
                                    //    },

                                    //    itemStyle: {
                                    //        normal: {
                                    //            color: '#9a9fb0', // Making the allocated bar invisible, only showing the used storage visually
                                    //            barBorderColor: '#9a9fb0', // Border color for allocated storage
                                    //            barBorderWidth: 1, // Border width to visually indicate total allocated storage
                                    //        }
                                    //    }
                                    //}
                                ],

                                dataZoom: [{
                                    type: 'slider',
                                    yAxisIndex: 0,
                                    filterMode: 'filter',
                                    start: 0,
                                    zoomLock: true,
                                    end: 10, // This value might need adjustment based on total items vs. desired items per view                                             
                                    showDataShadow: false, // Optionally hide the data shadow
                                    left: '95%',    // Position the zoom slider to the right side of the chart
                                    width: 15, // Width of the slider
                                    showDetail: true, // Optionally hide the detailed percentage information
                                    orient: 'vertical',
                                }]
                            }} />}
                    </LcLoading>}
                </>
                )
            }
        },

        {
            id: 5,
            type: 'Custom',
            ticketButtonLabel: "Ver detalhes",
            position: { RowSpan: 2, ColSpan: 5, row: 4, col: 9 },
            showFocusButton: !props.isHome,
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            infoContent: () => 'Status de serviços disponibilizados pela Microsoft.',
            title: () => <div className="lc-title ellipsis">HealthCheck</div>,
            customContentRender: (focused: boolean) => {
                if (focused) {
                    return <HealthCheckTable />
                } else {
                    return <HealthCheckCard  isHome={props.isHome} />
                }
            }
        },

        {// Últimos 30 dias de consumo Cloud
            id: 6,
            type: 'Custom',
            showFocusButton: !props.isHome,
            position: { ColSpan: 5, RowSpan: 3, row: 1, col: 9 },
            title: !props.isHome ? () => <>{
                <div className="lc-title ellipsis">
                    Consumo cloud 30 dias (R$)
                </div>
            }</> : undefined,
            customContentRender: () => <div style={{ width: '100%', height: '100%' }} >
                <LcLoading loading={resourceUsagePivotRecordsLoading}>
                    {resourceUsagePivotRecords && resourceUsagePivotRecords.length > 0 ?
                        <ReactECharts style={{ width: '100%', height: '100%' }} opts={{ renderer: 'svg', locale: 'PT-br' }}
                            option={{
                                tooltip: {
                                    backgroundColor: '#f1f5fb',
                                    borderWidth: 0,
                                    textStyle: {
                                        fontSize: 11,
                                        fontFamily: 'Ubuntu',
                                        color: '#2B304F',
                                    },
                                    trigger: 'axis',
                                    formatter: function (params) {
                                        const valueFormatted = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params[0].value[1]);
                                        return `${moment(params[0].value[0]).format('DD/MM/YYYY')} : ${valueFormatted}`;
                                    },
                                    axisPointer: {
                                        type: 'line', // vertical line
                                        lineStyle: {
                                            color: '#ccc' // set the desired color for the vertical line
                                        }
                                    }
                                },
                                xAxis: {
                                    type: 'time',
                                    boundaryGap: false,
                                    data: resourceUsagePivotRecords.map(item => item.data),
                                    axisLabel: {
                                        fontSize: '10px',
                                        fontFamily: 'Ubuntu',
                                        color: '#8b90a3',
                                        interval: 0
                                    },
                                    splitLine: {
                                        show: false
                                    }
                                },
                                yAxis: {
                                    type: 'value',
                                    boundaryGap: [0, '100%'],
                                    axisLabel: {
                                        formatter: value => formatNumberLC(value),
                                        fontSize: '10px',
                                        fontFamily: 'Ubuntu',
                                        color: '#8b90a3',
                                    },
                                    max: function (value) {
                                        // Utiliza a função calculateMaxY para definir o valor máximo de forma dinâmica
                                        const calculatedMax = calculateMaxY(value.max);
                                        return calculatedMax;
                                    }
                                },
                                series: [{
                                    data: resourceUsagePivotRecords.map(item => [item.data, item.total]),
                                    type: 'line',
                                    areaStyle: {},
                                    smooth: true
                                }],
                                grid: {
                                    height: 'fit-content',
                                    top: '16px',
                                    left: '3%',
                                    right: '3%',
                                    bottom: '5%',
                                    containLabel: true
                                },
                            }} /> :
                        <LcNoData size="small" label={resourceUsagePivotNoRecordMsg} />
                    }</LcLoading>
            </div>
        },
        {//Status do AD Connect
            id: 7,
            type: 'Custom',
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            position: { RowSpan: 2, ColSpan: 5, row: 6, col: 9 },
            title: !props.isHome ? () => <>{
                <div className="lc-title ellipsis">Status do AD Connect</div>
            } </> : undefined,
            customContentRender: () =>
                <LcLoading loading={ADConnectCountLoading}>
                    <IconValue isHome={props.isHome}
                        title={'Microsoft 365 suite'}
                        icon={getIconAndColor(100, "%", props.isHome).icon}
                        color={getIconAndColor(100, "%", props.isHome).color}
                        value={100}
                        unity="%"
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        description={props.isHome ? "AD Connect" : getADConnectReportValue()}
                        formatValue={true}
                        colorValue='#2B304F'
                    />                    
                </LcLoading>
        },
        {//alerta de fraude
            id: 8, type: 'Custom',
            bgColor: props.isHome ? '#F1F5FB' : undefined,
            hideHeader: props.isHome,
            position: { RowSpan: 2, ColSpan: 4, row: 6, col: 5 },
            infoContent: () => `Dados provenientes do serviço de detecção de eventos de fraude da Microsoft Azure`,
            infoOnTop: true,
            title: () => <>{
                <div className="lc-title ellipsis">Alertas de fraude</div>
            }</>,
            customContentRender: () => 
                <LcLoading loading={fraudEventsReportCountLoading}>
                    <IconValue 
                        isHome={props.isHome}
                        title={'Microsoft 365 suite'}
                        icon={getIconAndColor(fraudEventsReportCount ? fraudEventsReportCount : 0, "", props.isHome).icon}  // Passe `props.isHome` aqui
                        value={fraudEventsReportCount}
                        color={getIconAndColor(fraudEventsReportCount ? fraudEventsReportCount : 0, "", props.isHome).color}  // Passe `props.isHome` aqui
                        description={props.isHome ? "Alertas de Fraude" : getFraudEventReportValue()}
                        colorValue='#2B304F'
                    />
                    
                </LcLoading>
        },
        {//Usuários ativos / dia
            id: 9,
            type: 'Custom',
            ticketButtonLabel: !props.isHome ? "Ir para Atividades" : undefined,
            ticketButton: !props.isHome ? () => {
                return <PiArrowSquareOutLight onClick={() => { history.push("/SaaS/AtividadesSaaS") }} />;
            } : undefined,
            position: { ColSpan: 4, RowSpan: 4, row: 4, col: 1 },
            infoContent: () => `Informações disponíveis mais recentes sendo exibidas`,
            title: () => <>{
                <div className="lc-title ellipsis">Usuários ativos / dia</div>
            }</>
            ,
            customContentRender: () => <LcLoading loading={usersChartLoading}>
                {usersChart && usersChart.labels && usersChart.datasets ?
                    <ReactECharts
                        style={{ width: '100%', height: '100%' }}
                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                        option={{
                            grid: {
                                height: 'fit-content',
                                top: '16px',
                                left: '3%',
                                right: '3%',
                                bottom: '5%',
                                containLabel: true
                            },
                            color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],
                            tooltip: {
                                backgroundColor: '#f1f5fb',
                                borderWidth: 0,
                                textStyle: {
                                    fontSize: 11,
                                    fontFamily: 'Ubuntu',
                                    color: '#2B304F',
                                },
                                trigger: 'axis',
                                formatter: function (params) {
                                    // Adding color box using item.marker and then appending series name and value
                                    return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
                                }
                            },
                            xAxis: {
                                type: 'category',
                                data: usersChart.labels,
                                axisLabel: {
                                    fontSize: '10px',
                                    fontFamily: 'Ubuntu',
                                    color: '#8b90a3',
                                },
                            },
                            yAxis: {
                                type: 'value',
                                //interval: 150,
                                axisLabel: {
                                    fontSize: '10px',
                                    fontFamily: 'Ubuntu',
                                    color: '#8b90a3',
                                },
                            },
                            series: usersChart.datasets,
                        }}
                    />
                    :
                    <LcNoData size="small" label="Sem dados no período." />
                }
            </LcLoading>

        }
    ];

    return (<>
        {!props.isHome ?
            <Layout
                pivot={pivotlicensesFocus}
                pageTitle="Resumo"
                periodSelection={periodSelection}
            >
                <LCDashboard cards={cards} grid={[6, 12]} rightSidePanel={{
                    title: '',
                    content: () => '',
                    pinned: false,
                    show: false
                }} />
            </Layout> :
            <LCDashboard isHomeType grid={[6, 2]} cards={props.qtdGroups && props.qtdGroups > 0 ? cards.map((card, index) => {
                if (props.qtdGroups) {
                    var posi = positionHome(card.id, props.qtdGroups);
                    if (posi !== undefined) {
                        card.position = posi;
                    }
                }
                return card;
            }) : []}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: false,
                    content: () => {
                        return <div style={{ gridRowEnd: 'span 12', gridColumnEnd: 'span 12' }}>
                        </div>;
                    }
                }} />
        } </>
    );
};

export default CSPResume;
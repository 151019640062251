import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { EmailUsageUserReport, Office365ActivationReport, Office365EmailActivityUserDetail, Office365EmailActivityUserDetailReport, OneDriveUsageReport, SaasService } from '../../../services/saas/saasService';
import { Line } from "react-chartjs-2";
import styles from './index.module.css';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { MenuState } from '../../../store/reducers/menuReducer';
import { RootState } from '../../../store/index';

import { useSnackbar } from 'notistack';

//Img Fluxo
import ImgFluxo from '../../../assets/images/Fluxos/csp.png';
import history from '../../../history';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import ReactECharts from 'echarts-for-react';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import { Chart } from '../../endpointDetectionResponse/resumeEvent/Models';
import PivotTableWebix from '../../../components/webix';
import { defaultTooltipConfig } from '../../../components/Generic/eChartsConfig';

const SaaSAtividades: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const [pivotViewUsers, setPivotViewUsers] = useState<boolean>(false);
    const [pivotViewEmail, setPivotViewEmail] = useState<boolean>(false);
    const [pivotViewOneDrive, setPivotViewOneDrive] = useState<boolean>(false);
    const [pivotViewBox, setPivotViewBox] = useState<boolean>(false);

    const saasService = new SaasService(props);

    const { enqueueSnackbar } = useSnackbar();

    const [usersChart, setUsersChart] = useState<Chart>({
        labels: [],
        datasets: []
    });
    const [emailActivityChart, setEmailActivityChart] = useState<Chart>({
        labels: [],
        datasets: []
    });
    const [emailUsageChart, setEmailUsageChart] = useState<Chart>({
        labels: [],
        datasets: []
    });
    const [oneDriveUsageChart, setOneDriveUsageChart] = useState<Chart>({
        labels: [],
        datasets: []
    });

    const [userPeriod, setUserPeriod] = useState<any>(7);
    const [office365ActiveUserDetail, setOffice365ActiveUserDetail] = useState<any[]>([]);
    const [office365ActivationReport, setOffice365ActivationReport] = useState<Office365ActivationReport[]>([]);
    const [graphOffice365EmailActivityDetail, setGraphOffice365EmailActivityDetail] = useState<Office365EmailActivityUserDetailReport[]>([]);
    const [oneDriveUsageAccountDetail, setOneDriveUsageAccountDetail] = useState<OneDriveUsageReport[]>([]);
    const [mailboxUsageDetail, setMailboxUsageDetail] = useState<EmailUsageUserReport[]>([]);
    const [office365EmailActivityUserDetail, setOffice365EmailActivityUserDetail] = useState<Office365EmailActivityUserDetail[]>([]);

    const [loading, setLoading] = useState(false);
    const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
    const dispatch = useDispatch();
    let _showLastUpdate: boolean = true
    useEffect(() => {
        _showLastUpdate = false
    }, [user.ClientGroupSelected])

    useEffect(() => {
        if (user.ClientGroupSelected) {
            setLoading(true)
            Promise.all([
                getUsersdata(userPeriod),
                getOffice365ActiveUserDetail(userPeriod),
                getOffice365ActivationReport(),
                getEmailsctivityData(userPeriod),
                getGraphOffice365EmailActivityDetail(userPeriod),
                getEmailUsageData(userPeriod),
                GetMailboxUsageDetail(userPeriod),
                getOneDriveUsageAccountDetail(userPeriod),
                getOneDriveUsageAccountDetail(userPeriod),
                getOneDriveUsageData(userPeriod)
            ])
                .finally(() => setLoading(false))
        }
    }, [user.ClientGroupSelected, user.refreshFlag, userPeriod]);

    const getUsersdata = async (days) => {
        try {

            var response = (await saasService.GetUsersChart(days));
            UsersChartData(response);
        } catch (e) {

        }
    }

    const getOffice365ActiveUserDetail = async (days) => {
        var result = await saasService.GetOffice365ActiveUserDetail(days);
        console.log(result);
        setOffice365ActiveUserDetail(result)
    }

    const getOffice365ActivationReport = async () => {
        var result = await saasService.GetOffice365ActivationReport();
        setOffice365ActivationReport(result);
        // const [office365ActivationReport, setOffice365ActivationReport] = useState<Office365ActivationReport[]>([]); 
    }

    const getGraphOffice365EmailActivityDetail = async (days) => {
        var result = await saasService.GetGraphOffice365EmailActivityDetail(days);
        setGraphOffice365EmailActivityDetail(result);
        // const [office365ActivationReport, setOffice365ActivationReport] = useState<Office365ActivationReport[]>([]); 
    }
    const getOffice365EmailActivityUserDetail = async (days) => {
        var result = await saasService.GetOffice365EmailActivityUserDetailAsync(days);
        setOffice365EmailActivityUserDetail(result);
        // const [office365ActivationReport, setOffice365ActivationReport] = useState<Office365ActivationReport[]>([]); 
    }

    const getOneDriveUsageAccountDetail = async (days) => {
        var result = await saasService.GetOneDriveUsageAccountDetail(days);
        console.log(result);
        setOneDriveUsageAccountDetail(result);
        // const [office365ActivationReport, setOffice365ActivationReport] = useState<Office365ActivationReport[]>([]); 
    }
    const GetMailboxUsageDetail = async (days) => {
        var result = await saasService.GetMailboxUsageDetail(days);
        console.log(result);
        setMailboxUsageDetail(result);
        // const [office365ActivationReport, setOffice365ActivationReport] = useState<Office365ActivationReport[]>([]); 
    }

    const getEmailsctivityData = async (days) => {
        await saasService.GetEmailActivityChart(days)
            .then(response => {
                EmailActivityChartData(response);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const getEmailUsageData = async (days) => {
        await saasService.GetEmailUsageChart(days)
            .then(response => {
                EmailUsageChartData(response);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const getOneDriveUsageData = async (days) => {
        await saasService.GetOneDriveUsageChart(days)
            .then(response => {
                OneDriveUsageChartData(response);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const showLastUpdate = (max_date: any) => {
        setTimeout(() => {
            if (!_showLastUpdate) {
                enqueueSnackbar(`Última atualização do fabricante em ${max_date.date} às ${max_date.hours}`, {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                })
                _showLastUpdate = true
            }
        }, 700);
    }

    function UsersChartData(response: any) {
        if (response !== null) {
            console.log(response);
            let max_Date: any[] = []
            response.map((item: any) => {
                let date = new Date(item.reportDate)
                let d = date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                let h = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
                max_Date.push(
                    {
                        date: d,
                        hours: h
                    }
                )
            })
            setLastUpdate(new Date(response[response.length - 1].reportDate));
            showLastUpdate(max_Date[max_Date.length - 1])

            let usersChart = {
                labels: response.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: 'Office365',
                        data: response.map((Item: any) => {
                            return Item.office365
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'Exchange',
                        data: response.map((Item: any) => {
                            return Item.exchange
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'OneDrive',
                        data: response.map((Item: any) => {
                            return Item.oneDrive
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'SharePoint',
                        data: response.map((Item: any) => {
                            return Item.sharePoint
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'Yammer',
                        data: response.map((Item: any) => {
                            return Item.yammer
                        }),
                        type: 'line',
                        smooth: true,
                    },
                    {
                        name: 'Teams',
                        data: response.map((Item: any) => {
                            return Item.teams
                        }),
                        type: 'line',
                        smooth: true,
                    }
                ]
            };
            setUsersChart(usersChart);

        } else {
            let usersChart = {
                labels: [],
                datasets: []
            };
            setUsersChart(usersChart);
        }
    };

    function EmailActivityChartData(response: any) {
        if (response.data != null) {
            let emailActivityChart = {
                labels: response.data.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: "Enviado",
                        data: response.data.map((Item: any) => {
                            return Item.send
                        }),
                        type: 'line',
                        smooth: true
                    },
                    {
                        name: "Recebido",
                        data: response.data.map((Item: any) => {
                            return Item.receive
                        }),
                        type: 'line',
                        smooth: true
                    },
                    {
                        name: "Lido",
                        data: response.data.map((Item: any) => {
                            return Item.read
                        }),
                        type: 'line',
                        smooth: true
                    }
                ]
            };
            setEmailActivityChart(emailActivityChart);
        }
    };

    function EmailUsageChartData(response: any) {
        if (response.data != null) {
            let emailUsageChart = {
                labels: response.data.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: "Total",
                        data: response.data.map((Item: any) => {
                            return Item.total
                        }),
                        type: 'line',
                        smooth: true
                    },
                    {
                        name: "Ativas",
                        data: response.data.map((Item: any) => {
                            return Item.active
                        }),
                        type: 'line',
                        smooth: true
                    }
                ]
            };
            setEmailUsageChart(emailUsageChart);
        }
    };

    function OneDriveUsageChartData(response: any) {
        if (response.data != null) {
            let oneDriveUsageChart = {
                labels: response.data.map((Item: any) => {
                    let date = new Date(Item.reportDate);
                    return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
                }),
                datasets: [
                    {
                        name: "Total de contas",
                        data: response.data.map((Item: any) => {
                            return Item.total
                        }),
                        type: 'line',
                        smooth: true
                    },
                    {
                        name: "Contas ativas",
                        data: response.data.map((Item: any) => {
                            return Item.active
                        }),
                        type: 'line',
                        smooth: true
                    }
                ]
            };
            setOneDriveUsageChart(oneDriveUsageChart);
        }
    };


    //#region Verifica se há GTM CSP
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);
    useEffect(() => {
        if (menu && menu.selectedItem && menu.selectedItem.id == '67aa56e4-ad63-44b0-aa27-d2d2a8665a63') {
            saasService.GetbyClientGroupIdAndTileId('13')
                .then(response => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [menu.selectedItem])
    //#endregion

    const onChagePeriod = (selected: any) => {
        setUserPeriod(Number(selected.fields[0].value))
    };

    const periodSelection = {
        periods: [
            {
                label: 'Útimos 7 dias', text: 'Útimos 7 dias',
                fields: [
                    { name: 'last7Days', value: '7' },
                ],
                default: true
            },
            {
                label: 'Útimos 30 dias', text: 'Útimos 30 dias',
                fields: [
                    { name: 'last30Days', value: '30' }
                ],
                default: false
            },
            {
                label: 'Últimos 3 meses', text: 'Últimos 3 meses',
                fields: [
                    { name: 'last90Days', value: '90' }
                ],
                default: false
            },
            {
                label: 'Últimos 6 meses', text: 'Últimos 6 meses',
                fields: [
                    { name: 'last90Days', value: '180' }
                ],
                default: false
            },
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const cards: Card[] = [
        //Usuários ativos / dia
        {
            id: 1,
            type: 'Custom',
            showFocusButton: true,
            position: { ColSpan: 6, RowSpan: 2, row: 1, col: 1 },
            title: () => <>{
                <div className="lc-title ellipsis" title="Usuários ativos / dia">Usuários ativos / dia</div>
            }</>
            ,
            customContentRender: (focused: boolean) => {
                setPivotViewUsers(focused == true);
                if (focused) {                    
                    return <>
                        <PivotTableWebix
                            focusedStyleVision

                            idView={'dataPivotoffice365ActiveUser'}
                            key={'dataPivotoffice365ActiveUser'}
                            data={office365ActiveUserDetail}
                            style={{ marginTop: '-10px' }}
                            fields={[
                                { id: 'reportRefreshDate', value: "Data de Atualização do Relatório", type: "date", predicate: "date" },
                                { id: 'userPrincipalName', value: "Nome Principal do Usuário", type: "text" },
                                { id: 'displayName', value: "Nome de Exibição", type: "text" },
                                { id: 'isDeleted', value: "Está Deletado", type: "boolean" },
                                { id: 'deletedDate', value: "Data de Deleção", type: "date", predicate: "date" },
                                { id: 'hasExchangeLicense', value: "Exchange", type: "number" },
                                { id: 'hasOneDriveLicense', value: "OneDrive", type: "number" },
                                { id: 'hasSharePointLicense', value: "SharePoint", type: "number" },
                                { id: 'hasSkypeForBusinessLicense', value: "Skype for Business", type: "number" },
                                { id: 'hasYammerLicense', value: "Yammer", type: "number" },
                                { id: 'hasTeamsLicense', value: "Teams", type: "number" },
                                { id: 'exchangeLastActivityDate', value: "Data da Última Atividade no Exchange", type: "date", predicate: "date" },
                                { id: 'oneDriveLastActivityDate', value: "Data da Última Atividade no OneDrive", type: "date", predicate: "date" },
                                { id: 'sharePointLastActivityDate', value: "Data da Última Atividade no SharePoint", type: "date", predicate: "date" },
                                { id: 'skypeForBusinessLastActivityDate', value: "Data da Última Atividade no Skype for Business", type: "date", predicate: "date" },
                                { id: 'yammerLastActivityDate', value: "Data da Última Atividade no Yammer", type: "date", predicate: "date" },
                                { id: 'teamsLastActivityDate', value: "Data da Última Atividade no Teams", type: "date", predicate: "date" },
                                { id: 'exchangeLicenseAssignDate', value: "Data de Atribuição da Licença Exchange", type: "date", predicate: "date" },
                                { id: 'oneDriveLicenseAssignDate', value: "Data de Atribuição da Licença OneDrive", type: "date", predicate: "date" },
                                { id: 'sharePointLicenseAssignDate', value: "Data de Atribuição da Licença SharePoint", type: "date" },
                                { id: 'skypeForBusinessLicenseAssignDate', value: "Data de Atribuição da Licença Skype for Business", type: "date", predicate: "date" },
                                { id: 'yammerLicenseAssignDate', value: "Data de Atribuição da Licença Yammer", type: "date", predicate: "date" },
                                { id: 'teamsLicenseAssignDate', value: "Data de Atribuição da Licença Teams", type: "date", predicate: "date" },
                                { id: 'assignedProducts', value: "Produtos Atribuídos", type: "text" }
                            ]}
                            structure={{
                                rows: ["displayName", "userPrincipalName"],
                                values: [
                                    { name: "hasTeamsLicense", operation: ["sum"] },
                                    { name: "hasExchangeLicense", operation: ["sum"] },
                                    { name: "hasOneDriveLicense", operation: ["sum"] },
                                    { name: "hasSharePointLicense", operation: ["sum"] },
                                    { name: "hasYammerLicense", operation: ["sum"] }
                                ]
                            }}
                        />
                    </>
                } else {
                    setPivotViewUsers(false);
                    return <LcLoading loading={loading}>
                        {usersChart.labels.length > 0 && usersChart.datasets.length > 0 ?
                            <ReactECharts
                                className="chart-medium"
                                style={{ width: '100%', height: '100%' }}
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                option={{
                                    grid: {
                                        top: '5%',
                                        left: '2%',
                                        right: '2%',
                                        bottom: '6%',
                                        containLabel: true
                                    },
                                    color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],

                                    tooltip: {
                                        ...defaultTooltipConfig,
                                        trigger: 'axis',
                                        formatter: function (params) {
                                            // Adding color box using item.marker and then appending series name and value
                                            return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
                                        }
                                    },
                                    xAxis: {
                                        type: 'category',
                                        data: usersChart.labels,
                                        axisLabel: {
                                            fontSize: '10px',
                                            fontFamily: 'Ubuntu',
                                            color: '#8b90a3',
                                        },
                                    },
                                    yAxis: {
                                        type: 'value',
                                        //interval: 150,
                                        axisLabel: {
                                            fontSize: '10px',
                                            fontFamily: 'Ubuntu',
                                            color: '#8b90a3',
                                        },
                                    },
                                    series: usersChart.datasets,
                                }}
                            />
                            :
                            <LcNoData size="small" label="Sem dados no período." />
                        }
                    </LcLoading>
                }
            }
        },
        //Atividades de e-mail
        {
            id: 10, type: 'Custom',
            showFocusButton: true,
            position: {
                ColSpan: 6, RowSpan: 2, row: 1, col: 7
            },
            title: () => <>{
                <div className="lc-title ellipsis" title="Atividades de e-mail">Atividades de e-mail</div>
            }</>
            , customContentRender: (focused: boolean) => {
                setPivotViewEmail(focused == true);
                if (focused) {
                    return <> <PivotTableWebix
                        focusedStyleVision

                        idView={'dataPivotgraphOffice365EmailActivityDetail'}
                        key={'dataPivotgraphOffice365EmailActivityDetail'}
                        data={graphOffice365EmailActivityDetail}
                        style={{ marginTop: '-10px' }}
                        fields={[
                            { id: 'reportRefreshDate', value: "Data de Atualização do Relatório", type: "date", predicate: "date" },
                            { id: 'userPrincipalName', value: "Nome Principal do Usuário", type: "text" },
                            { id: 'displayName', value: "Nome de Exibição", type: "text" },
                            { id: 'isDeleted', value: "Está Deletado", type: "boolean" },
                            { id: 'deletedDate', value: "Data de Deleção", type: "date", predicate: "date" },
                            { id: 'lastActivityDate', value: "Data da Última Atividade", type: "date", predicate: "date" },
                            { id: 'sendCount', value: "Contagem de Envio", type: "number" },
                            { id: 'receiveCount', value: "Contagem de Recebimento", type: "number" },
                            { id: 'readCount', value: "Contagem de Leitura", type: "number" },
                            { id: 'meetingCreatedCount', value: "Contagem de Reuniões Criadas", type: "number" },
                            { id: 'meetingInteractedCount', value: "Contagem de Interações em Reuniões", type: "number" },
                            { id: 'assignedProducts', value: "Produtos Atribuídos", type: "text" },
                            { id: 'reportPeriod', value: "Período do Relatório", type: "number" }
                        ]}
                        structure={{
                            rows: ["displayName", "userPrincipalName"],
                            values: [
                                { name: "sendCount", operation: ["sum"] },
                                { name: "receiveCount", operation: ["sum"] },
                                { name: "readCount", operation: ["sum"] },
                                { name: "meetingCreatedCount", operation: ["sum"] },
                                { name: "meetingInteractedCount", operation: ["sum"] }
                            ]
                        }}
                    />  </>
                } else {
                    setPivotViewEmail(false);
                    return <LcLoading loading={loading}>
                        {emailActivityChart && emailActivityChart.labels && emailActivityChart.datasets ?
                            <ReactECharts
                                className="chart-medium"
                                style={{ width: '100%', height: '100%' }}
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                option={{
                                    grid: {
                                        top: '10%',
                                        left: '2%',
                                        right: '2%',
                                        bottom: '0%',
                                        containLabel: true
                                    },
                                    color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],
                                    tooltip: {
                                        backgroundColor: '#f1f5fb',
                                        borderWidth: 0,
                                        textStyle: {
                                            fontSize: 11,
                                            fontFamily: 'Ubuntu',
                                            color: '#2B304F',
                                        },
                                        trigger: 'axis',
                                        formatter: function (params) {
                                            // Adding color box using item.marker and then appending series name and value
                                            return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
                                        }
                                    },
                                    xAxis: {
                                        type: 'category',
                                        boundaryGap: false,
                                        data: emailActivityChart.labels,
                                        axisLabel: {
                                            fontSize: '10px',
                                            fontFamily: 'Ubuntu',
                                            color: '#8b90a3',
                                        },
                                    },
                                    yAxis: {
                                        type: 'value',
                                        scale: true,
                                        splitNumber: 5,
                                        axisLabel: {
                                            formatter: '{value}',
                                            fontSize: '10px',
                                            fontFamily: 'Ubuntu',
                                            color: '#8b90a3',
                                        },
                                        //interval: 35000
                                    },
                                    series: emailActivityChart.datasets,
                                }} />
                            :
                            <LcNoData size="small" label="Sem dados no período." />
                        }
                    </LcLoading>
                }
            }
        },
        //OneDrive ativas / uso
        {
            id: 11, type: 'Custom',
            showFocusButton: true,
            position: {
                ColSpan: 6, RowSpan: 2, row: 3, col: 1
            },
            title: () => <>{
                <div className="lc-title ellipsis" title="OneDrive ativas / uso">OneDrive ativas / uso</div>
            }</>

            , customContentRender: (focused: boolean) => {
                setPivotViewOneDrive(focused == true);
                if (focused) {
                    return <> <PivotTableWebix
                        focusedStyleVision

                        idView={'dataPivotoneDriveUsageAccountDetail'}
                        key={'dataPivotoneDriveUsageAccountDetail'}
                        data={oneDriveUsageAccountDetail}
                        style={{ marginTop: '-10px' }}
                        fields={[
                            { id: 'reportRefreshDate', value: "Data de Atualização do Relatório", type: "date", predicate: "date" },
                            { id: 'siteId', value: "ID do Site", type: "text" },
                            { id: 'siteURL', value: "URL do Site", type: "text" },
                            { id: 'ownerDisplayName', value: "Nome de Exibição do Proprietário", type: "text" },
                            { id: 'isDeleted', value: "Está Deletado", type: "boolean" },
                            { id: 'lastActivityDate', value: "Data da Última Atividade", type: "date", predicate: "date" },
                            { id: 'fileCount', value: "Arquivos", type: "number" },
                            { id: 'activeFileCount', value: "Arquivos_Ativos", type: "number" },
                            { id: 'storageUsed', value: "BytesUsados", type: "number" },
                            { id: 'storageAllocated', value: "BytesAlocados", type: "number" },
                            { id: 'ownerPrincipalName', value: "Nome Principal do Proprietário", type: "text" }
                        ]}
                        structure={{
                            rows: ["ownerDisplayName", "ownerPrincipalName"],
                            values: [
                                { name: "fileCount", operation: ["sum"] },
                                { name: "activeFileCount", operation: ["sum"] },
                                { name: "storageUsed", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "storageAllocated", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                            ]
                        }}
                    /> </>
                } else {
                    setPivotViewOneDrive(false);                    
                    return <LcLoading loading={loading}>
                        {oneDriveUsageChart && oneDriveUsageChart.labels && oneDriveUsageChart.datasets ?
                            <ReactECharts
                                className="chart-medium"
                                style={{ width: '100%', height: '100%' }}
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                option={{
                                    grid: {
                                        height: 'fit-content',
                                        top: '5%',
                                        left: '3%',
                                        right: '3%',
                                        bottom: '0%',
                                        containLabel: true
                                    },
                                    color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],
                                    tooltip: {
                                        backgroundColor: '#f1f5fb',
                                        borderWidth: 0,
                                        textStyle: {
                                            fontSize: 11,
                                            fontFamily: 'Ubuntu',
                                            color: '#2B304F',
                                        },
                                        trigger: 'axis',
                                        formatter: function (params) {
                                            // Adding color box using item.marker and then appending series name and value
                                            return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
                                        }
                                    },
                                    xAxis: {
                                        type: 'category',
                                        boundaryGap: false,
                                        data: oneDriveUsageChart.labels,
                                        axisLabel: {
                                            fontSize: '10px',
                                            fontFamily: 'Ubuntu',
                                            color: '#8b90a3',
                                        },
                                    },
                                    yAxis: {
                                        type: 'value',
                                        axisLabel: {
                                            formatter: '{value}',
                                            fontSize: '10px',
                                            fontFamily: 'Ubuntu',
                                            color: '#8b90a3',
                                        },
                                        //interval: 150
                                    },
                                    series: oneDriveUsageChart.datasets,
                                }} />
                            :
                            <LcNoData size="small" label="Sem dados no período." />
                        }
                    </LcLoading>
                }
            }

        },
        //Caixas - ativas / uso
        {
            id: 12,
            type: 'Custom',
            showFocusButton: true,
            position: {
                ColSpan: 6, RowSpan: 2, row: 3, col: 7
            },
            title: () => <>{
                <div className="lc-title ellipsis" title="Caixas - ativas / uso">Caixas - ativas / uso</div>
            }</>
            , customContentRender: (focused: boolean) => {
                setPivotViewBox(focused == true);
                if (focused) {
                    return <> <PivotTableWebix
                        focusedStyleVision

                        idView={'dataPivotoffice365ActiveUserDetailmailboxUsageDetail'}
                        key={'dataPivotoffice365ActiveUserDetailmailboxUsageDetail'}
                        data={mailboxUsageDetail} // Add your data here
                        style={{ marginTop: '-10px' }}
                        fields={[
                            { id: 'reportRefreshDate', value: "Dt de Atualização do Relatório", type: "date", predicate: "date" },
                            { id: 'userPrincipalName', value: "Nome Principal do Usuário", type: "text" },
                            { id: 'displayName', value: "Nome de Exibição", type: "text" },
                            { id: 'isDeleted', value: "Está Deletado", type: "boolean" },
                            { id: 'deletedDate', value: "Dt de Exclusão", type: "date", predicate: "date" },
                            { id: 'createdDate', value: "Dt de Criação", type: "date", predicate: "date" },
                            { id: 'lastActivityDate', value: "Dt da Atividade", type: "date", predicate: "date" },
                            { id: 'itemCount', value: "Contagem_de_Itens", type: "number" },
                            { id: 'storageUsed', value: "Utilizado_(Bytes)", type: "number" },
                            { id: 'issueWarningQuota', value: "Quota_de_Aviso(Bytes)", type: "number" },
                            { id: 'prohibitSendQuota', value: "Quota_de_Proibição(Bytes)", type: "number" },
                            { id: 'prohibitSendReceiveQuota', value: "Quota_de_Proibição de Envio/Recebimento (Bytes)", type: "number" },
                            { id: 'deletedItemCount', value: "Qtd_de_Itens_Deletados", type: "number" },
                            { id: 'deletedItemSize', value: "Tamanho_Deletados(Bytes)", type: "number" },
                            { id: 'deletedItemQuota', value: "Quota_de_Itens_Deletados(Bytes)", type: "number" },
                            { id: 'hasArchive', value: "Possui_Arquivo", type: "boolean" }
                        ]}
                        structure={{
                            rows: ["displayName", "userPrincipalName"], // Adjust to the properties you want to aggregate by
                            values: [
                                { name: "itemCount", operation: ["sum"] },
                                { name: "storageUsed", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "issueWarningQuota", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "prohibitSendQuota", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "prohibitSendReceiveQuota", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "deletedItemCount", operation: ["sum"] },
                                { name: "deletedItemSize", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                                { name: "deletedItemQuota", operation: ["sum"], format: value => (value / (1024 ** 3)).toFixed(2) + " GB" },
                            ],
                        }}
                    /> </>
                } else {
                    setPivotViewBox(false);
                    return <LcLoading loading={loading}>
                        {emailUsageChart && emailUsageChart.labels && emailUsageChart.datasets ?
                            <ReactECharts
                                className="chart-medium"
                                style={{ width: '100%', height: '100%' }}
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                option={{
                                    grid: {
                                        height: 'fit-content',
                                        top: '5%',
                                        left: '2%',
                                        right: '2%',
                                        bottom: '0%',
                                        containLabel: true
                                    },
                                    color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],
                                    tooltip: {
                                        backgroundColor: '#f1f5fb',
                                        borderWidth: 0,
                                        textStyle: {
                                            fontSize: 11,
                                            fontFamily: 'Ubuntu',
                                            color: '#2B304F',
                                        },
                                        trigger: 'axis',
                                        formatter: function (params) {
                                            // Adding color box using item.marker and then appending series name and value
                                            return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
                                        }
                                    },
                                    xAxis: {
                                        type: 'category',
                                        boundaryGap: false,
                                        data: emailUsageChart.labels,
                                        axisLabel: {
                                            fontSize: '10px',
                                            fontFamily: 'Ubuntu',
                                            color: '#8b90a3',
                                        },
                                    },
                                    yAxis: {
                                        type: 'value',
                                        axisLabel: {
                                            formatter: '{value}',
                                            fontSize: '10px',
                                            fontFamily: 'Ubuntu',
                                            color: '#8b90a3',
                                        },
                                        //interval: 150                                
                                    },
                                    series: emailUsageChart.datasets,
                                }} />
                            :
                            <LcNoData size="small" label="Sem dados no período." />
                        }
                    </LcLoading>
                }
            }
        }
    ]

    return (
        <Layout pivot={pivotViewUsers || pivotViewEmail || pivotViewOneDrive || pivotViewBox} pageTitle="Atividades" lastUpdate={lastUpdate} periodSelection={periodSelection}>
            {
                showFluxoCSP ?
                    <div className="lc-segment">
                        <div className="body">
                            Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                            fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                            <img src={ImgFluxo} width="100%" alt="Procedimento" />
                        </div>
                    </div>
                    :
                    <LCDashboard cards={cards} />
            }
        </Layout>
    );
};

export default SaaSAtividades;
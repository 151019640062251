import React, { useState, useEffect,  useRef } from 'react';
import { Box, Typography, List, ListItem, Paper } from '@mui/material';
import ThreadService from '../../../services/AIx/threads/ThreadService';
import MessageService from '../../../services/AIx/messages/MessageService';
import { Thread, MessageResponse } from '../../../services/AIx/assistantsTypes';
import './index.css';
import LcLoading from '../../../components/Generic/LcLoading';
// import Base64ImageGallery from './Base64ImageGallery';
// import { TbArrowBigUpLines } from 'react-icons/tb';
import { Markdown } from 'react-showdown';
import moment from 'moment';
import { BsCopy } from 'react-icons/bs';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { useSnackbar } from 'notistack';
import ExpandingTextarea from '../../LiveWatch/Insights/ExpandingTextArea';
import { useEventSource } from './Hooks/UseEventSource';

interface ThreadDialogProps {
    handleClose: () => void;
    assistantId: string;
    assistantName: string;
}

export const ThreadDialog: React.FC<ThreadDialogProps> = ({ handleClose, assistantId, assistantName }) => {
    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<MessageResponse[]>([]);
    const [threadId, setThreadId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingStream, setLoadingStream] = useState<boolean>(true);
    const [runRequest, setRunRequest] = useState<number>(0);
    const [messagesMap, setMessagesMap] = useState<{ [id: string]: MessageResponse }>({});

    const { enqueueSnackbar } = useSnackbar();
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    useEventSource(assistantId, threadId,runRequest, messagesMap, setMessages, setLoadingStream, setLoading);
    
    useEffect(() => {
        const scrollToBottom = () => {
            if (messagesEndRef.current) {
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        };
        scrollToBottom();
    },[messages, message]);


    useEffect(() => {
        const createOrFetchThread = async () => {
            setLoading(true);
            if (!assistantId) return;
            const threadService = new ThreadService({});

            try {
                const storedThreadId = localStorage.getItem(`threadId_${assistantId}`);
                let threadIdToUse = storedThreadId;
                if (!threadIdToUse) {
                    const newThread = {} as Thread;
                    const createdThread = await threadService.createThread(newThread);
                    if (createdThread && createdThread.id) {
                        setThreadId(createdThread.id);
                        threadIdToUse = createdThread.id;
                        localStorage.setItem(`threadId_${assistantId}`, createdThread.id);
                    }
                } else {
                    setThreadId(threadIdToUse);
                }

                if (threadIdToUse) {
                    setRunRequest(0);
                    const messageService = new MessageService({});
                    const allMessages = await messageService.listMessagesAIX(threadIdToUse);
                    if (allMessages) {
                        setMessagesMap(allMessages.reduce(
                            (map, message) => {
                                map[message.id] = message;
                                return map;
                            },
                            {} as { [id: string]: MessageResponse }
                        ))
                        setMessages(allMessages);
                    }
                }
            } catch (error) {
                console.error('Error creating or fetching thread:', error);
            } finally {
                setRunRequest(Math.random());
            }
        };
        if (assistantId) {
            createOrFetchThread();
        }
    }, [assistantId]);

    const handleSendMessage = async () => {
        if (!assistantId || !message.trim() || !threadId) return;
        setLoadingStream(true);
        try {
            const newMessage: MessageResponse = {
                id: `msg_${Date.now()}`,
                object: 'message',
                created_at: Math.floor(Date.now() / 1000),
                assistant_id: assistantId,
                thread_id: threadId,
                run_id: `run_${Date.now()}`,
                role: 'user',
                content: [{ type: 'text', text: { value: message, annotations: [] } }],
                attachments: [],
                metadata: {},
            };
            messagesMap[newMessage.id] = newMessage;
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            const messageService = new MessageService({});
            await messageService.createChat(threadId, assistantId, {
                role: 'user',
                content: message,
            });
            setMessage(''); // Isso deve limpar o textarea
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setRunRequest(Math.random());
        }
    };


    const removeFileCitations = (text: string, annotations: any[]) => {
        let cleanedText = text;
        annotations.forEach((annotation) => {
            if (annotation.type === 'file_citation') {
                cleanedText = cleanedText.replace(annotation.text, '');
            }
        });
        return cleanedText;
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => enqueueSnackbar(`Texto copiado com sucesso!`, {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            }),
            (err) => console.error('Falha ao copiar o texto: ', err)
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '90%' }}>
            <LcLoading loading={loading} >
                <Box className="chat-container" sx={{ minHeight: 'calc(100vh - 300px)', overflowY: 'auto', mb: 2 }}>
                    { messages && messages.length > 0 ? (
                    <List className="message-list scrollable-v">
                        {
                            messages.map((msg, index) => (
                            <ListItem key={msg.id} disableGutters className={`message-item ${msg.role}`}>
                                <Paper  elevation={msg.role === 'user' ? 2 : 1}
                                className={`message-paper ${msg.role}`} >
                                {msg.role !== 'user' && (
                                    <Box className="message-header">
                                        <Typography variant="subtitle2" className="assistant-name">
                                            {assistantName || 'Assistant'}
                                        </Typography>
                                        <div className="message-header-right">
                                            <Typography variant="caption" className="message-timestamp">
                                                {msg.created_at && moment(new Date(msg.created_at * 1000)).format("DD/MM/yy HH:mm")}
                                            </Typography>
                                            <LcIconLink
                                                onClick={() =>
                                                    copyToClipboard(
                                                        msg.content
                                                            .map((c) => c.text && c.text.value)
                                                            .join('')
                                                    )
                                                }
                                                size="small"
                                                className="copy-icon"
                                                tooltip='Copiar texto'
                                                tooltipPosition='inline-left'
                                                icon={<BsCopy fontSize="small" />}
                                            >

                                            </LcIconLink>
                                        </div>
                                    </Box>
                                )}
                                <Typography 
                                    key={index}
                                    component="div"
                                    variant="body1" 
                                    className="message-content" 
                                    style={
                                            {
                                                justifyContent: msg.role !== 'user' ? 'flex-start' : 'flex-end',
                                                marginRight: msg.role !== 'user' ? '5px' : '0px'
                                            }
                                        }
                                    >
                                    <Markdown
                                        markdown={
                                            msg.content &&
                                            msg.content
                                                .map((c) => {
                                                    const textValue = c.text?.value || '';
                                                    return c.text?.annotations
                                                        ? removeFileCitations(textValue, c.text.annotations)
                                                        : textValue;
                                                })
                                                .join('')
                                        }
                                    />
                                </Typography>
                            </Paper>
                        </ListItem>
                        ))
                    }
                    <Box height={'10%'}>
                        <LcLoading loading={loadingStream} />
                    </Box>
                    <div ref={messagesEndRef} />
                    </List>
                    ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography variant="body2" color="textSecondary">Nenhuma mensagem.</Typography>
                    </Box>
                    )}
                </Box>
            </LcLoading>
            <Box className="textarea-container-parent">
                    <ExpandingTextarea
                        value={message}
                        setValue={setMessage}
                        onSend={handleSendMessage}
                        ref={textareaRef}
                    />
            </Box>
        </Box>
    );
};


import React, { useEffect, useState } from 'react';

import './index.css';

import ReleaseNotesService from '../../services/releaseNotesService';

import Layout from '../../components/Layout/Layout';
import LCFlexContainer from '../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../components/Layout/LCFlexItem';
import LcLoading from '../../components/Generic/LcLoading';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import MarkdownView from 'react-showdown';
// import ReactMarkdown from 'react-markdown'

const ReleaseNotes: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);

    const onChagePeriod = (selected: any) => {
        let value = selected.fields[0].value;
        setVersion(value);
    };

    const [IsLoading, setIsLoading] = useState<boolean>(false);

    let startVersion = (sessionStorage.getItem('release_notes_version') || '').length > 0 ? sessionStorage.getItem('release_notes_version') : process.env.REACT_APP_LAST_VERSION;
    sessionStorage.removeItem('release_notes_version');
    const [version, setVersion] = useState<any>(startVersion);

    const [wikiSubPages, setWikiSubPages] = useState<any>();
    const versionSelectStart = { label: version, text: version, fields: [{ name: 'text', value: version }], default: true }
    const [versionSelect, setVersionSelect] = useState<{ periods: any[], customPeriod: any, customPeriodType: string, onChange: Function }>({ periods: [versionSelectStart], customPeriod: null, customPeriodType: '', onChange: onChagePeriod })
    const [wikiContentPage, setWikiContentPage] = useState<any>({ content: null });


    useEffect(() => {
        const service: ReleaseNotesService = new ReleaseNotesService({});
        setIsLoading(true);
        service.GetRelesaseNotesReading(version).then((response: any[]) => {
            if (response?.length === 0) {
                service.Create(version);
            }
        });
        if (versionSelect.periods.length === 1) {
            service.GetAllWikiSubPages().then((response) => {
                let localVersionSelect: { periods: any[], customPeriod: any, customPeriodType: string, onChange: Function } = {
                    periods: [],
                    customPeriod: null,
                    customPeriodType: '',
                    onChange: onChagePeriod
                };
                let wikisubpages = response.map((item: any, index: number) => {
                    let label = item.path.replace('/LiveCloud/Release Notes/Produção/', '');
                    localVersionSelect.periods.push({
                        label: label,
                        text: label,
                        fields: [
                            { name: 'text', value: label }
                        ],
                        default: index === 0
                    });
                    return label
                });
                setVersionSelect(localVersionSelect);
                setWikiSubPages(wikisubpages);
            });
        }
        service.GetWikiContentPage(version).then((response) => {
            setWikiContentPage(response);
        }).catch(error => {
            console.error(error)
            throw error;
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, [ user.ClientGroupSelected, version, versionSelect.periods.length]);

    return (
        <Layout pageTitle="Notas da versão" periodSelection={versionSelect}>
            <LCFlexContainer spacing={2}>
                <LCFlexItem xs={12} sm={12} md={12} lg={12}>
                    <div className="cardRow">
                        <LcLoading label="Carregando notas da versão..." loading={IsLoading}>
                            <MarkdownView
                                markdown={wikiContentPage?.content}
                                options={{ tables: true, emoji: true }}
                            />
                            {/* <ReactMarkdown components={{br: 'br'}}>{wikiContentPage.content}</ReactMarkdown> */}
                            {/* <div dangerouslySetInnerHTML={{ __html: wikiContentPage.content }}></div> */}
                        </LcLoading>
                    </div>
                </LCFlexItem>
            </LCFlexContainer>
        </Layout>
    );
}

export default ReleaseNotes;
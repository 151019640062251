import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as webix from '@xbs/webix-pro';
import * as pivotLib from '@xbs/pivot';
import "@xbs/webix-pro/skins/mini.min.css";
import "@xbs/pivot/codebase/skins/mini.min.css";
import { usePivotFunctions } from '../../contexts/PivotContext';
import "./index.css"
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { AnalyticalCostCenterState } from '../../store/reducers/analyticalReducer';
import { PivotViewPanel } from './PivotViewPanel/PivotViewPanel';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
window.webix = webix;

webix.i18n.setLocale("pt-BR");
const customDateFormat = (dateString) => {
    const date = new Date(dateString);
    const specificDate = new Date("31 Dec 1969 00:00:00 GMT");
    // Check for the specific date
    if ( date.getFullYear() === specificDate.getFullYear() && date.getMonth() === specificDate.getMonth()) {
        return "-"; // Return empty string if the date matches
    }
    return webix.Date.dateToStr("%d/%m/%Y")(date);
};
export interface PivotTableProps {
    showAllNumbersAsInteger?: boolean;
    maxY?: boolean;
    minY?: boolean;
    maxX?: boolean;
    minX?: boolean;
    data: any[];
    fields?: any[];
    chart?: { [key: string]: any };
    structure?: any;

    idView?: string;
    mode?: string;
    pivoting?: boolean;
    style?: React.CSSProperties;
    footer?: boolean;
    classTable?: string
    setSelectedView?: (selected: boolean) => void
    styleVision?: CSSProperties
    focusedStyleVision?: boolean;
}
type MultiTypeBackEnd = pivotLib.services.Backend | pivotLib.services.LocalData;


function isValidStructure(structure: any, availableFields: any[]): boolean {
    const allFields = [...structure.columns, ...structure.rows];
    return allFields.every(field => availableFields.map(f => f.id).includes(field));
}

function isValidFilter(filter, data) {
    // Se o filtro não tiver um valor definido, consideramos válido
    if (!filter.value || !filter.value.includes) return true;

    // Verifique se pelo menos um dos valores filtrados ainda existe nos dados
    for (let value of filter.value.includes) {
        if (data.some(item => item[filter.name] === value)) {
            return true;
        }
    }
    return false;
}

const PivotTableWebix = (props: PivotTableProps) => {


    //ordenar campos disponíveis na pivot
    const orderedFields = props.fields
        ? [...props.fields].sort((a, b) => a.value.localeCompare(b.value))
        : [];

    const pivotId = `pivot-${Math.random().toString(36).substring(2, 9)}-${props.idView}`;
    const pivotContainerId = `pivot-container-${Math.random().toString(36).substring(2, 9)}-${props.idView}`;
    const colsId = `cols-${Math.random().toString(36).substring(2, 9)}`;
    const rowsId = `rows-${Math.random().toString(36).substring(2, 9)}`;
    const newrowsId = `newrows-${Math.random().toString(36).substring(2, 9)}`;
    const settingsId = `settings-${Math.random().toString(36).substring(2, 9)}`;
    const fieldsId = `fields-${Math.random().toString(36).substring(2, 9)}`;
    const toggleId = `toggle-${Math.random().toString(36).substring(2, 9)}`;
    const headerContextMenuId = `headerContextMenu-${Math.random().toString(36).substring(2, 9)}`;

    const { data } = props;
    const pivotRef = useRef<any>(props.idView);
    const { setOpenPivotConfiguration, setCustomPivotConfiguration, setCSVPivotConfiguration, setOpenExternalSettings, setOpenTable, setOpenTree, setOpenChart } = usePivotFunctions();
    const analytical = useSelector<RootState, AnalyticalCostCenterState>(state => state.analytical);

    const dispatch = useDispatch();
    // Código de tradução
    const ptLocale = {
        //general
        Done: "Concluído",
        Table: "Tabela",
        Tree: "Árvore",
        Chart: "Gráfico",
        "Click to configure": "Clique para configurar",
        "Configure Pivot": "Configurar Pivot",
        Total: "Total",
        //settings
        Fields: "Campos",
        Methods: "Métodos",
        Columns: "Colunas",
        "Add column": "Adicionar coluna",
        Rows: "Linhas",
        "Add row": "Adicionar linha",
        "Clean rows": "Limpar linhas",
        Filters: "Filtros",
        "Add filter": "Adicionar filtro",
        "Group By": "Agrupar Por",
        "Chart type": "Tipo de gráfico",
        "Logarithmic scale": "Escala logarítmica",
        "X axis title": "Título do eixo X",
        "Y axis title": "Título do eixo Y",
        "Scale color": "Cor da escala",
        "Circled lines": "Linhas circulares",
        Horizontal: "Horizontal",
        Stacked: "Empilhado",
        Lines: "Linhas",
        "Y axis lines": "Linhas do eixo Y",
        "X axis lines": "Linhas do eixo X",
        Line: "Linha",
        Radar: "Radar",
        Bar: "Barra",
        Area: "Área",
        Spline: "Spline",
        "Spline Area": "Área Spline",
        Pie: "Torta",
        Donut: "Donut",
        Scatter: "Dispersão",
        Values: "Valores",
        "Add value": "Adicionar valor",
        "Field not defined": "Campo não definido",
        Highlight: "Destaque",
        "Min X": "Min X",
        "Max X": "Max X",
        "Min Y": "Min Y",
        "Max Y": "Max Y",
        Footer: "Rodapé",
        "Total Column": "Coluna Total",
        Off: "Desligado",
        On: "Ligado",
        "Sum Only": "Somente Soma",
        "3D": "3D",
        "Legend align": "Alinhar legenda",
        Labels: "Rótulos",
        Tooltips: "Dicas",
        Markers: "Marcadores",
        Square: "Quadrado",
        Triangle: "Triângulo",
        Diamond: "Diamante",
        Round: "Redondo",
        Left: "Esquerda",
        Center: "Centro",
        Right: "Direita",
        Top: "Topo",
        Middle: "Meio",
        Bottom: "Fundo",
        "Fill area": "Preencher área",
        //operations
        count: "contagem",
        max: "máximo",
        min: "mínimo",
        avg: "média",
        wavg: "média ponderada",
        any: "qualquer",
        sum: "soma",
        complex: "complexo",
        "Incorrect formula in values": "Fórmula incorreta em valores",
    };

    const clientGroupSelected = sessionStorage.getItem('ClientGroupSelected');
    const location = useLocation();
    const currentPath = location.pathname;
    const [pivotStructure, setPivotStructure] = useState<any>(props.structure);
    const [refreshPivot, setRefreshPivot] = useState(0);
    const [showPivotViewPanel, setShowPivotViewPanel] = useState<boolean>(false);

    const savePivotConfig = useCallback((structure: any) => {
        localStorage.setItem(`pivotConfig-${currentPath}-${clientGroupSelected}-${props.idView}`, JSON.stringify(structure));
    },[clientGroupSelected, currentPath, props.idView])

    function loadPivotConfig(): any {
        const storedConfig = localStorage.getItem(`pivotConfig-${currentPath}-${clientGroupSelected}-${props.idView}`);
        return storedConfig ? JSON.parse(storedConfig) : null;
    }


    const updateStructure = useCallback((name: string, value: string, abbreviation: string, initialStructure: any) => {
        let newStructureFilter :any[] = [];
        let newStructure ={};
        if(analytical.typeParam === 'costcenter_budget') {
            newStructureFilter = analytical.hasConstCenterChildren ? [
                { name: abbreviation, value: { includes: value === '' ? null : [value] } },
                { name: 'Cdc', value: null },
                ...initialStructure.filters.filter(item => item.name !== 'parentCostCenter' && item.name !== 'Cdc'),
            ]
            :
            [
                { name: abbreviation, value: { includes: [value] } },
                { name: 'parentCostCenter', value: null},
                ...initialStructure.filters.filter(item => item.name !== 'parentCostCenter' && item.name !== 'Cdc'),
            ];
            let newValues = [{name:"Vbr", operation:"sum"}, {name: 'Bdg', operation:"max"} ]
            let newRows =  analytical.hasConstCenterChildren ? ['parentCostCenter', 'Cdc'] : ['Cdc']
            newStructure = {
                rows: newRows,
                columns: initialStructure.columns,
                filters: newStructureFilter,
                groupBy: initialStructure.groupBy,
                values: newValues
            };
    
            savePivotConfig(newStructure);
            setPivotStructure(newStructure);
            dispatch({ type: 'RESET_ANALYTICAL' });
            
        }else {
            newStructureFilter = [ 
                { name: abbreviation, value: { includes: [value] } },
                ...initialStructure.filters.filter(item => item.name !== abbreviation),
            ]
            let newValues = initialStructure.values;
            let newRows =  [abbreviation, ...initialStructure.rows.filter(item => item !== abbreviation)];
    
            newStructure = {
                rows: newRows,
                columns: initialStructure.columns,
                filters: newStructureFilter,
                groupBy: initialStructure.groupBy,
                values: newValues
            };
    
            savePivotConfig(newStructure);
            setPivotStructure(newStructure);
            dispatch({ type: 'RESET_ANALYTICAL' });

        }
        

        return newStructure;
    },[analytical, dispatch, savePivotConfig]);

    // function exportToExcel() {
    //     webix.toExcel(webix.$$(pivotId));
    // }

    useEffect(() => {
        if (pivotRef.current) {

            pivotRef.current.innerHTML = ''; // Limpa o contêiner

            let savedConfig = loadPivotConfig();

            // Validação da estrutura de campos
            if (savedConfig && !isValidStructure(savedConfig, orderedFields)) {
                console.warn("Estrutura salva no localStorage é inválida. Usando estrutura padrão.");
                savedConfig = null;
            }

            // Validação dos filtros
            if (savedConfig && savedConfig.filters) {
                savedConfig.filters = savedConfig.filters.filter(filter => isValidFilter(filter, data));
            }
            let initialStructure: any = savedConfig || pivotStructure;

            if (analytical.resource && analytical.resource !== '') {
                initialStructure = updateStructure("Rec", analytical.resource, "Rec", initialStructure);
            }

            if (analytical.service && analytical.service !== '') {
                initialStructure = updateStructure("Srv", analytical.service, "Srv", initialStructure);
            }

            if (analytical.region && analytical.region !== '') {
                initialStructure = updateStructure("Rgn", analytical.region, "Rgn", initialStructure);
            }

            if (analytical.account && analytical.account !== '') {
                initialStructure = updateStructure("Acn", analytical.account, "Acn", initialStructure);
            }

            if (analytical.costcenter && analytical.costcenter !== '' && analytical.typeParam === '' )  {
                initialStructure = updateStructure("Cdc", analytical.costcenter, "Cdc", initialStructure);
            }
            if (analytical.costcenter && analytical.costcenter !== '' && analytical.service && analytical.service !== '' && analytical.typeParam === 'service_costcenter') {
                initialStructure = updateStructure("Cdc", analytical.costcenter, "Cdc", initialStructure);
                initialStructure = updateStructure("Srv", analytical.service, "Srv", initialStructure);

            }
            if(analytical.costcenter!== null && analytical.costcenter !== undefined  && analytical.typeParam === 'costcenter_budget'){
                initialStructure = updateStructure("Cdc", analytical.costcenter, analytical.hasConstCenterChildren ? 'parentCostCenter': "Cdc", initialStructure);
            }
            webix.ready(() => {

                //require("@xbs/pivot");
                webix.CustomScroll.init();

                pivotLib.locales.pt = ptLocale;

                class LocalBackend extends pivotLib.services.Backend {
                    data() {
                        return webix.promise.resolve(data);
                    }
                }

                class CustomData extends pivotLib.services.LocalData {
                    [x: string]: any;
                    getOps() {
                        const state = this._state;
                        const table = state.datatable;
                        const ops = super.getOps();
                        const marks = table.marks;

                        if (marks) {
                            ops.forEach(op => {
                                for (const m in marks) {
                                    op.marks.push({
                                        name: m,
                                        check: marks[m]
                                    });
                                }
                            });
                        }
                        return ops;
                    }
                }

                class CustomTable extends pivotLib.views.table {
                    CellFormat(value) {
                        const fmt = webix.Number.numToStr({
                            groupDelimiter: ".",
                            groupSize: 3,
                            decimalDelimiter: ",",
                            decimalSize: props.showAllNumbersAsInteger ? 0 : 2
                        });

                        if (!value) value = value === 0 ? "0" : "";
                        return value >= 0 ? (value ? fmt(value) : value) : (value ? '-' + fmt(value) : value);
                    }
                }

                const myMap: Map<any, MultiTypeBackEnd> = new Map<any, MultiTypeBackEnd>([
                    [pivotLib.services.Backend, LocalBackend],
                    [pivotLib.services.LocalData, CustomData],
                    [pivotLib.views.table, CustomTable]
                ]);
                function getListConfig(id, value, horizontal) {
                    let config = {
                        id,
                        view: "list",
                        css: "dnd-list",
                        drag: "move",
                        on: {
                            onAfterDrop: () => updateStructure(),
                        },
                        onClick: {
                            "field-filter": function (e, id) {
                                const pivot = webix.$$(pivotId) as any;
                                const filterPos = pivot
                                    .getStructure()
                                    .filters.findIndex(filter => filter.name === id);
                                const structure = webix.copy(pivot.getStructure());
                                if (filterPos === -1 ) structure.filters.push({ name: id });
                                else structure.filters.splice(filterPos, 1);
                                pivot.setStructure(structure);
                            },
                        },
                        template: obj => {
                            const pivotComponent = webix.$$(pivotId) as any;
                            const foundFilter = pivotComponent.getStructure().filters.find(filter => filter.name === obj.id);
                            const css = foundFilter ? " field-filter-active" : "";
                            return `${obj.value}<span class="webix_icon wxi-filter field-filter${css}"></span>`;
                        },
                    };

                    const moveLabel = id !== fieldsId;
                    const name = {
                        template: `${value}${moveLabel ? "<div class=\"field-name-sub\">MOVA OS CAMPOS AQUI</div>" : ""}`,
                        css: "field-name" + (moveLabel ? " field-move" : ""),
                        width: 160,
                        height: 36,
                    };
                    if (horizontal)
                        return {
                            cols: [
                                name,
                                webix.extend(config, {
                                    layout: "x",
                                    scroll: "x",
                                    type: { height: 36, },
                                }),
                            ],
                        };
                    return { rows: [name, config] };
                }
                const initialToggleValue = props.pivoting ? 0 : 1; // 0 para "off" (oculto), 1 para "on" (visível)    
                webix.ui(
                    {
                        type: "line",
                        container: pivotRef.current,
                        css: "lc-pivot",
                        borderless: false,
                        margin: 0,
                        cols: [
                            {
                                id: newrowsId,
                                type: "line",
                                rows: [
                                    {
                                        view: 'toggle',
                                        css: 'toogle-tree',
                                        type: 'icon',
                                        offIcon: 'wxi-angle-down',  // ícone quando está desligado (recolhido)
                                        onIcon: 'wxi-angle-up',     // ícone quando está ligado (expandido)
                                        label: 'Expandir/recolher',
                                        value: 1,  // valor inicial (0 para desligado, 1 para ligado)
                                        width: 167,
                                        height: 60,
                                        on: {
                                            onChange: function (newv, oldv) {
                                                const tt = webix.$$(pivotId).queryView("treetable");
                                                if (tt) {
                                                    if (newv) {
                                                        tt.openAll();
                                                    } else {
                                                        tt.closeAll();
                                                        applyBoldStyles();
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    getListConfig(rowsId, "LINHAS", false),
                                ],
                            },
                            {
                                view: "resizer",
                                margin: 0,
                                width: 15,
                                borderless: true,
                            },
                            {
                                borderless: false,
                                margin: 10,
                                rows: [
                                    {
                                        id: toggleId,
                                        view: "toggle",
                                        css: "pivot-toggle",
                                        height: 1,
                                        borderless: true,
                                        offLabel: "Ocultar pivot",
                                        onLabel: "Exibir pivot",
                                        value: initialToggleValue,
                                        on: {
                                            onChange: val => {
                                                webix.$$(settingsId)[val ? "hide" : "show"]();
                                                webix.$$(newrowsId)[val ? "hide" : "show"]();
                                            }
                                        },
                                    },
                                    {
                                        id: settingsId,
                                        borderless: false,
                                        margin: 10,
                                        rows: [
                                            getListConfig(fieldsId, "CAMPOS DISPONÍVEIS", true),
                                            getListConfig(colsId, "COLUNAS", true),
                                        ],
                                    },
                                    //{
                                    //    view: "resizer",
                                    //    borderless: true,
                                    //},
                                    //pivot
                                    {
                                        view: "pivot",
                                        webWorker: "@xbs/pivot/codebase/pivot.worker.js",
                                        mode: props.mode ? props.mode : "table",
                                        css: "pivot-data",
                                        id: pivotId,
                                        locale: {
                                            lang: "pt",
                                            webix: {
                                                pt: "pt-BR",
                                            },
                                        },
                                        datatable: {
                                            footer: props.footer === true || props.footer === false ? props.footer : true,
                                            marks: {
                                                "r-align": v => typeof v == "number" // check the data type
                                            },
                                            minX: props.minX === true || props.minX === false ? props.minX : true,
                                            maxX: props.maxX === true || props.maxX === false ? props.maxX : true,
                                            minY: props.minY === true || props.minY === false ? props.minY : true,
                                            maxY: props.maxY === true || props.maxY === false ? props.maxY : true,
                                            css: props.classTable ? props.classTable : '',
                                        },
                                        fields: orderedFields.length > 0 ? orderedFields : undefined,
                                        chart: props.chart ? props.chart : {},
                                        predicates: {
                                            date: customDateFormat,
                                        },
                                        freezeColumns: false,
                                        borderless: false,
                                        //structure: props.structure,
                                        structure: initialStructure,
                                        override: myMap
                                    }
                                ]
                            }
                        ]
                    }
                );
                const pivot = webix.$$(pivotId) as any;
                const cols = webix.$$(colsId) as any;
                const rows = webix.$$(rowsId) as any;
                const toggle = webix.$$(toggleId) as any;

                // lógica para formatar em negrito nodos abertos na arvore
                let expandedLevels = {};
                pivotRef.current.addEventListener('click', function (e) {
                    const tt = pivot.queryView("treetable");
                    if (tt) {
                        let maxLevel = getMaxLevel();
                        for (let i = 1; i <= maxLevel; i++) {
                            tt.data.each(function (item) {
                                if (item.$level === i && item.open) {
                                    expandedLevels[i] = true;
                                }
                            });
                        }
                    }
                    applyBoldStyles();
                });
                function applyBoldStyles() {
                    let maxLevel = getMaxLevel();
                    for (let i = 1; i <= maxLevel; i++) {
                        let nodesAtLevel = document.querySelectorAll('.webix_cell[aria-level="' + i + '"]');
                        nodesAtLevel.forEach(function (node) {
                            if (expandedLevels[i]) {
                                node.classList.add('same-level-bold');
                            } else {
                                node.classList.remove('same-level-bold');
                            }
                        });
                    }
                }
                setTimeout(() => {
                    const tt = pivot.queryView("treetable");
                    if (tt) {
                        tt.attachEvent("onAfterScroll", applyBoldStyles);
                        tt.attachEvent("onAfterRender", applyBoldStyles);
                    }
                }, 50);
                // Função para obter o nível máximo na árvore
                function getMaxLevel() {
                    let nodes = document.querySelectorAll('.webix_cell[aria-level]');
                    let max = 0;
                    nodes.forEach(function (node) {
                        let levelAttr = node.getAttribute('aria-level');
                        let level = levelAttr ? parseInt(levelAttr, 10) : 0;
                        if (level > max) {
                            max = level;
                        }
                    });
                    return max;
                }
                //fim da lógica de negrito para nodos abertos da arvore

                //ui separada da webix para o menu de contexto
                webix.ui({
                    view: "contextmenu",
                    id: headerContextMenuId,
                    data: [
                        { id: "expand", value: "Expandir todos" },
                        { id: "collapse", value: "Recolher todos" }
                    ],
                    on: {
                        onItemClick: function (id) {
                            const pivot = webix.$$(pivotId) as any;
                            const tt = pivot.queryView("treetable");
                            if (tt) {
                                if (id === "expand") {
                                    tt.openAll();
                                } else if (id === "collapse") {
                                    tt.closeAll();
                                    applyBoldStyles();
                                }
                            }
                        }
                    }
                });
                function handleHeaderClick(e: MouseEvent) {
                    if ((e.target as HTMLElement)?.className.indexOf("webix_pivot_tree_header") !== -1) {
                        webix.$$(headerContextMenuId).show(e as any);
                        return webix.html.preventEvent(e);
                    }
                }
                pivot.$view.oncontextmenu = handleHeaderClick;
                pivot.$view.onclick = handleHeaderClick;

                if (!props.pivoting) {
                    webix.$$(settingsId).hide();
                    webix.$$(newrowsId).hide();
                }

                //contexto do pivot
                setOpenPivotConfiguration(() => { pivot.config.config = !pivot.config.config; });
                setCSVPivotConfiguration(() => { webix.toExcel(webix.$$(pivotId)); });
                setCustomPivotConfiguration(() => { setShowPivotViewPanel(prev => !prev); });
                /*    click:function(){
                    webix.toExcel($$("mydatatable"));
                } */
                setOpenTable(() => { pivot.config.mode = "table"; });
                setOpenTable(() => { pivot.config.mode = "table"; });
                setOpenTree(() => { pivot.config.mode = "tree"; });
                setOpenChart(() => { pivot.config.mode = "chart"; });
                setOpenExternalSettings(() => { toggle.toggle(); });


                function updateStructure() {
                    const structure = webix.copy(pivot.getStructure());
                    if (pivot.config.mode === "chart")
                    structure.groupBy = cols.data.order[0];
                    structure.columns = [...cols.data.order];
                    structure.rows = [...rows.data.order];
                    pivot.setStructure(structure);
                }

                pivot.getState().$observe("structure", structure => {

                    savePivotConfig(structure);
                    setPivotStructure(structure); // Atualizar o estado local

                    const fields = pivot.config.fields;
                    cols.clearAll();
                    cols.parse(
                        structure.columns.map(id => fields.find(field => field.id === id))
                    );
                    rows.clearAll();
                    rows.parse(
                        structure.rows.map(id => fields.find(field => field.id === id))
                    );
                    const fieldsView = webix.$$(fieldsId) as any;
                    fieldsView.clearAll();
                    fieldsView.parse(
                        fields.filter(
                            field =>
                                structure.columns.indexOf(field.id) === -1 &&
                                structure.rows.indexOf(field.id) === -1
                        )
                    );
                });
                webix.i18n.setLocale("pt-BR");
            });
            return () => {
                webix.$$(pivotId).destructor();
            };
        }
    }, [refreshPivot]);

    return <div>
        <PivotViewPanel
            setShowPivotViewPanel={setShowPivotViewPanel}
            setSelectitedView={props.setSelectedView && props.setSelectedView}
            show={showPivotViewPanel}
            props={props}
            pivotStructure={pivotStructure}
            setPivotStructure={setPivotStructure}
            setRefreshPivot={setRefreshPivot}
            styleVision={props.styleVision}
            focusedStyle={props.focusedStyleVision}
        />
        <div id={pivotContainerId} ref={pivotRef} style={props.style ? props.style : { height: "calc(100vh - 125px)" }}></div>
    </div>
};
export default PivotTableWebix;
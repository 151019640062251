import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Layout from '../../../components/Layout/Layout';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { PatchManagerService } from '../../../services/patchmanager/patchManagerService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { MissingPatches, SystemDetail, SystemReport } from '../../../services/patchmanager/patchManagerModel';
import { AiOutlineApple, AiOutlineExclamationCircle, AiOutlineWindows } from 'react-icons/ai';
import moment from 'moment';
import { PiAppleLogoLight, PiFunnelDuotone, PiFunnelLight, PiLinuxLogo, PiLinuxLogoLight, PiWindowsLogoLight } from 'react-icons/pi';
import LcTooltip from '../../../components/Generic/LcTooltip';
import { PPModal } from 'processor-plataform-ui';
import './index.css';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import PPCheckBox from '../../../components/Form/PPCheckBox';
import FilterPanel from '../../../components/Data/FilterPanel';
import FilterPanelOld from '../../../components/Data/Old/FiltterPanelOld';
import { PatchManagerState } from '../../../store/reducers/patchManagerReducer';

export const translateStatus = (row) => {
    const translateStatus = {
        1: 'Ativo',
        2: "Inativo",
    };
    return translateStatus[row];
};

export const getStatusRow = (row: SystemDetail) => {
    return row.computer_live_status == 1 ? '#3ebb3f' : '#dc291e';
};

const translateHealth = (status: number) => {
    const translateStatus = {
        0: 'Desconhecido',
        1: "Saudável",
        2: "Vulnerável",
        3: "Altamente vulnerável",
    };
    return translateStatus[status];
};

const translateHealthColor = (status: number) => {
    const translateStatusColor = {
        4: '#ff0400', // 'Important'
        3: '#ff5400', // 'Important'
        2: "#f6de00",// 'Moderate' 
        1: "#3ebb3f",//'Low'
        0: "#cccccf",//'Unrated'
    };
    return translateStatusColor[status];
};

const translateSeverityColor = (status) => {
    const translateStatusColor = {
        4: '#ff4400', // 'Important'
        3: '#ffa400', // 'Important'
        2: "#f6de00",// 'Moderate' 
        1: "#3ebb3f",//'Low'
        0: "#cccccf",//'Unrated'
    };
    return translateStatusColor[status];
};

const translateSeverityLabel = (status) => {
    const translateStatusColor = {
        4: 'Crítico'
        , 3: 'Importante'
        , 2: 'Moderado'
        , 1: 'Baixo'
        , 0: 'Não classificado'
    };
    return translateStatusColor[status];
};

const SystemListPatchManager: React.FC = () => {
    const queryClient = useQueryClient();
    const patchManager = useSelector<RootState, PatchManagerState>(state => state.patchManager);
    const user = useSelector<RootState, UserState>(state => state.user);
    const patchManagerService = new PatchManagerService({});
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [systemSelected, setSystemSelected] = useState<SystemDetail | null>(null);
    const [systemListFiltered, setSystemListFiltered] = useState<SystemDetail[]>([]);
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
    const [pendentesFilter, setPendentesFilter] = useState<boolean>(true);
    const [instaladosFilter, setInstaladosFilter] = useState<boolean>(false);
    const [implantandosPMFilter, setImplantandosPMFilter] = useState<boolean>(false);
    const [failedFilter, setFailedFilter] = useState<boolean>(false);
    const [systemWithManyPatches, setSystemWithManyPatches] = useState<string[]>([]);
    const [filteredPatches, setFilteredPatches] = useState<SystemReport[]>([]);
    const [ clearFilter, setClearFilter ] = useState<boolean>(true);
    const [reducerFilter , setReducerFilter] = useState<any>()

    const { data: systemList = [], isLoading: systemListLoading } = useQuery('systemList', async () => {
        //  setSystemListFiltered([] as unknown as SystemDetail[]);
        const response = await patchManagerService.GetSystemByPlatformList();
        return response;
    }, {
        onSuccess: data => {
            if (data.length === 0) {
                queryClient.invalidateQueries('todos'); // Invalidate the cache if data is empty
            }
        },
    });

    useEffect(() => {
        if(patchManager.OsSystemName && patchManager.OsSystemName !== '') {
            let osName = {
                service_pack:[patchManager.OsSystemName]
            }
            setReducerFilter(osName)
        }
    },[patchManager.OsSystemName])

    const card:Card[] = [
        {
            id: 1,
            type: 'Custom',
            hideHeader: true,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={systemListLoading} label='Carregando...'>
                        <LcInfiniteTable
                            rows={systemListFiltered}
                            columns={columnsSystem}
                            size={systemListFiltered.length}
                            hidePagination
                            fontSize='medium'
                            status={getStatusRow}
                            notLoadMore
                            tooltipContentColumn='computer_live_status'
                            customTooltipContentColumn={translateStatus}
                        />
                    </LcLoading>
                );
            }
        }
    ]

    useEffect(() => { if(!reducerFilter) setSystemListFiltered(systemList); }, [systemList])
    const translations = {
        resource_name: {
            label: "HostName",
            type: "multiSelect"
        },
        service_pack: {
            label: "Sistema operacional",
            type: "multiSelect",
            // topDropdown: true,
        },
        computer_live_status: {
            label: "Status",
            type: "multiSelect",
            values: {
                1: 'Ligado',
                2: "Desligado",
                3: 'Desconhecido',
            }
        },
        resource_health_status: {
            label: "Saúde",
            type: "multiSelect",
            values: {
                0: 'Desconhecido',
                1: "Saudável ",
                2: "Vulnerável",
                3: "Altamente vulnerável"
            }
        },
        agent_last_contact_time: {
            label: "Última comunicação",
            type: "spanDateTime"
        },
        // 'resourcetorebootdetails.reboot_req_status': {
        //     label: "Reboot Necessário",
        //     type: "multiSelect",
        //     values: {
        //         true: 'Sim',
        //         false: 'Não'
        //     }
        // },
    };

    const columnsSystem = [
        {
            field: "resource_name", headerName: "HostName", width: "20%", align: 'left', fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
                        {row.os_platform_name && <LcIconLink notALink icon={
                            row.os_platform_name.toLocaleLowerCase().includes('windows') ? <PiWindowsLogoLight size={'1rem'} />
                                :
                                row.os_platform_name.toLocaleLowerCase().includes('mac') ? <PiAppleLogoLight size={'1rem'} />
                                    :
                                    <PiLinuxLogoLight size={'1rem'} />
                        } />}
                        <span style={{ color: 'var(--text-link)', fontWeight: 400, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { setModalOpen(true); setSystemSelected(row); }} className='font-4x'>{row.resource_name}</span>
                    </div>
                )
            }
        },
        {
            field: "resource_health_status", headerName: "Status de vulnerabilidade", width: "15%", align: 'left', fontSize: '11px', overflow: 'visible',
            renderCell: (row: SystemDetail) => {
                return <div>
                     <div style={{ width: '100%', borderRadius: '100%', color: translateHealthColor(row.resource_health_status) }}>{translateHealth(row.resource_health_status)}</div>
                </div>
            }
        },
        {
            field: "agent_last_contact_time", headerName: "Última comunicação", width: "12%", align: 'left', fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return moment(row.agent_last_contact_time).format('DD/MM/YYYY hh:mm')
            }
        },
        // {
        //     field: "agent_last_bootup_time", headerName: "Último reboot", width: "12%", align: 'left', fontSize: '11px',
        //     renderCell: (row: SystemDetail) => {
        //         return moment(row.agent_last_bootup_time).format('DD/MM/YYYY hh:mm')
        //     }
        // },
        {
            field: 'resourcetorebootdetails.reboot_req_status', headerName: "Reboot", width: "15%", align: 'center', overflow: "visible", fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return (
                    <LcTooltip 
                        trigger='hover'
                        position='right'
                        content={`Último reboot: ${moment(row.agent_last_bootup_time).format('DD/MM/YYYY hh:mm')} `}
                    >
                        {
                            (row['resourcetorebootdetails.reboot_req_status']) !== false ?
                            'Sim'
                            : 
                            'Não'

                        }
                    </LcTooltip>
                )
            }      
        },
        {
            field: "service_pack", headerName: "Sistema operacional", width: "28%", align: 'left', overflow: 'visible', fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return row.service_pack ?
                    <div className='operationalSystem'>
                        <p>{row.service_pack}</p>
                    </div>
                    :
                    <div className='operationalSystem'>{row.service_pack}</div>
            }
        },
        {
            field: "scan_status", headerName: "Status", width: "10%", align: 'left', fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return (<div className='agentStatus'> {translateScanStatusPatch(row.scan_status)}</div>);
            }
        },
        {
            field: "critical_patch_count", headerName: "Patches pendentes", width: "15%", align: 'left', overflow: 'visible',
            renderCell: (row: SystemDetail) => {
                let allSum = row.missing_bios_patches + row.missing_driver_patches + row.missing_ms_patches + row.missing_tp_patches;
                let classifiedSum = row.critical_patch_count + row.important_patch_count + row.moderate_patch_count + row.moderate_patch_count + row.low_patch_count;
                var unclassifiedSum = allSum - classifiedSum;
                if (unclassifiedSum < 0) {
                    unclassifiedSum = 0;
                }
                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <LcTooltip trigger='hover' position='left' content="Crítico">
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: row.critical_patch_count > 0 ? '#dc291e' : '#e2e6f3' }}>
                                {row.critical_patch_count > 99 ? 99 : row.critical_patch_count}
                            </div>
                        </LcTooltip>
                        <LcTooltip trigger='hover' position='left' content="Importante" >
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: row.important_patch_count > 0 ? '#ffa400' : '#e2e6f3' }}>
                                {row.important_patch_count > 99 ? 99 : row.important_patch_count}
                            </div>
                        </LcTooltip>
                        <LcTooltip trigger='hover' position='left' content="Moderado" >
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: row.moderate_patch_count > 0 ? '#f6de00' : '#e2e6f3' }}>
                                {row.moderate_patch_count > 99 ? 99 : row.moderate_patch_count}
                            </div>
                        </LcTooltip>
                        <LcTooltip trigger='hover' position='left' content="Baixo" >
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: row.low_patch_count > 0 ? '#3ebb3f' : '#e2e6f3' }}>
                                {row.low_patch_count > 99 ? 99 : row.low_patch_count}
                            </div>
                        </LcTooltip>
                        <LcTooltip trigger='hover' position='right' content="Não classificado"  >
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: unclassifiedSum > 0 ? '#cccccf' : '#e2e6f3' }}>
                                {unclassifiedSum > 99 ? 99 : unclassifiedSum}
                            </div>
                        </LcTooltip>
                    </div>
                )
            }
        },
    ];

    const columnsPatchDetails = [
        {
            field: "patchId", headerName: "Patch Id", width: "60px", align: 'left', overflow: 'visible',
            renderCell: (row: SystemReport) => {
                return row.superceded_by > 0 ?
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        {row.patch_id}
                    </div>
                    :
                    <div style={{ display: 'flex', width: '100%', alignItems: 'left' }}>{row.patch_id}</div>
            }
        },
        {
            field: "severity", headerName: "Severidade", width: "10%", align: 'center', overflow: 'visibled',
            renderCell: (row: SystemReport) => {
                return <div className='severityLabelDetails' style={{ backgroundColor: translateSeverityColor(row.severity) }}>{translateSeverityLabel(row.severity)} </div>
            }
        },
        {
            field: "patch_description", headerName: "Descrição do patch", width: "30%", align: 'left',
            renderCell: (row: SystemReport) => <div>{row.patch_description}</div>
        },
        { field: "vendor_name", headerName: "Fabricante", width: "20%", align: 'left' },
        {
            field: "installed_time", headerName: "Instalado em", width: "10%", align: 'left',
            renderCell: (row: SystemReport) => {
                return moment(row.installed_time)
                    .format('DD/MM/YYYY') !== '31/12/1969' ?
                    <>{moment(row.installed_time).format('DD/MM/YYYY')} </> : '-'
            }
        },
        {
            field: "deploy_remarks_args", headerName: "Remarks", width: "20%", align: 'left',
            renderCell: (row: SystemReport) => <div className='overflowEllipsis'>{row.deploy_remarks}</div>
        },
    ];

    const translateStatusPatch = (row: SystemReport) => {
        const translateStatus = {
            202: 'Pendente',
            201: "Instalado",
            206: "Falha"
        };
        return translateStatus[row.patch_status];
    };

    const translateScanStatusPatch = (status: number) => {
        const translateStatus = {
            226: 'Falha',
            227: 'Em andamento',
            228: 'Sucesso',
            229: 'Não verificado'
        };
        return translateStatus[status];
    };
    //affected_patch_status
    const { data: systemReport, refetch: refetchSystemReport, isLoading: systemReportListLoading } = useQuery(
        ['systemReport', systemSelected?.resource_id_string],
        async () => {
            //  setSystemListFiltered([] as unknown as SystemDetail[]);
            if (systemSelected) {
                const report = await patchManagerService.GetSystemReport(systemSelected?.resource_id_string);
                applyPatchFilters(report);
                return report;
            }
        },
        {
            enabled: !!systemSelected,
        }
    );


    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    reducerFilter={reducerFilter}
                    data={systemList}
                    onFilteredData={(dataFilteredParm) => {
                        setClearFilter(false);
                        setSystemListFiltered(dataFilteredParm as SystemDetail[]);
                    }}
                    clearFilters={() => {setClearFilter(true); setSystemListFiltered(systemList)}}
                    translations={translations} 
                    filter={Object.keys(translations).map(key => ({ 
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}                
                    />
            </div>
        );
    };

    const plural = systemWithManyPatches.length > 1;
    const hosts = systemWithManyPatches.join(', ');
    const handleCheckboxChange = (name: string, e: any) => {

        var checked = e.target.value;
        switch (name) {
            case 'pendentes':
                setPendentesFilter(checked);
                break;
            case 'instalados':
                setInstaladosFilter(checked);
                break;
            case 'implantandosPMFilter':
                setImplantandosPMFilter(checked);
                break;
            default:
                break;
        }

    };
    useEffect(() => {
        systemReport && applyPatchFilters(systemReport);
    }, [pendentesFilter, instaladosFilter, failedFilter, implantandosPMFilter])

    const items = [
        {
            icon: clearFilter ? <PiFunnelLight color='var(--icon-default)' /> : <PiFunnelDuotone color='var(--color-secondary-lime)' className='notHover' />,
            tooltip: "Filtro",
            onClick: () => setShowFilterPanel(!showFilterPanel),
            clickble: showFilterPanel,
            color: clearFilter ? '' : 'var(--color-secondary-lime)'
        },
        ...(systemWithManyPatches.length !== 0 ? [{
            icon: <AiOutlineExclamationCircle color='#ffa400' />,
            tooltip: `O${plural ? 's' : ''} host${plural ? 's' : ''}: ${hosts} est${plural ? 'ão' : 'á'} com 100 ou mais patche${plural ? 's' : ''} pendente${plural ? 's' : ''} de instalação`,
            onClick: () => undefined,
            clickable: false
        }] : [])
    ];

    const applyPatchFilters = (patches: SystemReport[]) => {

        let filteredPatches = [...patches];

        if (pendentesFilter) {
            filteredPatches = filteredPatches.filter(x => !((x.patch_affected_status_label) == 'dc.common.INSTALLED'));
        }
        if (implantandosPMFilter) {
            filteredPatches = filteredPatches.filter(x => ((x.installed_time) > 0));
        }
        if (instaladosFilter) {
            filteredPatches = filteredPatches.filter(x => ((x.patch_affected_status_label) == 'dc.common.INSTALLED'));
        }
        if (instaladosFilter && pendentesFilter) {
            filteredPatches = [...patches];
        }

        setFilteredPatches(filteredPatches);
    };
    return (
        <Layout
            pageTitle='Sistemas'
            functionsGeneric={items}
            row={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                    <div style={{ fontWeight: 700 }} className='size'>{systemListFiltered.length}</div>
                    &nbsp;
                    <span> Sistemas</span>
                </div>
            }
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: showFilterPanel,
                    close: (e) => setShowFilterPanel(e),
                    content: () => filterSystem()
                }}
            />
            <PPModal visible={modalOpen} title="Detalhes do Sistema" onClose={() => setModalOpen(false)} size='large'>
                {
                    systemSelected &&
                    <div className='detailsSystemPlataform'>
                        <div className="headerSystemDetails">
                            <div className='systemDetail'>
                                <h3>{systemSelected.resource_name}</h3>
                                <div>
                                    <LcIconLink icon={
                                        systemSelected.os_platform_name.toLocaleLowerCase().includes('windows') ? <AiOutlineWindows size={'.9rem'} />
                                            :
                                            systemSelected.os_platform_name.toLocaleLowerCase().includes('mac') ? <AiOutlineApple size={'.9rem'} />
                                                :
                                                <PiLinuxLogo size={'.9rem'} />
                                    } />
                                    <span>{systemSelected.os_platform_name}</span>
                                </div>
                            </div>
                            <div className='systemDetail'>
                                <p><span className="systemDetailLabel">Status do agente: </span><strong style={{ color: systemSelected.computer_live_status === 1 ? '#3ebb3f' : '#dc291e' }}>{translateStatus(systemSelected.computer_live_status)} </strong></p>
                                <p><span className="systemDetailLabel">Requer reboot:</span> <strong style={{ color: Boolean(systemSelected['resourcetorebootdetails.reboot_req_status']) != false ? '#3ebb3f' : '#dc291e' }}>{Boolean(systemSelected['resourcetorebootdetails.reboot_req_status']) != false ? 'Sim' : 'Não'}</strong></p>
                            </div>
                            <div className='systemDetail'>
                                <p><span className="systemDetailLabel">Endereço IP: </span> {systemSelected.ip_address}</p>
                                <p><span className="systemDetailLabel">Idioma do SO: </span> {systemSelected.os_language_abbr}</p>
                            </div>
                            <div className='systemDetail '>
                                <p><span className="systemDetailLabel">Última comunicação:</span> {moment(systemSelected.agent_last_contact_time).format('DD/MM/YYYY hh:mm')}</p>
                            </div>
                        </div>
                        <div className='bodySystem'>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'space-evenly' }}> <h4>Patches do sistema</h4>      </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'space-evenly' }}>
                                    <PPCheckBox checked={implantandosPMFilter} name='implantandosPM' title={'Implantados PM ' + (filteredPatches?.filter(x => x.installed_time > 0)?.length)} onChange={(e) => handleCheckboxChange('implantandosPMFilter', e)} className='evenly' />
                                    <PPCheckBox checked={pendentesFilter} name='pendentes' title={'Pendentes ' + (systemSelected.missing_bios_patches + systemSelected.missing_driver_patches + systemSelected.missing_ms_patches + systemSelected.missing_tp_patches)} onChange={(e) => handleCheckboxChange('pendentes', e)} className='evenly' />
                                    <PPCheckBox checked={instaladosFilter} name='instalados' title={'Instalados ' + (systemSelected.installed_bios_patches + systemSelected.installed_driver_patches + systemSelected.installed_ms_patches + systemSelected.installed_tp_patches)} onChange={(e) => handleCheckboxChange('instalados', e)} className='evenly' />
                                </div>
                            </div>

                            <LcLoading loading={systemReportListLoading} >
                                <LcInfiniteTable
                                    fontSize='medium'
                                    rows={filteredPatches}
                                    columns={columnsPatchDetails}
                                    height='35vh'
                                    size={filteredPatches.length}
                                    loadMore={() => { return null; }}
                                    status={translateStatusPatch}
                                />
                            </LcLoading>
                        </div>
                    </div>
                }
            </PPModal>
        </Layout>
    );
};

export default SystemListPatchManager;

import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import LcNoData from '../../../../components/Generic/LcNoData';
import { GraphModel } from '../../../../services/patchmanager/patchManagerModel';
import { defaultTooltipConfig } from '../../../../components/Generic/eChartsConfig';

interface graphData {
    labels: Array<string>
    data: Array<number>
    colors: Array<string>
}
interface Props {
    isHome?: boolean
    isSecOps?: boolean
    title?: string
    qtsGroups?: number
    patchSummariesMissingSeverity?: any[];
}

const MissingPatchesGraph: React.FC<Props> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [graphData, setGraphData] = useState<graphData>()

    const getColorsGraph = (SOName: string): string => {
        const color = {
            'Crítico': '#dc291e',
            'Importante': "#ffa400",
            'Moderado': "#f6de00",
            'Baixo': "#3ebb3f",
            'Não classificado': "#cccccf",
        };
        return color[SOName];
    }

    const GetPatchSummariesMissingSeverity = async () => {
        try {
            const missingResponse = props.patchSummariesMissingSeverity;
            if (missingResponse) {
                setGraphData(buildGraphData(missingResponse))
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const buildGraphData = (data: GraphModel[]) => {
        return {
            labels: data.map(label => label.name),
            data: data.map(dados => dados.value),
            colors: data.map(cor => getColorsGraph(cor.name))
        }
    }

    useEffect(() => {
        GetPatchSummariesMissingSeverity()
    }, [user.ClientGroupSelected, user.refreshFlag, props.patchSummariesMissingSeverity]);

    function GetOptions(graphDataLocal) {
        const option = {
            tooltip: {
                ...defaultTooltipConfig,
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            title: {
                text: props.title,
                right: "0",
                top: "0",
                textStyle: {
                    fontSize: 11,
                    fontWeight: 'normal',
                    color: '#8b90a3',
                    fontFamily: 'Ubuntu',
                },
                backgroundColor: props.title ? '#ffffff90' : 'transparent',
                borderRadius: [10],
                padding: [4, 8, 4, 8],
                itemGap: 8
            },
            grid: {
                top: '15%',
                bottom: '0%',
                left: '0%',
                width: '100%',
                containLabel: true,

            },
            xAxis: {
                type: 'category',
                data: graphDataLocal.labels,
                show: true,
                axisLabel: {
                    interval: props.isHome ? 2 : 0,
                    fontSize: (window.innerWidth > 1520 ? 12 : window.innerWidth > 1920 ? 14 : (props.qtsGroups && props.qtsGroups >= 4 ? 7 : 9))
                }
            },
            yAxis: {
                type: 'value',
                show: true,
                axisLabel: {
                    fontSize: props.qtsGroups && props.qtsGroups >= 4 ? 9 : 12
                }
            },
            series: [
                {
                    data: graphDataLocal.data,
                    itemStyle: {
                        color: (params) => graphDataLocal.colors[params.dataIndex],
                        borderRadius: [4, 4, 0, 0],
                        borderColor: '#fff',
                        borderWidth: 0,

                    },
                    type: 'bar',
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(58, 79, 135, 0.4)',
                        }
                    }

                }
            ]
        }

        return option;
    }

    return (
        <div style={{
            height: "100%",
            width: "100%",
        }}>
            {
                graphData ?
                    <ReactECharts
                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                        className='MissingPatchesGraph'
                        option={GetOptions(graphData)}
                        style={{ width: '100%', height: '100%', minHeight: props.isSecOps ? '215px' : '220px', marginBottom: props.isSecOps ? '10px' : '' }}
                    />
                    :
                    <LcNoData size={props.isHome ? "small" : "default"} />
            }
        </div>
    );
}

export default MissingPatchesGraph;
import { GridCellValue } from "@material-ui/data-grid";
import { PPFormInput } from "processor-plataform-ui/dist/components/PPForm2";
import { VendorCredentialItem } from "../pages/VendorCredential/Model/VendorCredentialItem";
import { AzureSubscription } from "../pages/VendorCredential/Model/AzureSubscription";

import api, { APIClient } from "./api";
import { VendorCredential } from "../pages/VendorCredential/Model/VendorCredential";
export class VendorService extends APIClient {

    async Delete(id: number) {

        const url = `${this.endpoint}/api/VendorCredential/Delete/${id}`;
        var response = api({
            method: 'delete',
            url,
            withCredentials: false
        });
        return response;
    }

    async RetrieveListGetListValidateCustomerSubscriptions() {
        const url = `${this.endpoint}/api/VendorCredential/ValidateCustomerSubscriptions`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false
        });
        return response;
    }
    async CheckStatusForVendorCredential(providerid: number): Promise<HealthEvent[]> {
        const url = `${this.endpoint}/api/VendorCredential/CheckStatusForVendorCredential?providerid=${providerid}&refresh=${Math.random()}`;
        var response = await api({
            method: 'get',
            url,
            withCredentials: false
        });
        return response.data;
    }

    async RetrieveListGetListClientGroups() {
        const url = `${this.endpoint}/Account/GetListClientGroups`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false
        });
        return response;
    }

    async RetrieveList() {
        const url = `${this.endpoint}/api/VendorCredential/RetrieveList?refresh=${Math.random()}`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false
        });
        return response;
    }

    async Retrieve(id: number) {

        const url = `${this.endpoint}/api/VendorCredential/Retrieve?id=${id}&refresh=${Math.random()}`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false,
        });
        return response;
    }

    async GetAccountsFromCustomer() {

        const url = `${this.endpoint}/api/VendorCredential/GetAccountsFromCustomer?refresh=${Math.random()}`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false,
        });
        return response;
    }

    async GetActiveSubscritpionsFromProvider() {

        const url = `${this.endpoint}/api/VendorCredential/GetSubscritpionsFromProvider?refresh=${Math.random()}`;
        var response = api({
            method: 'get',
            url,
            withCredentials: false,
        });
        return response;
    }

    async Save(item: VendorCredential) {
        return item.id && item.id > 0 ? await this.Update(item) : await this.Create(item)
    }

    async SaveAccountsFromCustomer(accountChangeRequest: any) {
        const url = `${this.endpoint}/api/VendorCredential/SaveSubscritpionsFromProvider`;
        var response = api({
            method: 'post',
            url,
            data: accountChangeRequest,
            withCredentials: false,
        });
        return response;
    }

    async Create(item: VendorCredential) {

        const url = `${this.endpoint}/api/VendorCredential/Create`;
        var response = await api({
            method: 'post',
            url,
            data: item,
            withCredentials: false,
        });
        return response;

    }

    async Update(item: any) {
        const url = `${this.endpoint}/api/VendorCredential/Update`;
        var response = api({
            method: 'post',
            url,
            data: item,
            withCredentials: false,
        });
        return response;
    }

    async RetrieveProviderSubscriptions(item: any) {
        const url = `${this.endpoint}/api/VendorCredential/GetSubscriptionList`;
        var response = api({
            method: 'post',
            url,
            data: item,
            withCredentials: false,
        });

        return response;
    }

    async SaveSubscriptionList(item: any) {
        const url = `${this.endpoint}/api/VendorCredential/SaveSubscriptions`;
        var response = api({
            method: 'post',
            url,
            data: item,
            withCredentials: false,
        });

        return response;
    }

    public emptyRecord: VendorCredential = {
        id: 0,
        clientGroupId: 0,
        cloudOSProviderId: 0,
        active: true,
        vendorCredentialAccountTypeID: 0,
        vendorCredentialItem: [] as VendorCredentialItem[]
    };

    //VendorCredentialItem
    public recordAWSConsumption: VendorCredential = {
        id: 99999999999,
        clientGroupId: 0,
        cloudOSProviderId: 0,
        active: true,
        vendorCredentialAccountTypeID: 0,
        vendorCredentialItem: [] as VendorCredentialItem[]
    };

    ActionsBaseFunctions(createAction: any, closeActions: any, DeleteActions: any) {

        let arrayActions = {
            functionsEdit: [{
                id: "75eb1597-cdf4-48aa-9644-61230493886b",
                title: 'Salvar',
                tooltip: 'Salvar',
                icon: 'lci lci-check',
                func: () => {
                    createAction();
                },
                disabled: false
            }, {
                id: "58d530a1-7f35-42cd-88dd-ba17eba4b6d5",
                title: 'Fechar',
                tooltip: 'Fechar',
                icon: 'lci lci-x',
                func: () => {
                    if (closeActions) {
                        closeActions();
                    }
                },
                disabled: false
            }]
        };

        if (DeleteActions != undefined) {
            arrayActions.functionsEdit.push({
                id: "c0423b0b-e2d1-4365-b66e-ebce6edcf0d9",
                title: 'Excluir selecionados',
                tooltip: 'Excluir',
                icon: 'lci lci-trash',
                func: () => {
                    DeleteActions();
                },
                disabled: false
            });
        }
        return arrayActions;
    }

    getProviderName(id: GridCellValue) {
        if (!id) {
            return '';
        }
        try {
            var idnumber: number = +id;
            var i = 0;
            for (i = 0; i < this.enumProviders.length; i++) {
                if (this.enumProviders[i].value == idnumber) {
                    return this.enumProviders[i].label;
                }
            }
        } catch (e) {
            console.error(" Error" + e);
        }

        return '';
    };


    getGraphPerfil(id: GridCellValue) {
        if (!id) {
            return '';
        }
        var idnumber: number = +id;
        let i = 0;
        for (i = 0; i < this.enumPerfilGraph.length; i++) {
            if (this.enumPerfilGraph[i].id == idnumber) {
                return this.enumPerfilGraph[i].name;
            }
        }
        return '';
    };

    getTypeInputSecretName(name: string,
        VendorCredentialId: string | number,
        clientGroupId: string | number,
        providerId: string | number,
        idItem: string | number) {

        var ClientGroupId = clientGroupId;
        var ProviderName = this.getProviderName(providerId);
        var IdVendor = VendorCredentialId;
        var IdVendorItem = 'Novo';
        if (idItem != null && idItem != '') {
            IdVendorItem = '' + idItem;
        }
        var SecretTag = "" + ClientGroupId + "-" + ProviderName + "" + IdVendor + "-" + IdVendorItem + "-" + name + "";
        return SecretTag;
    }

    public enumProviders = [
        {
            value: 1, label: 'Azure', processorOnly: true
        },
        {
            value: 2, label: 'AWS', processorOnly: false
        },
        {
            value: 3, label: 'MicrosoftGraph', processorOnly: false
        },
        {
            value: 7, label: 'CommVault', processorOnly: true
        },
        {
            value: 70, label: 'CommvaultGlobalAccess', processorOnly: false
        },
        {
            value: 8, label: 'Arm', processorOnly: false
        },
        {
            value: 10, label: 'HeyHo', processorOnly: true
        },
        {
            value: 11, label: 'AzureRetention', processorOnly: true
        },
        {
            value: 12, label: 'SAM', processorOnly: true
        },
        {
            value: 13, label: 'SaaS Backup', processorOnly: true
        },
        {
            value: 14, label: 'Google', processorOnly: false
        },
        {
            value: 15, label: 'SEP', processorOnly: true
        },
        {
            value: 16, label: 'ConsumptionAzure', processorOnly: false
        },
        {
            value: 17, label: 'ConsumptionAWS', processorOnly: false
        },
        {
            value: 18, label: 'ConsumptionGoogle', processorOnly: false
        },
        {
            value: 19, label: 'PartnerSource', processorOnly: true
        },
        {
            value: 20, label: 'PowerShell', processorOnly: true
        },
        {
            value: 21, label: 'AWSDynamoDB', processorOnly: true
        },
        {
            value: 23, label: 'BackupSaaSV2Customer', processorOnly: false
        },
        {
            value: 223, label: 'BackupSaaSV2Partner', processorOnly: false
        },
        {
            value: 241, label: 'EndpointDetectionResponse', processorOnly: true
        },
        {
            value: 242, label: 'GlobalEDR', processorOnly: false
        },
        {
            value: 26, label: 'BillingDataLakeAzure', processorOnly: false
        },
        {
            value: 27, label: 'BillingDataLakeAWS', processorOnly: false
        },
        {
            value: 28, label: 'BillingDataLakeGoogle', processorOnly: false
        },
        {
            value: 29, label: 'OptScale', processorOnly: false
        },
        {
            value: 31, label: 'EmailGlobalAWS', processorOnly: false
        },
        {
            value: 110, label: 'GlobalRetention', processorOnly: false
        },
        {
            value: 32, label: 'PatchManagerPlusGlobal', processorOnly: false
        },
        {
            value: 33, label: 'PatchManagerPlus', processorOnly: false
        },
        {
            value: 34, label: 'VantageAPI', processorOnly: false
        },
        {
            value: 9935, label: 'OPENAI', processorOnly: true
        },
        {
            value: 9936, label: 'GEMINI', processorOnly: true
        },
        {
            value: 9937, label: 'CLAUDE', processorOnly: true
        }
    ];

    enumPerfilGraph = [
        {
            id: 0, name: 'SAAS'
        },
        {
            id: 2, name: 'EA'
        },
        {
            id: 3, name: 'CSP'
        }
    ];

    IsInputSecret(name: string) {

        return [
            'key',
            'password',
            'accountkey',
            'accesskey',
            'secretkey',
            'applicationsecret',
            'eamanagementkey',
            'securitykey',
            'cspmanagementkey',
            'keyrefreshtoken',
            'cspapplicationsecret',
            'refreshtoken',
            'uservmpassword',
            'redisconnectionstring',
            'privatekey',
            'clientid',
            'clientsecret',
            'serviceprincipalsecretkey',
            'cspkeyvaultclientsecret',
            'passwordfortinetedr',
            'optscalepassword',
            'emailpassword',
            'apitoken'
        ].includes(name.toLocaleLowerCase());
    };

    ReadOnlyField(fieldName: string) {
        return ['type'].includes(fieldName.toLocaleLowerCase())
    }

    public enumProvidersTemplates = [
        {
            id: 1, name: 'Azure',
            item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'TenantId', data: '', readOnly: false },
                    { name: 'SubscriptionId', data: '', readOnly: false },
                    { name: 'ApplicationId', data: '', readOnly: false },
                    { name: 'Key', data: '', readOnly: false },
                    { name: 'DefaultContract', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 2, name: 'AWS',
            item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'AccountId', data: '', readOnly: false },
                    { name: 'Region', data: '', readOnly: false },
                    { name: 'AccessKey', data: '', readOnly: false },
                    { name: 'SecretKey', data: '', readOnly: false },
                    { name: 'DefaultContract', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 3, name: 'MicrosoftGraph',
            item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'ApplicationId', data: '', readOnly: false },
                    { name: 'ApplicationSecret', data: '', readOnly: false },
                    { name: 'Domain', data: '', readOnly: false },
                    { name: 'User', data: '', readOnly: false },
                    { name: 'Password', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 7, name: 'CommVault', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'SipId', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 8, name: 'Arm', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'TenantId', data: '', readOnly: false },
                    { name: 'SubscriptionId', data: '', readOnly: false },
                    { name: 'ApplicationId', data: '', readOnly: false },
                    { name: 'Key', data: '', readOnly: false },
                    { name: 'DefaultContract', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 10, name: 'HeyHo', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'DepartmentId', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 11, name: 'AzureRetention', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'AccountName', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 12, name: 'SAM', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'IdSource', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 13, name: 'SaaS Backup', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'UserName', data: '', readOnly: false },
                    { name: 'Password', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 14, name: 'Google', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'Type', data: '', readOnly: false },
                    { name: 'ProjectId', data: '', readOnly: false },
                    { name: 'PrivateKeyId', data: '', readOnly: false },
                    { name: 'PrivateKey', data: '', readOnly: false },
                    { name: 'ClientEmail', data: '', readOnly: false },
                    { name: 'ClientId', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 15, name: 'SEP', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'ClientId', data: '', readOnly: false },
                    { name: 'ClientSecret', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 16, name: 'ConsumptionAzure', item: {
                idItem: 0,
                alias: '', active: true, fields: [
                    { name: 'ServicePrincipalId', data: '', readOnly: false },
                    { name: 'ServicePrincipalSecretKey', data: '', readOnly: false },
                    { name: 'TenantId', data: '', readOnly: false },
                    // { name: 'MarkupFix', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 17, name: 'ConsumptionAWS', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'IdConfiguracao', data: '', readOnly: false },
                    { name: 'AccountNumber', data: '', readOnly: false },
                    { name: 'AccessKey', data: '', readOnly: false },
                    { name: 'SecurityKey', data: '', readOnly: false },
                    { name: 'Region', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 18, name: 'ConsumptionGoogle', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'IdConfiguracao', data: '', readOnly: false },
                    { name: 'Type', data: '', readOnly: false },
                    { name: 'ProjectId', data: '', readOnly: false },
                    { name: 'PrivateKeyId', data: '', readOnly: false },
                    { name: 'PrivateKey', data: '', readOnly: false },
                    { name: 'ClientEmail', data: '', readOnly: false },
                    { name: 'ClientId', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 19, name: 'PartnerSource', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'ApplicationId', data: '', readOnly: false },
                    { name: 'PartnerId', data: '', readOnly: false },
                    { name: 'CSPApplicationSecret', data: '', readOnly: false },
                    { name: 'RefreshToken', data: '', readOnly: false },
                    { name: "CSPKeyVaultEndpoint", data: '', readOnly: false },
                    { name: "CSPKeyVaultClientId", data: '', readOnly: false },
                    { name: "CSPKeyVaultClientSecret", data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 20, name: 'PowerShell', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'Domain', data: '', readOnly: false },
                    { name: 'User', data: '', readOnly: false },
                    { name: 'Password', data: '', readOnly: false },
                    { name: 'AddressVM', data: '', readOnly: false },
                    { name: 'UserVM', data: '', readOnly: false },
                    { name: 'UserVMPassword', data: '', readOnly: false },
                    { name: 'RedisConnectionString', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }

                ]
            }
        },
        {
            id: 21, name: 'AWSDynamoDB', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'AccountId', data: '', readOnly: false },
                    { name: 'Region', data: '', readOnly: false },
                    { name: 'AccessKey', data: '', readOnly: false },
                    { name: 'SecretKey', data: '', readOnly: false },
                    { name: 'DefaultContract', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 23, name: 'BackupSaaSV2Customer', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'username', data: '', readOnly: false },
                    { name: 'password', data: '', readOnly: false },
                    { name: 'type', data: '', value: 'customer', readOnly: true },
                ]
            }
        },
        {
            id: 26, name: 'BillingDataLakeAzure',
            item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'StorageName', data: '', readOnly: false },
                    { name: 'ServicePrincipalId', data: '', readOnly: false },
                    { name: 'ServicePrincipalSecretKey', data: '', readOnly: false },
                    { name: 'TenantId', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 27, name: 'BillingDataLakeAWS',
            item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'StorageName', data: '', readOnly: false },
                    { name: 'ServicePrincipalId', data: '', readOnly: false },
                    { name: 'ServicePrincipalSecretKey', data: '', readOnly: false },
                    { name: 'TenantId', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 28, name: 'BillingDataLakeGoogle',
            item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'StorageName', data: '', readOnly: false },
                    { name: 'ServicePrincipalId', data: '', readOnly: false },
                    { name: 'ServicePrincipalSecretKey', data: '', readOnly: false },
                    { name: 'TenantId', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 110, name: 'GlobalRetention', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'TenantId', data: '', readOnly: false },
                    { name: 'SubscriptionId', data: '', readOnly: false },
                    { name: 'ApplicationId', data: '', readOnly: false },
                    { name: 'Key', data: '', readOnly: false },
                    { name: 'DefaultContract', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 70, name: 'CommvaultGlobalAccess', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: "Endpoint", data: '', readOnly: false },
                    { name: "Username", data: '', readOnly: false },
                    { name: "Password", data: '', readOnly: false },
                    { name: "UserID", data: '', readOnly: false }
                ]
            }
        },
        {
            id: 242, name: 'GlobalEDR', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: "URLFortiNetEDR", data: '', readOnly: false },
                    { name: "UserFortiNetEDR", data: '', readOnly: false },
                    { name: "PasswordFortiNetEDR", data: '', readOnly: false }
                ]
            }
        },
        {
            id: 223, name: 'BackupSaaSV2Partner', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'clientId', data: '', readOnly: false },
                    { name: 'clientSecret', data: '', readOnly: false },
                    { name: 'urlHost', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 241, name: 'EndpointDetectionResponse', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'organizationName', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 29, name: 'OptScale', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: "OptScaleUser", data: '', readOnly: false },
                    { name: "OptScalePassword", data: '', readOnly: false }
                ]
            }
        },
        {
            id: 31, name: 'EmailGlobalAWS', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: "EmailHost", data: '', readOnly: false },
                    { name: "EmailPort", data: '', readOnly: false },
                    { name: "EmailUserName", data: '', readOnly: false },
                    { name: "EmailPassword", data: '', readOnly: false }
                ]
            }
        },
        {
            id: 32, name: 'PatchManagerPlusGlobal', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'clientId', data: '', readOnly: false },
                    { name: 'clientSecret', data: '', readOnly: false },
                    { name: 'RefreshToken', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 33, name: 'PatchManagerPlus', item: {
                idItem: 0,
                alias: '',
                active: true,
                fields: [
                    { name: 'Office', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 34, name: 'VantageAPI', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'Username', data: '', readOnly: false },
                    { name: 'BaseUrl', data: '', readOnly: false },
                    { name: 'ApiToken', data: '', readOnly: false }
                ]
            }
        },
        {
            id: 9935, name: 'OPENAI', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'PlatformNumber', data: '', readOnly: false },
                    { name: 'ApiToken', data: '', readOnly: false } 
                ]
            }
        },
        {
            id: 9936, name: 'GEMINI', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'ApiToken', data: '', readOnly: false } 
                ]
            }
        },
        {
            id: 9937, name: 'CLAUDE', item: {
                idItem: 0, alias: '', active: true, fields: [
                    { name: 'ApiToken', data: '', readOnly: false } 
                ]
            }
        }
    ];

    public capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public IsFieldRequired(service: string, field: string): boolean {

        var requiredFields = {
            "PowerShell": ["Domain", "User", "Password"]
        }

        return requiredFields[service] == undefined ||
            requiredFields[service].includes(field)
    }

    public getTypeAndRegex(fieldName: string): {
        type: 'text' | 'date' | 'datetime-local' | 'month' | 'time' | 'week' | 'email' | 'number' | 'password' | 'tel' | 'url' | 'file' | 'multiselect' | 'dropdown' | 'textarea',
        regex?: {
            exp: RegExp;
            help: string;
        }
    } {
        const fieldTypes = {
            accesskey: {
                type: 'password'
            },
            secretkey: {
                type: 'password',
                regex: {
                    exp: /(?<![A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=])/,
                    help: `${this.capitalizeFirstLetter(fieldName)} não é válido`
                }
            },
            defaultcontract: { type: 'number', regex: { exp: /^[0-9]\d*$/, help: 'Deve ser maior que 0' } },
            accountid: { type: 'number', regex: { exp: /^[0-9]\d*$/, help: 'Deve ser maior que 0' } },
            idsource: { type: 'number', regex: { exp: /^[0-9]\d*$/, help: 'Deve ser maior que 0' } },
            departmentid: { type: 'number', regex: { exp: /^[0-9]\d*$/, help: 'Deve ser maior que 0' } },
            applicationid: { type: 'text', regex: { exp: /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/, help: `${this.capitalizeFirstLetter(fieldName)} não é válido` } },
            subscriptionid: { type: 'text', regex: { exp: /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/, help: `${this.capitalizeFirstLetter(fieldName)} não é válido` } },
            domain: { type: 'text', regex: { exp: /^\S+$/, help: 'Espaços em branco não serão aceitos no domain' } },
            serviceprincipalid: { type: 'text', regex: { exp: /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/, help: `${this.capitalizeFirstLetter(fieldName)} não é válido` } },
            default: { type: this.IsInputSecret(fieldName) ? 'password' : 'text' }
        };

        return fieldTypes[fieldName.toLowerCase()] || fieldTypes.default;
    }

    public getValue(fieldName: string, item: VendorCredentialItem): string | ReadonlyArray<string> | number | undefined {
        if (item.keysSecret == "") {
            return undefined
        } else {
            var objeParset = (JSON.parse(item?.keysSecret));
            type SecretInfos = {
                field: string,
                value: string
            }

            var values: SecretInfos[] = [];

            Object.keys(objeParset).map(element => {
                values.push({
                    field: element,
                    value: objeParset[element]
                })
            })
            let value = values.find(x => x.field.toLowerCase() == fieldName.toLowerCase());
            return value ? value.value : undefined;
        }
    }
    public getFormTemplate = (cloudOSProviderId: number, clientGroupId: number, VendorCredentialId: number, item: VendorCredentialItem, readOnly?: boolean) => {
        let fields: PPFormInput[] = []
        if (item.id > 0) {
            var campos = this.enumProvidersTemplates.filter(x => x.id == cloudOSProviderId)[0].item.fields

            fields.push({
                readOnly: readOnly ? readOnly : undefined,
                title: 'Alias',
                name: 'alias',
                type: 'text',
                required: true,
                width: '50%',
                value: item.alias
            })

            campos.map(campo => {
                if (!campo.name.includes('Expire')) {
                    var typeAndRegex = this.getTypeAndRegex(campo.name)

                    var val = this.getValue(campo.name, item);

                    let isInputSecret = this.IsInputSecret(campo.name);

                    var local = this.getTypeInputSecretName(campo.name, VendorCredentialId, clientGroupId, cloudOSProviderId, item.id)

                    fields.push({
                        readOnly: readOnly ? readOnly : undefined,
                        title: isInputSecret ? campo.name + ` (${local})` : campo.name,
                        name: campo.name,
                        type: typeAndRegex.type,
                        required: this.IsFieldRequired(this.getProviderName(cloudOSProviderId), campo.name),
                        width: '50%',
                        regex: typeAndRegex.regex ?? undefined,
                        value: val
                    })
                } else {
                    if (readOnly) {
                        var val = this.getValue(campo.name, item);
                        if (val) {
                            fields.push({
                                readOnly: readOnly ? readOnly : undefined,
                                title: campo.name,
                                name: campo.name,
                                type: 'text',
                                width: '50%',
                                value: val
                            })
                        }

                    }
                }
            })
        } else {
            var campos = this.enumProvidersTemplates.filter(x => x.id == cloudOSProviderId)[0].item.fields

            fields.push({
                readOnly: readOnly ? readOnly : undefined,
                title: 'Alias',
                name: 'alias',
                type: 'text',
                required: true,
                width: '50%',
                value: item.alias
            })

            campos.map(campo => {
                try {
                    if (!campo.name.includes('Expire')) {
                        var typeAndRegex = this.getTypeAndRegex(campo.name);
                        var val = this.getValue(campo.name, item);
                        let isInputSecret = this.IsInputSecret(campo.name);
                        var local = this.getTypeInputSecretName(campo.name, VendorCredentialId, clientGroupId, cloudOSProviderId, item.id);
                        fields.push({
                            readOnly: readOnly ? readOnly : undefined,
                            title: isInputSecret ? campo.name + ` (${local})` : campo.name,
                            name: campo.name,
                            type: typeAndRegex.type,
                            required: this.IsFieldRequired(this.getProviderName(cloudOSProviderId), campo.name),
                            width: '50%',
                            regex: typeAndRegex.regex ?? undefined,
                            value: val
                        });
                    }
                } catch (e) {

                }
            });
        }
        return fields;
    }
}

export interface HealthEvent {
    originService: string;
    gTMId: number;
    gTMItemId: number;
    dataModel: string;
    blockDataCopy: string;
    provider: string;
    categoria: number;
    rotaryGroup: number;
    errorLevel: number;
    servicePath: string;
    clientGroupId: number;
    syncDate: string;
    vendorDate: string;
    logSync: string;
    customerSyncMessage: string;
}
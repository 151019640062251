import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { LinearProgress, Grid, Paper, Typography } from '@material-ui/core';
import history from '../../../history';
import Layout from '../../../components/Layout/Layout';

import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import { useSnackbar } from 'notistack';
import DynamicForm from '../../../components/Layout/DynamicForm/DynamicForm';
import { AuditLogService } from '../../../services/AuditLogService';
import DetailForm from '../DetailForm';
import { useDebounce } from 'use-debounce/lib';
import { useSelector, useDispatch } from 'react-redux';
import { MenuState } from '../../../store/reducers/menuReducer';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import LcLoading from '../../../components/Generic/LcLoading';
import LcIconLink from '../../../components/Generic/LcIconLink';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import style from './index.module.css'
import Moment from 'moment';

import { GTMConfigurationService } from '../../../services/gtmConfigurationService/gtmConfigurationService';

function DateFormater(date: any) {
    let options: any = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    return date && new Intl.DateTimeFormat('pt-BR', options).format(new Date(date)) as string;
}

const useSingleton = (callBack = () => { }) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

export interface HeyHoProps {
    Title?: string,
    Detalhes?: string,
    Servico?: number,
}

function getDateString(date: any, endDate: boolean) {
    if (!endDate) {
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-1";
    } else {
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
}

const AuditLogList: React.FC<HeyHoProps> = (props) => {

    const constructLiveWatchServiceGroupService = (): AuditLogService => {
        return new AuditLogService(props);
    }
    const initTemplate = async () => {
        await ServiceInstance.initialize();

    }
    const [ServiceInstance, setServiceInstance] = useState<AuditLogService>(constructLiveWatchServiceGroupService());
    useSingleton(async () => {
        setServiceInstance && setServiceInstance(constructLiveWatchServiceGroupService());
        initTemplate();
    });
    const service = new GTMConfigurationService(props);
    const dispatch = useDispatch();
    const [reset, setReset] = useState<number>(0);
    const [totalSize, setTotalSize] = useState<number>(-1);
    const [ListData, setListData] = useState<any[]>([]);
    const [clientsOpt, setClientsOpt] = useState<any[]>([]);
    const [ListDataFiltered, setListDataFiltered] = useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);
    const [showDetailComment, setShowDetailComment] = React.useState(false);
    const [commentsDetails, setCommentsDetails] = React.useState<any>();
    const [selectedLog, setSelectedLog] = React.useState<any>();
    const [detailsModalVisible, setDetailsModalVisible] = React.useState<any>(false);
    const [filter, setFilter] = React.useState<any>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ValueSearch, setValueSearch] = React.useState<string>('');
    const [nextToken, setNextToken] = React.useState<string>('');
    const [ValueSearchDebounce] = useDebounce(ValueSearch, 500);
    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);

    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        record: ServiceInstance.emptyRecord
    };

    useEffect(() => {
        return history.listen((location) => {
            setReset(Math.random())
        })
    }, [history, props])

    const [record, setRecord] = useState(emptyRecordMessage);
    const [recordComment, setRecordComment] = useState({ id: (0 as any), detail: '' });

    const [template, setTemplate] = useState<any>({
        title: '',
        Fields: []
    });

    const [templateEdit, setTemplateEdit] = useState<any>({
        title: '',
        Fields: []
    });

    const [templateComment, setTemplateComment] = useState<any>({
        title: '',
        Fields: []
    });
    const [filterPage, setFilterPage] = useState<{
        size: number,
        term: string,
        startDate: string,
        endDate: string,
        nextToken: string,
        clientId: string,
        column: string,
        direction: string
    }>({
        size: 20,
        term: "",
        startDate: getDateString(new Date(), false),
        endDate: getDateString(new Date(), true),
        nextToken: "",
        clientId: "",
        column: "",
        direction: "asc"
    });

    const columns = [
        { field: 'userEmail', headerName: 'Usuário', width: "25%" },
        { field: 'clientGroupName', headerName: 'Empresa', width: "20%" },
        { field: 'route', headerName: 'Rota', width: "50%" },
        {
            field: 'id', headerName: ' ', width: "20px", overflow: "visible",
            renderCell: (row: any) => {
                return <LcIconLink icon="lci lci-view-list" tooltip="Detalhes" onClick={() => { setSelectedLog(row); setDetailsModalVisible(true); }} />
            }
        },
    ];

    const [counter, setCounter] = useState(0);

    const handleDeleteClose = (deleteConfirm: boolean) => {
        setOpen(false);
    };

    function loadData(filter: any) {
        if (filter.size) {
            setLoadingData(true);
            ServiceInstance.RetrieveList(filter, nextToken)
                .then((tt: any) => {
                    setListData([...ListData, ...tt.data.auditList]);
                    setListDataFiltered([...ListDataFiltered, ...tt.data.auditList]);
                    setLoadingData(false);
                    setNextToken(tt.data.nextToken);
                })
                .catch((error) => { console.error(error); setLoadingData(false); });
        }
    }

    useEffect(() => {
        setRecord(emptyRecordMessage);
        resetData();
        setTotalSize(-1);
        service.getClientActive().then(result => {
            if (result.status == 200) {
                let array = result.data.map((e: any) => {
                    return (
                        {
                            name: e.description,
                            value: e.id
                        }
                    )
                });
                array = array.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
                setClientsOpt(array)
            }
        }).catch((error) => {
            console.log(error);
        });
        ServiceInstance.RetrieveListCount(filterPage)
            .then((response: any) => {
                setTotalSize(response.data);
            })
            .catch((error) => { console.error(error); setTotalSize(0) });
    }, [user.ClientGroupSelected, user.refreshFlag, reset]);

    useEffect(() => {
        var filtered = ListData.filter((p: any) => (
            filterPage.term == "" ||
            p.id.toString().toLowerCase().indexOf(filterPage.term) > -1 ||
            p.userEmail.toString().toLowerCase().indexOf(filterPage.term) > -1 ||
            p.clientGroupName.toString().toLowerCase().indexOf(filterPage.term) > -1 ||
            p.route.toString().toLowerCase().indexOf(filterPage.term) > -1
        ));

        filtered.sort((a: any, b: any) => {
            if (filterPage.direction == 'asc') return (a[filterPage.column] > b[filterPage.column]) ? 1 : -1;
            else return (a[filterPage.column] > b[filterPage.column]) ? -1 : 1;
        });

        setListDataFiltered(filtered);
    }, [filterPage.direction, filterPage.column, ListDataFiltered.length > 0]);

    useEffect(() => {
        setListData([]);
        setListDataFiltered([]);
        setLoadingData(true);
        setNextToken("");
        setTotalSize(-1);
        ServiceInstance.RetrieveListCount(filterPage)
            .then((response: any) => {
                setTotalSize(response.data);
                ServiceInstance.RetrieveList(filterPage, '')
                    .then((tt: any) => {
                        setListData(tt.data.auditList);
                        setListDataFiltered(tt.data.auditList);
                        setLoadingData(false);
                        setNextToken(tt.data.nextToken);
                    })
                    .catch((error) => { console.error(error); setTotalSize(0); });

            })
            .catch((error) => { console.error(error); setTotalSize(0) });
    }, [filterPage.startDate, filterPage.endDate, filterPage.clientId, filterPage.term]);

    useEffect(() => {
        loadData(ValueSearch);
    }, [counter]);

    async function resetData() {
        var counterPlus = counter + 1;
        setCounter(counterPlus);
    }

    const filterAdvanced = {
        fields: [
            { label: 'Busca', name: 'term', type: 'text' },
            { label: 'Data inicial', name: 'startDate', type: 'date' },
            { label: 'Data final', name: 'endDate', type: 'date' },
            { label: 'Empresa', name: 'clientId', type: 'select', options: clientsOpt, show: (user.IsAdm) },
        ],
        onChange: (_filter: any, size: number) => {
            setFilterPage({
                ...filterPage,
                size: size,
                term: _filter[0].value.toLowerCase(),
                startDate: _filter[1].value == "" ? getDateString(new Date(), false) : _filter[1].value,
                endDate: _filter[2].value == "" ? getDateString(new Date(), true) : _filter[2].value,
                clientId: _filter[3].value,
            })
        }
    }

    const onSortModelChange = async (orderModel: any) => {
        if (orderModel != undefined && orderModel.sortModel != undefined && orderModel.sortModel.length > 0) {
            setLoading(true);
            let filterCopy = { ...filter };
            filterCopy.ordered = orderModel.sortModel[0].field[0].toUpperCase() + orderModel.sortModel[0].field.slice(1);
            filterCopy.direction = orderModel.sortModel[0].sort;
            setFilter(filterCopy);
        }
    }

    const handlepageChange = async (pageData: any) => {
        setLoading(true);
        let filterCopy = { ...filter };
        filterCopy.start = (pageData.page) * pageData.pageSize;
        filterCopy.size = pageData.pageSize;
        setFilter(filterCopy);
    }

    const selecionadoEditar = (childData: any) => {
        if (childData != null) {
            EDIT(childData);
        }
        else {
            setRecord({ ...emptyRecordMessage })
        }
    }

    const EDIT = async (item: any) => {
        setLoading(true);
        if (templateEdit.Fields.length == 0) {
            let template = await ServiceInstance.templateEdit(true);
            setTemplateEdit(template);
        }
        let addRecord = { ...emptyRecordMessage };
        addRecord.edit = true;
        addRecord.add = false;
        addRecord.detail = false;
        setRecord({ ...item });
    }
    useEffect(() => {

    }, [template]);

    let functions = {
        functionsEdit: [{
            title: 'Fechar',
            icon: 'lci lci-x',
            func: () => {
                setRecord({ ...emptyRecordMessage });
            },
            disabled: false,
            skipvalidation: true
        }]
    };

    const interfaceTyped: { [key: string]: any } = { ...record.record };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterPage({ ...filterPage, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setFilterPage({ ...filterPage, size: size });
        else {
            loadData(filterPage);
        }
    };

    return (
        <Layout pageTitle="Logs Auditoria Livecloud">

            <SideModal width="700px" header={"Detalhes " + selectedLog?.httpMethod} onClose={() => { setDetailsModalVisible(false); setSelectedLog(undefined); }} visible={detailsModalVisible} >
                <p className={style.sideModalReport}>
                    {selectedLog ?
                        <>
                            <div className={style.row}>
                                {Moment(new Date(selectedLog?.accessDate)).format('DD/MM/YYYY HH:mm:ss')}
                            </div>
                            <div className={style.row}>
                                {selectedLog?.httpMethod.toLowerCase() == 'get' ? 'Somente consulta' : selectedLog.detail}
                            </div>
                        </>
                        :
                        <Grid container justify="center" alignItems="center">
                            <LinearProgress />
                        </Grid>
                    }
                </p>
            </SideModal>

            {
                ListDataFiltered && totalSize != -1 ?
                    <LcInfiniteTable
                        height="calc(100vh - 120px)"
                        columns={columns}
                        rows={ListDataFiltered}
                        filter={filterAdvanced}
                        size={totalSize ? totalSize : 0}
                        loadMore={loadMore}
                        onSortChange={onSortChange} />
                    :
                    <Grid container direction="column" justify="center" alignItems="center">
                        <LcLoading loading={true} label="Carregando..." />
                    </Grid>
            }
            {(record.edit == true) &&
                <>
                    <DynamicForm
                        loading={loading}
                        header={(record != null && record.record != null) && interfaceTyped[templateEdit.title] > 0 ?
                            `Ticket: ${interfaceTyped[templateEdit.title]} Situação: ${record.record.heyHoSatatus}` : 'Novo chamado'}
                        EditForm={record.edit}
                        functions={functions.functionsEdit} data={record.record}
                        template={templateEdit} visible={true}
                        onClose={() => { setTemplateEdit({}); resetData(); setRecord(emptyRecordMessage); }} />
                </>
            }
            {
                (((window.location.pathname.toLowerCase().indexOf("criar") >= 0)) && record.edit != true && record.add != true) &&
                <Grid container justify="center" alignItems="center">
                    <LcLoading loading={loading} label="Carregando..." />
                </Grid>
            }
            <DialogConfirmForm onCloseEvent={handleDeleteClose}
                open={open} confirmMessage='Confirmar' abortMessage='Cancelar' title='Confirmar?' content=''>
            </DialogConfirmForm>
        </Layout >
    );
}

export default AuditLogList;